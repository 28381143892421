<script lang="ts" setup>
import stores from '@jaaluu/stores';
import { IntroMessageType } from '@jaaluu/stores/intro-message';
import { t } from '@shared/i18n';
import { PropType } from 'vue';

const { introMsgSeen } = stores.introMessage.state;

defineProps({
    type: {
    type: String as PropType<IntroMessageType>,
    required: true
  },
  icon: {
    type: String,
    required: false,
    default: 'mdi-information-outline'
  }
});
</script>

<template>
  <div class="d-flex align-center justify-center font-info text-center" v-if="!introMsgSeen[type]">
    <v-icon :icon="icon" color="blue" size="13" class="mr-1" />
    <span class="text-blue">{{ t(`intro_message.${type}`) }}</span>
  </div>
</template>

<style scoped lang="scss">
.font-info {
  font-size: 0.9rem;
  font-style: italic;
}
</style>
