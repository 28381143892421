import { KlexikonService, KlexikonTeaserRes, Language } from '@shared/openapi/jaaluu-api';
import { Ref, ref } from 'vue';
import {text, error} from '.';



export default () => {
  const klexArticles: Ref<KlexikonTeaserRes[]> = ref([]);
  const maxLoadKlexikon = ref(0)
  const { handleDefaultError } = error.funcs;

  const getRandomKlexikonArticles = (n: number, lang: Language) => {
    return KlexikonService.getTeasersV1KlexikonTeasersGet({
      query: { lang, n }, throwOnError: true
    }).then((res) => {
      if (res.data) {
        klexArticles.value = res.data;
      }
    })
  };

  const getSearchedKlexikonArticles = (search: string, langForeign: Language) => {};

  return {
    state: { klexArticles, maxLoadKlexikon },
    funcs: { getRandomKlexikonArticles, getSearchedKlexikonArticles }
  };
};
