export default {
  welcome: {
    your_language: 'Twój język',
    language_to_learn: 'Język, którego chcesz się uczyć',
    start_learning: 'Rozpocznij naukę',
    how_does_this_work: 'Jak to działa?',
    welcome_1: 'Witamy w JAALUU',
    welcome_2: 'Tutaj możesz uczyć się języków',
    welcome_3: 'Czytaj teksty lub oglądaj filmy z napisami',
    welcome_4: 'Kliknij na słowo, aby zobaczyć tłumaczenia, przykłady i definicje',
    main_teaser: 'Popraw swoje umiejętności językowe - łatwo i za darmo',
    select_language_and_start: 'Wybierz język i rozpocznij',
    how_to_title: 'Czytaj teksty, książki, wiadomości lub oglądaj filmy',
    how_to_text: 'Możesz wybierać spośród różnych tekstów, książek, wiadomości lub filmów',
    click_on_word_title: 'Automatyczne tłumaczenie, przykłady i definicje',
    click_on_word_text:
      'Kliknij dowolne słowo, aby uzyskać automatyczne tłumaczenie, przykładowe zdania i definicje. Możesz również tłumaczyć całe zdania automatycznie lub łączyć słowa z obrazami i tworzyć sieci słów.',
    listen_title: 'Słuchaj słów i tekstów',
    listen_text: 'Możesz słuchać dowolnych słów, zdań lub całych tekstów i ćwiczyć wymowę',
    conversations_title: 'Ćwicz rozmowy',
    conversations_text:
      'Możesz ćwiczyć rozmowy rozmawiając z wirtualnym chatbotem. Musisz rozwiązywać zadania, takie jak: `Zamów jedzenie w restauracji` lub `Poproś o wskazówki.`',
    writing_title: 'Pisownia',
    writing_text_1: 'Ćwicz pisownię pisząc teksty',
    writing_text_2:
      'Twoje teksty będą sprawdzane pod kątem błędów ortograficznych, treści i gramatyki.',
    texts_and_videos_title: 'Znajdź swój poziom',
    texts_and_videos_text:
      'Możesz wybierać między prostymi frazami i tekstami dla dzieci, wiadomościami, artykułami z Wikipedii lub książkami, w zależności od tego, co Ci odpowiada',
    srs_title: 'Powtarzaj i trenuj słownictwo',
    srs_text_1:
      'Możesz zapisać dowolne słowo lub całe zdania jako ulubione do późniejszego powtórzenia',
    srs_text_2: 'Trenuj swoje słownictwo za pomocą fiszek i prostych ćwiczeń, śledź postępy',
    srs_text_3:
      'System powtórzeń SRS pomaga w efektywnym uczeniu się, powtarzając słowa we właściwym czasie',
    own_text_title: 'Specjalistyczne słownictwo',
    own_text_text:
      'Możesz tworzyć teksty na określony temat, aby uczyć się specjalistycznego słownictwa. Możesz również tworzyć teksty zawierające słowa, które chcesz nauczyć się.',
    wordmap_title: 'Sieci słów',
    wordmap_text_1: 'Możesz tworzyć sieci słów i łączyć słowa z prostymi zdaniami',
    wordmap_text_2:
      'Karty słów można zapisać jako ulubione, umożliwiając łatwe powtarzanie i ćwiczenie',
    sayings_title: 'Idiomaty',
    sayings_text:
      'Tutaj znajdziesz powszechnie używane zwroty i idiomy, sklasyfikowane. Możesz śledzić swoje postępy i rozwijać się w sposób uporządkowany.',
    details: 'Szczegóły',
    details_text_and_videos_title:
      'Odkryj Jaaluu – Twoje klucz do szybkiego i skutecznego nauczania języka!',
    details_text_and_videos_text:
      'Jaaluu oferuje Ci bogactwo tekstów i filmów, które pomogą Ci poprawić Twój poziom językowy. Platforma jest idealna, jeśli potrafisz już rozumieć proste słowa i zdania. Dla początkujących dostępne są proste frazy i idiomy, teksty dla dzieci, słowniki oraz filmy, które ułatwiają start. Dla bardziej zaawansowanych uczniów dostępne są wiadomości, artykuły z Wikipedii, książki i ekscytujące filmy do głębszego zrozumienia lub interaktywne rozmowy z chatbotem. Dodatkowo możesz ćwiczyć pisownię i uzyskać wskazówki dotyczące błędów ortograficznych, treści i gramatyki.',
    details_click_on_words_title:
      'Nauka słownictwa staje się łatwa: Szybka, interaktywna i trwała!',
    details_click_on_words_text:
      'Nauka słownictwa może być żmudna, ponieważ wyszukiwanie słów i definicji zajmuje dużo czasu. Jaaluu rozwiązuje ten problem: Po prostu kliknij słowo, aby natychmiast uzyskać tłumaczenia, przykładowe zdania, definicje i wyjaśnienia. To oszczędza czas i pomaga uczyć się szybciej i skuteczniej!',
    details_listen_title:
      'Słuchaj i ćwicz: Słuchaj słów, zdań i tekstów, aby poprawić swoje umiejętności językowe!',
    details_listen_text:
      'Możesz słuchać wszystkiego – każdego słowa, każdego zdania lub całych tekstów. Po prostu włącz i słuchaj! Ta funkcja pomaga ćwiczyć poprawną wymowę i poprawia zrozumienie ze słuchu. Możesz również słuchać rozmów i rozwijać poczucie naturalnej komunikacji. Idealne do internalizowania słownictwa i wzmacniania swoich umiejętności językowych w codziennych sytuacjach.',
    details_conversations_title: 'Praktyczne szkolenie językowe: Realistyczne rozmowy na co dzień!',
    details_conversations_text:
      'Z Jaaluu możesz ćwiczyć realistyczne rozmowy z chatbotem i przygotować się do typowych sytuacji codziennych. Na przykład możesz zamówić jedzenie w restauracji i zapłacić rachunek lub poprosić o wskazówki. Lub możesz porozmawiać z nowym kolegą w pierwszym dniu w nowej pracy. Twoje zdania będą automatycznie analizowane, abyś otrzymał bezpośrednią opinię zwrotną i poprawił swoje umiejętności językowe. W ten sposób trenujesz w praktyczny i skuteczny sposób na prawdziwe rozmowy!',
    details_writing_title:
      'Ćwiczenie pisania na co dzień: Popraw swoje umiejętności dzięki realistycznym zadaniom!',
    details_writing_text:
      'Możesz ćwiczyć pisanie, wykonując małe, realistyczne zadania, takie jak pisanie podań lub listów reklamacyjnych. Twój tekst będzie następnie automatycznie analizowany, a Ty otrzymasz bezpośrednią opinię zwrotną na temat ortografii, treści i gramatyki. W ten sposób poprawiasz swoje umiejętności pisania i jesteś dobrze przygotowany do życia codziennego!',
    details_srs_title:
      'Efektywna nauka słownictwa z SRS: Automatyczne powtórzenia i personalizowana analiza postępów!',
    details_srs_text:
      'Dzięki systemowi SRS (System Powtórek Przestrzennych) możesz efektywnie uczyć się i powtarzać słownictwo. System przypomina Ci, kiedy powtarzać słowa, aby zachować je na długo. SRS opiera się na metodzie naukowej, która mówi, że powtarzanie w określonych odstępach czasu wzmacnia pamięć. Otrzymasz również statystyki postępów w nauce każdego słowa oraz swój wskaźnik sukcesu, dzięki czemu możesz skupić się na swoich słabościach i zawsze wiedzieć, jak dobrze idzie Ci nauka słownictwa.',
    details_own_text_title:
      'Personalizowane uczenie się: Twórz własne teksty do ukierunkowanego ćwiczenia słownictwa i tematów!',
    details_own_text_text:
      'Z Jaaluu łatwo stworzysz własne teksty w obszarach, na których chcesz się skupić. Opisz obszar lub słownictwo, które chcesz poznać, a otrzymasz tekst do przeczytania i ćwiczenia. W ten sposób możesz skupić się na konkretnych tematach lub słownictwie i poszerzać swoją wiedzę w interesujących Cię obszarach. To spersonalizowane podejście pomaga efektywnie i precyzyjnie się uczyć! Dodatkowo możesz tworzyć teksty zawierające wszystkie słowa, które chcesz przeglądać. Dzięki temu uczysz się i przeglądasz słownictwo w kontekście, co ułatwia zapamiętywanie.',
    details_wordmap_title: 'Karty słów: Łącz swoją wiedzę i zapamiętuj słowa z łatwością!',
    details_wordmap_text:
      'Możesz tworzyć karty słów, które pomogą Ci tworzyć sieci słów i lepiej je zapamiętywać. Dodawaj słowa i zdania interaktywnie do swojej karty słów i stopniowo rozwijaj swoje słownictwo. To tworzy i wzmacnia połączenia w Twoim mózgu i buduje skojarzenia w sposób zabawny. Dzięki temu wzmacniasz swoje rozumienie języka – w sposób trwały!',
    details_paste_text_title:
      'Używaj swoich własnych tekstów: Wklej swoją zawartość, aby uczyć się z pełnym skupieniem!',
    details_paste_text_text:
      'Z Jaaluu możesz również wkleić swoje własne teksty, aby używać ich do ćwiczeń. Dzięki temu możesz pracować z własnym materiałem i trenować swoje słownictwo w kontekstach rzeczywistych.',
    site_also_available: 'Strona jest również dostępna w',
    which_languages_title: 'Jakich języków mogę się uczyć?',
    which_languages_text: 'Tutaj możesz uczyć się angielskiego, hiszpańskiego, niemieckiego, francuskiego, portugalskiego, rosyjskiego i włoskiego. Po prostu wybierz język, którego chcesz się uczyć i gotowe!'
  },
  intro: {
    your_level: 'Jaki jest Twój poziom językowy?',
    beginner_explained: 'Potrafisz czytać proste zdania',
    advanced_explained: 'Potrafisz czytać bardziej złożone teksty',
    your_cefr_level: 'Twój poziom CEFR to',
    lets_start: 'Od czego chciałbyś zacząć?',
    beginner_options: {
      simple_texts: 'Czytaj teksty',
      simple_articles: 'Czytaj artykuły',
      sayings: 'Ucz się powiedzeń',
      view_videos: 'Oglądaj filmy',
      simple_phrases: 'Czytaj typowe zdania'
    },
    advanced_options: {
      news: 'Czytaj wiadomości',
      videos: 'Oglądaj filmy',
      wikipedia: 'Czytaj artykuły',
      books: 'Czytaj książki'
    }
  },
  languages: {
    de: 'Niemiecki',
    en: 'Angielski',
    es: 'Hiszpański',
    fr: 'Francuski',
    it: 'Włoski',
    pt: 'Portugalski',
    ru: 'Rosyjski',
    zh: 'Chiński',
    pl: 'Polski'
  },
  general: {
    beginner: 'Początkujący',
    advanced: 'Zaawansowany',
    search: 'Szukaj',
    search_imperative: 'Szukaj',
    translate: 'Tłumacz',
    settings: 'Ustawienia',
    vocabulary: 'Słownictwo',
    shorts: 'Krótkie teksty',
    articles: 'Artykuły',
    feeds: 'Wiadomości',
    videos: 'Filmy',
    read: 'Czytaj',
    view: 'Obejrzyj',
    listen: 'Słuchaj',
    save: 'Zapisz',
    more: 'więcej',
    back: 'Wstecz',
    next: 'Dalej',
    easy: 'Łatwy',
    medium: 'Średni',
    hard: 'Trudny',
    all: 'Wszystkie',
    level: 'Poziom',
    font_size: 'Rozmiar czcionki',
    language: 'Język',
    send: 'Wyślij',
    image: 'Obraz',
    wordmap: 'Mapa słów',
    open: 'Otwórz',
    add: 'Dodaj',
    question: 'Pytanie',
    start: 'Rozpocznij',
    words: 'Słowo | Słów'
  },
  app_bar: {
    search_in_foreign_language: 'Szukaj w obcym języku',
    menu: 'Menu'
  },
  category: {
    chapter: 'Rozdział'
  },
  favorites: {
    favorites: 'Ulubione',
    word: 'Słowo',
    sentence: 'Zdanie',
    sentences: 'Zdania',
    texts_videos: 'Teksty i Filmy',
    wordmaps: 'Mapy słów',
    saved: 'Zapisano',
    title: 'Tytuł',
    type: 'Rodzaj',
    no_favorites_found: 'Nie znaleziono ulubionych',
    filtered_by: 'filtrowane przez',
    train: 'Trening',
    notify_me_srs: 'Powiadom mnie o powtórce słownictwa',
    srs_level: 'Poziom SRS',
    success_rate: 'Wskaźnik sukcesu',
    last_seen: 'Ostatni raz widziano',
    next_review: 'Następna powtórka',
    success_level_up: 'Słowo przeniesione do poziomu {level}',
    what_is_srs: 'Co to jest SRS?',
    srs_short:
      'SRS to system powtórek przestrzennych. Pomaga Ci lepiej zapamiętywać słowa, powtarzając je w odpowiednim czasie.',
    srs_long:
      'System opiera się na zasadzie, że im częściej powtarzasz słowo, tym lepiej je zapamiętujesz. Interwały między powtórkami są określane przez system. Jeśli dobrze pamiętasz słowo, interwały stają się dłuższe. Jeśli masz trudności, interwały stają się krótsze. W ten sposób możesz efektywniej uczyć się słów.',
    srs_intervals: 'Te {nIntervals} interwały to: {intervals} dni.',
    when_does_a_word_move: 'Kiedy słowo przechodzi na kolejny poziom?',
    srs_next_level_example_1:
      'Jeśli dodasz słowo do ulubionych, będzie ono na poziomie 1. Słowo na poziomie 1 przejdzie na następny poziom, jeśli minie 1 dzień od dodania słowa, Twój wskaźnik sukcesu będzie wyższy niż 50% i właśnie zapamiętałeś to słowo poprawnie.',
    srs_next_level_example_2:
      'Słowo na poziomie {level} przejdzie na następny poziom, jeśli ostatnia zmiana na poziomie {levelM1} miała miejsce co najmniej {days} dni temu, Twój wskaźnik sukcesu jest wyższy niż 50% i właśnie zapamiętałeś to słowo poprawnie.'
  },
  player: {
    maximize: 'Powiększ',
    minimize: 'Zminimalizuj'
  },
  video: {
    error:
      'Wystąpił błąd podczas ładowania napisów. Niestety, nie wszystkie napisy są dostępne za darmo. Spróbuj innego filmu.'
  },
  feeds: {
    disclaimer:
      'To automatycznie wygenerowane podsumowanie za pomocą ChatGPT. Oryginalny artykuł jest dostępny',
    here: 'tutaj',
    generating_article: 'Generowanie artykułu...',
    error:
      'Wystąpił błąd podczas ładowania artykułu. Niestety, nie wszystkie artykuły są dostępne za darmo. Spróbuj innego artykułu lub ręcznie skopiuj tekst',
    open_org: '1. Otwórz oryginalny artykuł i skopiuj tekst',
    open_own: '2. Wklej tekst tutaj'
  },
  books: {
    read_now: 'Czytaj teraz',
    continue_on_page: 'Kontynuuj na stronie'
  },
  tooltip: {
    examples: 'Przykłady',
    emotional_examples: 'Emocjonalne przykłady',
    definition: 'Definicja',
    synonyms: 'Synonimy',
    antonyms: 'Antonimy',
    article: 'Artykuł',
    conjugations: 'Odmiana',
    grammer: 'Gramatyka'
  },
  custom_text: {
    enter_your_own_text: 'Wpisz własny tekst',
    read_text: 'Czytaj tekst',
    enter_new_text: 'Wpisz nowy tekst',
    own_text: 'Skopiuj własny tekst i wklej go tutaj',
    special_vocabulary: 'Specjalne słownictwo'
  },
  special_vocabulary: {
    special_vocabulary: 'Specjalne słownictwo',
    describe:
      'Wprowadź tekst po angielsku na temat lub specjalne słownictwo, które chcesz się nauczyć',
    language_level: 'Poziom językowy',
    generate_text: 'Generuj tekst'
  },
  intro_message: {
    save_word_as_fav: 'Zapisz słowo jako ulubione',
    save_video_as_fav: 'Zapisz wideo jako ulubione',
    save_article_as_fav: 'Zapisz artykuł jako ulubiony',
    save_book_as_fav: 'Zapisz książkę jako ulubioną',
    save_wordmap_as_fav: 'Zapisz mapę słów jako ulubioną',
    play_word: 'Posłuchaj wymowy słowa',
    play_article: 'Posłuchaj artykułu',
    play_book: 'Posłuchaj książki',
    select_main_category: 'Wybierz główną kategorię',
    select_sub_category: 'Wybierz podkategorię',
    click_on_read: 'Kliknij „CZYTAJ”, aby przeczytać cały tekst',
    click_on_view: 'Kliknij „OBEJRZYJ”, aby obejrzeć wideo',
    last_book_page: 'Twoja ostatnia strona zostanie automatycznie zapisana i wyświetlona tutaj',
    wordmap_click_on_word: 'Kliknij na słowo, aby zobaczyć najczęściej używane słowa',
    wordmap_click_on_line: 'Kliknij na linię między dwoma słowami, aby zobaczyć zdanie',
    category_progress: 'Śledź swoje postępy, zaznaczając pola wyboru',
    conversations_select_scene: 'Wybierz ćwiczenie',
    conversations_warn: 'Wydaje się, że w twoim zdaniu wystąpił błąd. Kliknij na trójkąt, aby uzyskać więcej wyjaśnień.',
    conversations_task: 'Sformułuj zdanie zgodnie z instrukcją na końcu czatu',
  },
  tour_guide: {
    click_on_every_word: {
      title: 'Tłumacz słowo',
      text: 'Możesz kliknąć na dowolne słowo, aby zobaczyć tłumaczenia, przykładowe zdania i definicje'
    },
    click_on_i_for_sentence: {
      title: 'Całe zdanie',
      text: 'Aby przetłumaczyć całe zdanie, kliknij symbol i. Tutaj możesz również uzyskać wyjaśnienia dotyczące gramatyki.'
    },
    no_save_custom: {
      title: 'Brak zapisu',
      text: 'Teksty własne nie mogą być zapisywane'
    },
    change_playback_rate: {
      title: 'Zmień Szybkość Odtwarzania',
      text: 'Zmień szybkość odtwarzania w Menu/Ustawienia/Audio i Wideo.'
    },
    theme: {
      title: 'Kolory',
      text: 'Możesz zmienić kolory w menu na tryb ciemny'
    },
    explain_menu: {
      title: 'Menu',
      text: 'Elementy menu są posortowane od bardzo trudnych do łatwych od góry do dołu'
    },
    repeat_words: {
      title: 'Powtórz słownictwo',
      text: 'Powinieneś powtórzyć {nWords} słownictw.',
      call_to_action: 'Naucz się teraz'
    }
  },
  chat: {
    your_message: 'Twoja wiadomość',
    in_de: 'po niemiecku',
    in_es: 'po hiszpańsku',
    in_fr: 'po francusku',
    in_it: 'po włosku',
    in_pt: 'po portugalsku',
    in_ru: 'po rosyjsku',
    in_zh: 'po chińsku',
    in_pl: 'po polsku',
    wait: 'Proszę czekać...',
    speak_now: 'Mów teraz',
    new_chat: 'Nowy czat'
  },
  langs: {
    de: 'Niemiecki',
    en: 'Angielski',
    sp: 'Hiszpański',
    fr: 'Francuski',
    it: 'Włoski',
    pt: 'Portugalski',
    pl: 'Polski',
    ru: 'Rosyjski',
    zh: 'Chiński'
  },
  about: {
    how_does_it_work: 'Jak to działa?',
    here_you_can: 'Tutaj możesz',
    read_texts: 'Czytać teksty',
    watch_videos: 'Oglądać filmy',
    click_on_every_wrord_for: 'Kliknij na dowolne słowo, aby zobaczyć',
    translations: 'tłumaczenia',
    examples: 'przykłady',
    definitions: 'definicje',
    synonyms: 'synonimy',
    copy_your_own_text: 'Skopiuj i czytaj własny tekst',
    chat_with_chatbot: 'Czatować z chatbotem',
    level: 'Twój poziom powinien być pomiędzy B1 a C2'
  },
  settings: {
    audio_video: 'Audio i Wideo',
    colors: 'Kolory',
    dark_mode: 'Tryb ciemny',
    lang_level: 'Poziom języka'
  },
  error: {
    resource_not_found: 'Przepraszamy, nie udało się znaleźć tego, czego szukasz'
  },
  train: {
    check: 'Sprawdź',
    flashcards: 'Karty flash',
    exercises: 'Ćwiczenia',
    finished: 'Ćwiczenia zakończone',
    go_to_favorites: 'Przejdź do ulubionych',
    restart_exercise: 'Zrestartuj ćwiczenie',
    go_to_home: 'Przejdź do strony głównej',
    text_with_words: 'Tekst ze słowami',
    text_includes: 'Tekst zawiera następujące słowa'
  },
  wordmap: {
    click_on_circle: 'Kliknij na okrąg, aby zobaczyć najczęściej używane słowa z {word}',
    click_on_edge: 'Kliknij na linię między dwoma słowami, aby zobaczyć zdanie z oboma słowami',
    open_fullscreen: 'Otwórz na pełnym ekranie',
    add_own_word: 'Dodaj własne słowo',
    add_sentence_with_all_words: 'Dodaj zdanie ze wszystkimi słowami',
    add_to_word: 'Dodaj do słowa'
  },
  conversations: {
    you_are: 'Jesteś',
    you_talk_to: 'Rozmawiasz z',
    your_tasks: 'Twoje zadania',
  },
  write: {
    min_words: 'Minimalna liczba słów',
    your_email_needs_to_contain: 'Twój e-mail musi zawierać',
    rate: 'Ocena',
    analysis: 'Analiza',
    overall: 'Ogólna',
    grammar: 'Gramatyka',
    content: 'Treść',
    spelling: 'Pisownia'
  }
};
