import {
  CommunicationBasics,
  EntertainmentAndLeisure,
  FeelingsAndEmotions,
  FoodAndDining,
  HealthAndWellBeing,
  MainCategories,
  NubmersAndQuantity,
  SocialContexts,
  TimeAndDates,
  TravelAndDirections
} from '@shared/openapi/jaaluu-api';

export const CATEGORIES_DE = {
  [MainCategories.COMMUNICATION_BASICS]: {
    title: 'Kommunikation',
    categories: {
      [CommunicationBasics.GREETINGS]: 'Grüße',
      [CommunicationBasics.QUESTIONS]: 'Fragen',
      [CommunicationBasics.EXPRESSIONS]: 'Ausdrücke'
    }
  },
  [MainCategories.FEELINGS_AND_EMOTIONS]: {
    title: 'Gefühle und Emotionen',
    categories: {
      [FeelingsAndEmotions.POSITIVE_EMOTIONS]: 'Positive Emotionen',
      [FeelingsAndEmotions.NEGATIVE_EMOTIONS]: 'Negative Emotionen',
      [FeelingsAndEmotions.NEUTRAL_OR_MIXED_FEELINGS]: 'Neutrale oder gemischte Gefühle'
    }
  },
  [MainCategories.TRAVEL_AND_DIRECTIONS]: {
    title: 'Reisen und Richtungen',
    categories: {
      [TravelAndDirections.DIRECTIONS]: 'Richtungen',
      [TravelAndDirections.LOCATIONS]: 'Orte',
      [TravelAndDirections.TRANSPORTATION]: 'Transport',
      [TravelAndDirections.ACCOMODATION]: 'Unterkunft'
    }
  },
  [MainCategories.NUMBERS_AND_QUANTITY]: {
    title: 'Zahlen und Menge',
    categories: {
      [NubmersAndQuantity.NUMBERS]: 'Zahlen',
      [NubmersAndQuantity.QUANTITY]: 'Menge'
    }
  },
  [MainCategories.TIME_AND_DATES]: {
    title: 'Zeit und Daten',
    categories: {
      [TimeAndDates.TIME]: 'Zeit',
      [TimeAndDates.DATES]: 'Daten'
    }
  },
  [MainCategories.ENTERTAINMENT_AND_LEISURE]: {
    title: 'Unterhaltung und Freizeit',
    categories: {
      [EntertainmentAndLeisure.ENTERTAINMENT]: 'Unterhaltung',
      [EntertainmentAndLeisure.LEISURE]: 'Freizeit'
    }
  },
  [MainCategories.HEALTH_AND_WELL_BEING]: {
    title: 'Gesundheit und Wohlbefinden',
    categories: {
      [HealthAndWellBeing.HEALTH]: 'Gesundheit',
      [HealthAndWellBeing.WELL_BEING]: 'Wohlbefinden'
    }
  },
  [MainCategories.SOCIAL_CONTEXTS]: {
    title: 'Soziale Kontexte',
    categories: {
      [SocialContexts.SOCIAL]: 'Sozial',
      [SocialContexts.CULTURAL]: 'Kulturell',
      [SocialContexts.RELIGIOUS]: 'Religiös',
      [SocialContexts.POLITICAL]: 'Politisch'
    }
  },
  [MainCategories.FOOD_AND_DINING]: {
    title: 'Essen und Trinken',
    categories: {
      [FoodAndDining.FOOD]: 'Essen',
      [FoodAndDining.DRINKS]: 'Getränke',
      [FoodAndDining.DINING]: 'Speisen'
    }
  }
};
