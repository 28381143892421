<script setup lang="ts">
import anime from 'animejs';
import { defineProps, onMounted, ref } from 'vue';

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  contents: {
    type: Array,
    required: true
  },
  videoClass: {
    type: String,
    required: false,
    default: ''
  }
});
const ID = Math.random().toString(36).substr(2, 9);
const isVisible = ref(false);
let timeout: NodeJS.Timeout | null = null;

onMounted(() => {
  window.addEventListener('scroll', onScroll);
});

const onScroll = () => {
  const rect = document.getElementById(ID)?.getBoundingClientRect();
  if (!rect || rect.top < 0) return;
  let visibleHeight =
    Math.max(0, Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0)) / rect.height;

  const duration = 750;
  if (visibleHeight !== null && visibleHeight < 0.3 && isVisible) {
    if (timeout) {
      clearTimeout(timeout);
    }
    const shiftPx = window.innerWidth / 2 + 300;
    timeout = setTimeout(() => {
      isVisible.value = false;
      anime({
        targets: `#text-${ID}`,
        translateX: shiftPx * -1,
        easing: 'easeInOutQuad',
        duration
      });
      anime({
        targets: `#video-${ID}`,
        translateX: shiftPx * -1,
        easing: 'easeInOutQuad',
        duration
      });
    }, 100);
  } else if (!isVisible.value) {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      isVisible.value = true;
      anime({
        targets: `#text-${ID}`,
        translateX: 0,
        easing: 'easeInOutQuad',
        duration
      });
      anime({
        targets: `#video-${ID}`,
        translateX: 0,
        easing: 'easeInOutQuad',
        duration
      });
    }, 100);
  }
};
</script>
<template>
  <div :id="ID">
    <div class="mx-auto mt-16">
      <h2 class="title-welcome text-center">{{ props.title }}</h2>
      <div
        v-for="cont in props.contents"
        class="d-flex justify-center mx-auto align-center flex-wrap"
      >
        <video
          :id="`video-${ID}`"
          playsinline
          autoplay
          muted
          loop
          class="video mt-1"
          :class="[videoClass]"
        >
          <source :src="cont.video" type="video/mp4" />
        </video>
        <div class="mt-n6">
          <v-card
            color="primary"
            variant="outlined"
            elevation="2"
            class="ml-0 ml-md-8 text-welcome text-center text pa-3 z-index-2"
            :id="`text-${ID}`"
          >
            {{ cont.text }}
          </v-card>
        </div>
      </div>
      <div class="d-flex justify-center">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.title-welcome {
  font-size: 2rem;
}
.text-welcome {
  font-size: 1.5rem;
}
video {
  width: 250px;
  filter: grayscale(0);
}
.text {
  width: 320px;
}
.title-welcome {
  font-size: 2rem;
}
.text-welcome {
  font-size: 1.5rem;
}
.scale-down {
  scale: 0.7;
}
</style>
