import {
  CommunicationBasics,
  EntertainmentAndLeisure,
  FeelingsAndEmotions,
  FoodAndDining,
  HealthAndWellBeing,
  MainCategories,
  NubmersAndQuantity,
  SocialContexts,
  TimeAndDates,
  TravelAndDirections
} from '@shared/openapi/jaaluu-api';

export const CATEGORIES_IT = {
  [MainCategories.COMMUNICATION_BASICS]: {
    title: 'Comunicazione',
    categories: {
      [CommunicationBasics.GREETINGS]: 'Saluti',
      [CommunicationBasics.QUESTIONS]: 'Domande',
      [CommunicationBasics.EXPRESSIONS]: 'Espressioni'
    }
  },
  [MainCategories.FEELINGS_AND_EMOTIONS]: {
    title: 'Sentimenti ed Emozioni',
    categories: {
      [FeelingsAndEmotions.POSITIVE_EMOTIONS]: 'Emozioni positive',
      [FeelingsAndEmotions.NEGATIVE_EMOTIONS]: 'Emozioni negative',
      [FeelingsAndEmotions.NEUTRAL_OR_MIXED_FEELINGS]: 'Sentimenti neutri o misti'
    }
  },
  [MainCategories.TRAVEL_AND_DIRECTIONS]: {
    title: 'Viaggi e Direzioni',
    categories: {
      [TravelAndDirections.DIRECTIONS]: 'Indicazioni',
      [TravelAndDirections.LOCATIONS]: 'Luoghi',
      [TravelAndDirections.TRANSPORTATION]: 'Trasporti',
      [TravelAndDirections.ACCOMODATION]: 'Alloggio'
    }
  },
  [MainCategories.NUMBERS_AND_QUANTITY]: {
    title: 'Numeri e Quantità',
    categories: {
      [NubmersAndQuantity.NUMBERS]: 'Numeri',
      [NubmersAndQuantity.QUANTITY]: 'Quantità'
    }
  },
  [MainCategories.TIME_AND_DATES]: {
    title: 'Ore e Date',
    categories: {
      [TimeAndDates.TIME]: 'Ora',
      [TimeAndDates.DATES]: 'Date'
    }
  },
  [MainCategories.ENTERTAINMENT_AND_LEISURE]: {
    title: 'Intrattenimento e Tempo Libero',
    categories: {
      [EntertainmentAndLeisure.ENTERTAINMENT]: 'Intrattenimento',
      [EntertainmentAndLeisure.LEISURE]: 'Tempo libero'
    }
  },
  [MainCategories.HEALTH_AND_WELL_BEING]: {
    title: 'Salute e Benessere',
    categories: {
      [HealthAndWellBeing.HEALTH]: 'Salute',
      [HealthAndWellBeing.WELL_BEING]: 'Benessere'
    }
  },
  [MainCategories.SOCIAL_CONTEXTS]: {
    title: 'Contesti Sociali',
    categories: {
      [SocialContexts.SOCIAL]: 'Sociale',
      [SocialContexts.CULTURAL]: 'Culturale',
      [SocialContexts.RELIGIOUS]: 'Religioso',
      [SocialContexts.POLITICAL]: 'Politico'
    }
  },
  [MainCategories.FOOD_AND_DINING]: {
    title: 'Cibo e Ristorazione',
    categories: {
      [FoodAndDining.FOOD]: 'Cibo',
      [FoodAndDining.DRINKS]: 'Bevande',
      [FoodAndDining.DINING]: 'Ristorazione'
    }
  }
};
