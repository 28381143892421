export default {
  welcome: {
    your_language: 'Deine Sprache',
    language_to_learn: 'Sprache, die du lernen möchtest',
    start_learning: 'Lernen starten',
    how_does_this_work: 'Wie funktioniert das?',
    welcome_1: 'Willkommen',
    welcome_2: 'Hier kannst Du Sprachen lernen',
    welcome_3: 'Lese Texte oder schaue Videos mit Untertitlen',
    welcome_4: 'Klicke auf ein Wort für Übersetzungen, Beispielsätze und Definitionen',
    main_teaser: 'Verbessere Deine Sprachkentnisse - einfach und kostenlos',
    select_language_and_start: 'Sprache Wählen und starten',
    how_to_title: 'Lese Texte, Bücher, Nachrichten oder schaue Videos',
    how_to_text:
      'Du kannst aus einer Vielzahl von Texten, Büchern, Nachrichten oder Videos auswählen',
    click_on_word_title: 'Automatische Übersetzung, Beispiele und Definitionen',
    click_on_wrod_text:
      'Klicke auf jedes beliebige Wort für eine automatische Übersetzung, Beispielsätze und Definitionen. Zudem kannst du ganze Sätze automatisch übersetzen oder Wörter mit Bildern verbinden und Wortnetzwerke erstellen.',
    listen_title: 'Wörter und Texte anhören',
    listen_text:
      'Du kannst jedes Wort, jeden Satz und ganze Texte anhören und so die Aussprache üben',
    conversations_title: 'Übe Unterhaltungen',
    conversations_text:
      'Du kannst Unterhaltungen üben, indem Du mit einem virtuellen Chatbot sprichst. Du musst dabei aufgeben lösen wie: `Bestelle Essen in einem Restaurant` oder `Frage nach dem Weg.',
    writing_title: 'Rechtschreibung',
    writing_text_1: 'Übe die Rechtschreibung in dem du Texte schreibst',
    writing_text_2: 'Deine Texte werden dann auf Rechtschreibfehler, Inhalt und Grammatik geprüft.',
    texts_and_videos_title: 'Finde Dein Niveau',
    texts_and_videos_text:
      'Du kannst zwischen einfachen Phrasen und Texten für Kinder, über Nachrichten, Wikipedia Artikel bis hin zu Büchern wählen, was für Dich am besten passt',
    srs_title: 'Wiederhole und Trainiere Vokabelen',
    srs_text_1:
      'Du kannst jedes Wort oder auch ganze Sätze als Favorit speichern um sie später zu wiederholen',
    srs_text_2:
      'Trainiere Deine Vokablen dann mit Flashcards und einfachen Übungen und verfolge Deinen Fortschritt',
    srs_text_3:
      'Das SRS Wiederholungssystem hilft Dir effektiver zu lernen, indem Du Wörter zum richtigen Zeitpunkt wiederholst',
    own_text_title: 'Spezieller Wortschatz',
    own_text_text:
      'Du kannst Dir Text zu einem bestimmten Thema erstellen lassen um spezielle Vokabeln zu lernen. Zudem kannst Du Dir Texte erstellen, welche Vokabeln enthalten, die Du lernen möchtest.',
    wordmap_title: 'Wortnetzwerke',
    wordmap_text_1:
      'Du kannst Netzwerke aus Wörtern erstellen und die Wörter mit einfachen Sätzen verbinden',
    wordmap_text_2:
      'Wortkarten können als Favoriten gespeichert werden und damit einfach wiederholt und trainiert werden',
    sayings_title: 'Redewendungen',
    sayings_text:
      'Hier findest Du häufig verwendete Phrasen und Redewendungen nach Kategorien unterteilt. Du kannst dabei Deinen Fortschritt festhalten und Dich so strukturiert weiterentwickeln.',
    details: 'Details',
    details_text_and_videos_title:
      'Entdecke Jaaluu – Dein Schlüssel zu schnellem und effektivem Sprachenlernen!',
    details_text_and_videos_text:
      'Jaaluu bietet Dir eine Fülle an Texten und Videos, um Dein Sprachniveau gezielt zu verbessern. Die Plattform ist ideal, wenn Du bereits einfache Wörter und Sätze verstehen kannst. Für Anfänger gibt es einfache Phrasen und Redewendungen, Kindgerechte Texte und Lexika sowie Videos für einen leichten Einstieg. Für Fortgescrittene Nachrichten, Wikipedia-Artikel und Bücher sowie spannende Videos zur Vertiefung oder interaktive Chatbot-Konversationen. Zudem kannst Du Deine Rechtschreibung trainieren und erhälst Tipps zur Verbesserung von Rechtschreibfehlern, Inhalt und Grammatik.',
    details_click_on_words_title:
      'Vokabeln lernen leicht gemacht: Schnell, interaktiv und nachhaltig!',
    details_click_on_words_text:
      'Vokabeln lernen kann mühsam sein, da das Nachschlagen der Wörter und Definitionen viel Zeit kostet. Jaaluu löst dieses Problem: Klicke einfach auf ein Wort, um sofort Übersetzungen, Beispielsätze, Definitionen und Erklärungen zu erhalten. So sparst du Zeit und lernst schneller und effektiver!',
    details_listen_title:
      '"Hören und Üben: Höre Wörter, Sätze und Texte, um deine Sprachfähigkeiten zu verbessern!',
    details_listen_text:
      'Du kannst alles anhören – jedes Wort, jeden Satz oder sogar ganze Texte. Einfach abspielen und anhören! Diese Funktion hilft Dir, die richtige Aussprache zu üben und das Hörverständnis zu verbessern. Du kannst auch Gespräche nachhören und so Dein Sprachgefühl für natürliche Kommunikation weiterentwickeln. Perfekt, um Vokabeln zu verinnerlichen und Deine Sprachfähigkeiten im Alltag zu stärken!',
    details_conversations_title:
      'Praxisnahes Sprachtraining: Realistische Gespräche für den Alltag!',
    details_conversations_text:
      'Mit Jaaluu kannst Du realistische Gespräche mit einem Chatbot üben und Dich auf typische Alltagssituationen vorbereiten. So musst Du z.B. Essen in einem Restaurant bestellen und die Rechnung bezahlen oder nach dem Weg fragen. Oder Du unterhälst Dich mit Deinem neuen Kollegen an Deinem ersten Arbeitstag in einem neuen Job. Deine Sätze werden automatisch analysiert, um Dir direktes Feedback zu geben und dein Sprachvermögen gezielt zu verbessern. So trainierst du praxisnah und effektiv für echte Gespräche!',
    details_writing_title:
      'Schreibtraining für den Alltag: Verbessere deine Fähigkeiten mit realistischen Aufgaben!',
    details_writing_text:
      'Du kannst das Schreiben üben, indem du kleine, realistische Situationen meisterst, wie das Verfassen von Bewerbungen oder Beschwerdebriefen. Dein Text wird dann automatisch analysiert, und Du erhältst direktes Feedback zu Rechtschreibung, Inhalt und Grammatik. So verbesserst du deine Schreibfähigkeiten und bist für den Alltag bestens gerüstet!',
    details_srs_title:
      'Effektives Vokabellernen mit SRS: Automatische Wiederholungen und personalisierte Fortschrittsanalyse!',
    details_srs_text:
      'Mit dem SRS-System (Spaced Repetition System) kannst Du Vokabeln effektiv lernen und wiederholen. Dieses System erinnert Dich automatisch daran, wann Du welche Wörter wiederholen solltest, um sie langfristig zu behalten. SRS basiert auf der wissenschaftlichen Methode, dass Wiederholungen in bestimmten Abständen das Gedächtnis stärken. Du erhältst außerdem Statistiken über den Lernstand jedes Wortes und Deine Erfolgsquote, sodass Du gezielt an deinen Schwächen arbeiten kannst und immer weißt, wie gut Du beim Vokabellernen vorankommst.',
    details_own_text_title:
      'Individuelles Lernen: Erstelle maßgeschneiderte Texte für gezielte Vokabel- und Themenübungen!',
    details_own_text_text:
      'Mit Jaaluu kannst Du ganz einfach eigene Texte in den Bereichen erstellen, die Du gezielt lernen möchtest. Beschreibe den Bereich oder die Vokabeln, die Du lernen möchtest und erhalte so einen Text, den Du lesen und üben kannst. So kannst Du Dich auf spezielle Themen oder Vokabeln konzentrieren und Dein Wissen in genau dem Bereich erweitern, der Dir wichtig ist. Diese maßgeschneiderte Methode hilft Dir, noch effektiver und gezielter zu lernen! Zudem kannst Du Texte erstellen, die all Deine Vokalen enthalten, die Du wiederholen möchtest. So lernst und wiederholst Du Vokabeln in einem Kontext und kannst sie Dir so schneller und besser merken.',
    details_wordmap_title:
      'Wortkarten: Verknüpfe dein Wissen und merke dir Wörter spielend leicht!',
    details_wordmap_text:
      'Du kannst Wortkarten erstellen, die Dir helfen, Wörter effektiv zu vernetzen und leichter zu merken. Füge interaktiv Wörter und Sätze zu Deiner Wortkarte hinzu und erweitere so langsam Deinen Wortschatz. Das erstellt und verstärkt Verbindungen in Deinem Gehirn und baut spielerisch Assoziationen auf. So stärkst Du Dein Sprachverständnis – auf nachhaltige Weise!',
    details_paste_text_title: 'Eigene Texte nutzen: füge Deine Inhalte ein, um gezielt zu lernen!',
    details_paste_text_text:
      'Mit Jallu kannst Du auch eigene Texte einfügen, um sie zum Üben zu verwenden. So kannst Du gezielt mit Deinem eigenen Material arbeiten und Dein Vokabular in realistischen Kontexten trainieren.',
    site_also_available: 'Die Seite ist auch verfügbar in',
    which_languages_title: 'Welche Sprachen kann ich lernen?',
    which_languages_text: 'Hier kannst Du Englisch, Spanisch, Französisch, Italienisch, Portugiesisch, Russisch und Polnisch lernen. Wähle einfach die Sprache aus, die Du lernen möchtest, und schon kann es losgehen!',
  },
  intro: {
    your_level: 'Dein Sprachniveau?',
    beginner_explained: 'Du kannst einfache Sätze lesen',
    advanced_explained: 'Du kannst komplexere Texte lesen',
    your_cefr_level: 'Dein CEFR Niveau ist',
    lets_start: 'Mit was möchtest Du starten?',
    beginner_options: {
      simple_texts: 'Texte lesen',
      simple_articles: 'Artikel lesen',
      sayings: 'Redewendungen lernen',
      view_videos: 'Videos anschauen',
      simple_phrases: 'Typische Sätze lesen'
    },
    advanced_options: {
      news: 'Nachrichten lesen',
      videos: 'Videos anschauen',
      wikipedia: 'Artikel lesen',
      books: 'Bücher lesen'
    }
  },
  languages: {
    de: 'Deutsch',
    en: 'Englisch',
    es: 'Spanisch',
    fr: 'Französisch',
    it: 'Italienisch',
    pt: 'Portugiesisch',
    ru: 'Russisch',
    zh: 'Chinesisch',
    pl: 'Polnisch'
  },
  general: {
    beginner: 'Anfänger',
    advanced: 'Fortgeschritten',
    search: 'Suchen',
    search_imperative: 'Suche',
    translate: 'Übersetzen',
    settings: 'Einstellungen',
    vocabulary: 'Vokablen',
    shorts: 'Kurze Texte',
    articles: 'Artikel',
    feeds: 'Nachrichten',
    videos: 'Videos',
    read: 'Lesen',
    view: 'Anschauen',
    listen: 'Anhören',
    save: 'Speichern',
    more: 'mehr',
    back: 'Zurück',
    next: 'Weiter',
    easy: 'Einfach',
    medium: 'Mittel',
    hard: 'Schwer',
    all: 'Alle',
    level: 'Niveau',
    font_size: 'Schriftgröße',
    language: 'Sprache',
    send: 'Senden',
    image: 'Bild',
    wordmap: 'Wortkarte',
    open: 'Öffnen',
    add: 'Hinzufügen',
    question: 'Frage',
    start: 'Starten',
    words: 'Wort | Wörter'
  },
  app_bar: {
    search_in_foreign_language: 'Suche in Fremdsprache',
    menu: 'Menü'
  },
  category: {
    chapter: 'Kapitel'
  },
  favorites: {
    favorites: 'Favoriten',
    word: 'Wort',
    sentence: 'Satz',
    sentences: 'Sätze',
    texts_videos: 'Texte und Videos',
    wordmaps: 'Wortkarten',
    saved: 'Gespeichert',
    title: 'Titel',
    type: 'Typ',
    no_favorites_found: 'Keine Favoriten gefunden',
    filtered_by: 'gefiltert nach',
    train: 'Trainieren',
    notify_me_srs: 'Benachrichtige mich, um den Wortschatz zu wiederholen',
    srs_level: 'SRS-Level',
    success_rate: 'Erfolgsquote',
    last_seen: 'Zuletzt gesehen',
    next_review: 'Nächste Wiederholung',
    success_level_up: 'Wort wurde auf Level {level} verschoben',
    what_is_srs: 'Was ist SRS?',
    srs_short:
      'SRS ist ein System zur wiederholten Abfrage. Es hilft Dir, Wörter besser zu merken, indem sie zur richtigen Zeit wiederholt werden.',
    srs_long:
      'Das System basiert auf dem Prinzip, dass Du ein Wort umso besser behältst, je öfter Du es wiederholst. Die Intervalle zwischen den Wiederholungen werden vom System bestimmt. Wenn Du ein Wort gut erinnerst, werden die Intervalle länger. Wenn Du Schwierigkeiten hast, werden die Intervalle kürzer. Auf diese Weise kannst Du Wörter effizienter lernen.',
    srs_intervals: 'Die {nIntervals} Intervalle sind: {intervals} Tage.',
    when_does_a_word_move: 'Wann kommt ein Wort auf das nächste Level?',
    srs_next_level_example_1:
      'Wenn Du ein Wort zu den Favoriten hinzufügst, ist es auf Level 1. Ein Wort auf Level 1 kommt auf das nächste Level, wenn 1 Tag vergangen ist, seit Du das Wort hinzugefügt hast, Deine Erfolgsquote über 50% liegt und Du das Wort gerade korrekt erinnert hast.',
    srs_next_level_example_2:
      'Ein Wort auf Level {level} kommt auf das nächste Level, nachdem die letzte Änderung auf Level {levelM1} mindestens {days} Tage her ist, Deine Erfolgsquote über 50% liegt und Du das Wort gerade korrekt erinnert hast.'
  },
  player: {
    maximize: 'Vergrößern',
    minimize: 'Verkleinern'
  },
  video: {
    error:
      'Es ist ein Fehler beim Laden der Untertitel aufgetreten. Leider sind nicht alle Untertietel frei zugänglich. Bitte versuche ein anderes Video.',
    subtitles: 'Untertitel'
  },
  feeds: {
    disclaimer:
      'Dies ist eine automatisch generierte Zusammenfassung von Chatgpt. Der Originalartikel ist verfügbar',
    here: 'hier',
    generating_article: 'Generiere Artikel...',
    error:
      'Es ist ein Fehler beim Laden des Artikels aufgetreten. Leider sind nicht alle Artikel frei zugänglich. Bitte versuche es mit einem anderen Artikel oder kopiere den Text manuell',
    open_org: '1. Originalartikel öffnen und Text kopieren',
    open_own: '2. Text hier einfügen'
  },
  books: {
    read_now: 'Jetzt lesen',
    continue_on_page: 'Weiter auf Seite'
  },
  tooltip: {
    examples: 'Beispiele',
    emotional_examples: 'Emotionale Beispiele',
    definition: 'Definition',
    synonyms: 'Synonyme',
    antonyms: 'Antonyme',
    article: 'Artikel',
    conjugations: 'Konjugation',
    grammer: 'Grammatik'
  },
  custom_text: {
    enter_your_own_text: 'Gib deinen eigenen Text ein',
    read_text: 'Text lesen',
    enter_new_text: 'Neuen Text eingeben',
    own_text: 'Kopiere Deinen eigenen Text und füge ihn hier ein',
    special_vocabulary: 'Spezieller Wortschatz'
  },
  special_vocabulary: {
    special_vocabulary: 'Spezialwortschatz',
    describe:
      'Gib einen Text auf Englisch über ein Thema oder einen speziellen Wortschatz ein, den Du lernen möchtest',
    language_level: 'Sprachniveau',
    generate_text: 'Text generieren'
  },
  intro_message: {
    save_word_as_fav:  'Speichere das Wort als Favorit',
    save_video_as_fav: 'Speichere das Videos als Favorit',
    save_article_as_fav: 'Speichere den Artikel als Favorit',
    save_book_as_fav: 'Speichere das Buch als Favorit',
    save_wordmap_as_fav: 'Speichere die Wortkarte als Favorit',
    play_word: 'Höre die Aussprache des Worts an',
    play_article: 'Höre den Artikel an',
    play_book: 'Höre das Buch an',
    select_main_category: 'Wähle eine Hauptkategorie',
    select_sub_category: 'Wähle eine Unterkategorie',
    click_on_read: 'Klicke auf `LESEN` um den ganzen Text zu lesen',
    click_on_view: ' Klicke auf `ANSCHAUEN` um ein Video zu sehen',
    last_book_page: 'Dein letzte Seite wird automatisch gespeichert und hier angezeigt',
    wordmap_click_on_word: 'Klicke auf das Wort um die am häufigsten verwendeten Wörter zu sehen',
    wordmap_click_on_line: 'Klicke auf eine Linie zwischen zwei Wörtern um einen Satz zu sehen',
    category_progress: 'Verfolge Deinen Fortschritt in dem Du die Checkboxen aktivierst',
    conversations_select_scene: 'Wähle eine Übung aus',
    conversations_warn: 'Es scheint einen Fehler mit Deinem Satz zu geben. Klicke auf das Dreieck für weitere Erklärungen.',
    conversations_task: 'Formuliere einen Satz nach der Anweisung am Ende des Chats',
  },
  tour_guide: {
    click_on_every_word: {
      title: 'Wort übersetzen',
      text: 'Du kannst auf jedes Wort klicken um Übersetzungen, Beispielsätze und Definitionen zu sehen'
    },
    click_on_i_for_sentence: {
      title: 'Ganzer Satz',
      text: 'Um einen ganzen Satz zu übersetzen, klick auf das i-Symbol. Hier bekommst Du auch Erklärungen zur Grammatik.'
    },
    no_save_custom: {
      title: 'Speicher nicht möglich',
      text: 'Eigene Texte können nicht gespeichert werden'
    },
    change_playback_rate: {
      title: 'Abspielgeschwindigkeit ändern',
      text: 'Ändere die Abspielgeschwindigkeit über Menu/Einstellungen/Audio und Video'
    },
    theme: {
      title: 'Farben',
      text: 'Du kannst die Farben im Menü auf Dunkelmodus umstellen'
    },
    explain_menu: {
      title: 'Menü',
      text: 'Die Menüpunkte sind von sehr schwer bis leicht von oben nach unten sortiert'
    },
    repeat_words: {
      title: 'Vokabeln wiederholen',
      text: 'Du solltest {nWords} Vokabeln wiederholen.',
      call_to_action: 'Jetzt lernen'
    }
  },
  chat: {
    your_message: 'Deine Nachricht',
    in_de: 'auf deutsch',
    in_en: 'auf englisch',
    in_sp: 'auf spanisch',
    in_fr: 'auf französisch',
    in_it: 'auf italienisch',
    in_pt: 'auf portugiesisch',
    in_ru: 'auf russisch',
    in_zh: 'auf chinesisch',
    in_pl: 'auf polnisch',
    wait: 'Bitte warten...',
    speak_now: 'Sprich jetzt',
    new_chat: 'Neuer Chat'
  },
  langs: {
    de: 'Deutsch',
    en: 'Englisch',
    sp: 'Spanisch',
    fr: 'Französisch',
    it: 'Italienisch',
    pt: 'Portugiesisch',
    ru: 'Russisch',
    zh: 'Chinesisch',
    pl: 'Polnisch'
  },
  about: {
    how_does_it_work: 'Wie funktioniert das?',
    here_you_can: 'Hier kannst du',
    read_texts: 'Texte lesen',
    watch_videos: 'Videos anschauen',
    click_on_every_wrord_for: 'Klcke auf ein beliebiges Wort für',
    translations: 'Übersetzungen',
    examples: 'Beispielsätze',
    definitions: 'Definitionen',
    synonyms: 'Synonyme',
    copy_your_own_text: 'Kopiere und lese deine eigenen Texte',
    chat_with_chatbot: 'Chatte mit einem Chatbot',
    level: 'Dein Niveau sollte zwischen B1 und C2 liegen'
  },
  settings: {
    audio_video: 'Audio und Video',
    colors: 'Farben',
    dark_mode: 'Dunkelmodus',
    lang_level: 'Sprachniveau'
  },
  error: {
    resource_not_found: 'Leider konnten wir nicht finden, was Du suchst'
  },
  train: {
    check: 'Überprüfen',
    flashcards: 'Karteikarten',
    exercises: 'Übungen',
    finished: 'Übungen beendet',
    go_to_favorites: 'Zu Favoriten',
    restart_exercise: 'Übung neu starten',
    go_to_home: 'Zur Startseite',
    text_with_words: 'Text mit Wörtern',
    text_includes: 'Der Text enthält die folgenden Wörter'
  },
  wordmap: {
    click_on_circle:
      'Klicke auf den Kreis um die am häufigsten verwendeten Wörter mit {word} zu sehen',
    click_on_edge:
      'Klicke auf die Linie zwischen zwei Wörtern um einen Satz mit beiden Wörtern zu sehen',
    open_fullscreen: 'Öffne Wordmap in Vollbild',
    add_own_word: 'Eigenes Wort hinzufügen',
    add_sentence_with_all_words: 'Satz mit allen Wörtern hinzufügen',
    add_to_word: 'Zu Wort hinzufügen'
  },
  conversations: {
    you_are: 'Du bist',
    you_talk_to: 'Du sprichst mit',
    your_tasks: 'Deine Aufgaben',
  },
  write: {
    min_words: 'Minimale Wörter',
    your_email_needs_to_contain: 'Deine E-Mail muss enthalten',
    rate: 'Bewertung',
    analysis: 'Analyse',
    overall: 'Gesamt',
    grammar: 'Grammatik',
    content: 'Inhalt',
    spelling: 'Rechtschreibung'
  }
};
