<script setup lang="ts">
import stores from '@jaaluu/stores';
import { t } from '@shared/i18n';
import sharedStores from '@shared/shared-stores';
import { marked } from 'marked';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const { tourGuideIsOpen, currentTour } = stores.tourGuide.state;
const { inDarkTheme } = stores.settings.state;
const { animateAnime } = sharedStores.animate.funcs;
const { endTour } = stores.tourGuide.funcs;
const { toggleTheme } = stores.settings.funcs;

const router = useRouter();
const currentStep = ref(0);

const animate = () => {
  setTimeout(() => {
    if (currentStep.value === 0) {
      animateAnime(`#card-${currentStep.value}`, { translateX: [-500, 0], scale: [0, 1] }, 2000);
    } else {
      animateAnime(`#card-${currentStep.value - 1}`, { translateX: [0, -50] }, 4000);
      animateAnime(`#card-${currentStep.value - 1}`, { translateX: [-50, 0], delay: 2000 }, 4000);
    }
    animateAnime(
      `#tour-guide-title-${currentStep.value}`,
      { opacity: [0.8, 1], translateY: [-50, 0], delay: 300 },
      1000
    );
    animateAnime(
      `#tour-guide-text-${currentStep.value}`,
      { opacity: [0, 1], delay: 1000, easing: 'easeOutQuad' },
      2000
    );
    animateAnime(
      `#tour-guide-actions-${currentStep.value}`,
      { opacity: [0, 1], delay: 2000, easing: 'easeOutQuad', translateY: [50, 0] },
      1000
    );
  }, 10);
};

watch(
  () => currentTour.value,
  () => {
    animate();
  }
);
watch(
  () => currentStep.value,
  () => {
    animate();
  }
);
</script>

<template>
  <v-dialog
    class="tour-guide-card"
    max-width="300"
    v-model="tourGuideIsOpen"
    v-if="currentTour"
    persistent
  >
    <v-window v-if="currentTour" v-model="currentStep">
      <v-window-item
        v-for="(step, stepIdx) in currentTour.steps"
        :key="stepIdx"
        :id="`card-${currentStep}`"
      >
        <v-card color="blue">
          <v-card-title :id="`tour-guide-title-${currentStep}`">
            <v-icon class="mr-2" size="x-small" v-if="step.icon" :icon="step.icon" />{{
              step.title
            }}</v-card-title
          >
          <v-card-text :id="`tour-guide-text-${currentStep}`"
            ><div v-html="marked.parse(step.text)" />
            <div v-if="currentTour.id === 'theme-tour'">
              <v-switch
                color="white"
                v-model="inDarkTheme"
                @update:model-value="toggleTheme"
                :label="t('settings.dark_mode')"
              ></v-switch>
            </div>
            <div v-if="step.callToAction" class="mt-3">
              <v-btn
                variant="outlined"
                size="x-small"
                @click="
                  () => {
                    endTour();
                    step.callToAction?.action(router);
                  }
                "
                color="white"
                >{{ step.callToAction.text }}</v-btn
              >
            </div>
          </v-card-text>
          <v-card-actions class="d-flex" :id="`tour-guide-actions-${currentStep}`">
            <div class="d-flex justify-space-between w-100">
              <v-btn
                v-if="currentStep > 0 && currentTour.steps.length > 1"
                @click="currentStep--"
                variant="tonal"
                color="white"
                size="x-small"
                >{{ t('general.back') }}</v-btn
              >
              <div class="dummy-div" v-else></div>
              <v-btn
                v-if="currentStep === currentTour.steps.length - 1"
                variant="tonal"
                color="white"
                size="x-small"
                @click="endTour"
                >Ok</v-btn
              >
              <v-btn v-else @click="currentStep++" variant="tonal" color="white" size="x-small">{{
                t('general.next')
              }}</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-window-item>
    </v-window>
  </v-dialog>
</template>

<style lang="scss" scoped>
.dummy-div {
  width: 100%;
}
.tour-guide-card {
  z-index: $z-level-11 !important;
}
</style>
