<script setup lang="ts">
import sharedStores from '@shared/shared-stores';

const { snackbars } = sharedStores.snackbar.state;

const remove = (id: number) => {
  snackbars.value = snackbars.value.filter((s: any) => s.id !== id);
};
</script>

<template>
  <!-- Snackbar stacks showing snackbars on bottom (max 5) -->
  <div v-for="(snackbar, counter) in snackbars.slice(0, 8)" :key="snackbar.id">
    <v-snackbar
      class="snackbar"
      :rounded="0"
      :style="{
        'margin-bottom': counter === 0 ? `8px !important` : `${8 + counter * 53}px !important`
      }"
      :color="snackbar.type"
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
    >
      <div class="d-flex justify-space-between max">
        <v-icon v-if="snackbar.icon" :icon="snackbar.icon" color="white" />
        <div>{{ snackbar.text }}</div>
        <v-btn class="ml-2" variant="text" size="x-small" @click="remove(snackbar.id)">Ok</v-btn>
      </div>
    </v-snackbar>
  </div>
</template>

<style lang="scss" scoped>
.max {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.snackbar {
  z-index: $z-level-7;
}
</style>
