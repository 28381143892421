<script lang="ts" setup>
import { GAnalyticsEvent } from '@jaaluu/interfaces/GAnalyticEvent';
import stores from '@jaaluu/stores';
import i18n, { t } from '@shared/i18n';
import { Language } from '@shared/openapi/jaaluu-api';
import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps({
  fromWelcome: {
    type: Boolean,
    required: false,
    default: false
  }
});
const router = useRouter();
const { setSelectedLangsToLocalStore } = stores.language.funcs;
const { sendEvent } = stores.gAnalytics.funcs;
const BASE_URL_APP = (import.meta as any).env.VITE_JAALUU_URL;
const { langNative, langForeign, langOptions } = stores.language.state;
const _langNative = ref<Language | null>(null);

onMounted(() => {
  if (!langNative.value) {
    _langNative.value = Language.EN;
  } else {
    _langNative.value = langNative.value;
  }
});

const atUpdateLangNative = () => {
  i18n.global.locale = _langNative.value as any;
  sendEvent(GAnalyticsEvent.SELECT_LANGUAGE_NATIVE, { change_to_lang: _langNative.value });
  langForeign.value = null;
  langNativeRef.value.blur();
  if (props.fromWelcome) {
    const url = BASE_URL_APP.replace('://', `://${_langNative.value}.`) + '/welcome';
    window.location.href = url;
  }
};

const atUpdateLangForeign = () => {
  if (!props.fromWelcome) {
    setSelectedLangsToLocalStore(langForeign.value, langNative.value);
  }
  sendEvent(GAnalyticsEvent.SELECT_LANGUAGE_FOREIGN, { change_to_lang: langForeign.value });
  if (_langNative.value === langNative.value && !props.fromWelcome) {
    router.push(`/${langForeign.value}`);
  } else if (!props.fromWelcome) {
    const url = BASE_URL_APP.replace('://', `://${_langNative.value}.`) + `/${langForeign.value}`;
    window.location.href = url;
  }
};

const langNativeRef = ref();

watch(
  () => langNative.value,
  () => {
    _langNative.value = langNative.value;
    i18n.global.locale = langNative.value as any;
  }
);
</script>

<template>
  <v-select
    ref="langNativeRef"
    v-model="_langNative"
    :items="langOptions"
    :label="t('welcome.your_language')"
    variant="underlined"
    color="green"
    hide-details
    @update:model-value="() => atUpdateLangNative()"
  >
    <template v-slot:selection="{ item }">
      <img :src="item.raw.flag" alt="" class="flag-icon mr-2" /> {{ item.title }}
    </template>
    <template v-slot:item="{ props, item }">
      <v-list-item v-bind="props">
        <template v-slot:prepend>
          <img :src="item.raw.flag" alt="" class="flag-icon mr-2" />
        </template>
      </v-list-item>
    </template>
  </v-select>

  <div v-if="langNative">
    <v-select
      v-model="langForeign"
      :items="langOptions.filter((l) => l.value !== langNative)"
      :label="t('welcome.language_to_learn')"
      variant="underlined"
      color="green"
      class="mt-8 w-300"
      hide-details
      @update:model-value="() => atUpdateLangForeign()"
    >
      <template v-slot:selection="{ item }">
        <img :src="item.raw.flag" alt="" class="flag-icon mr-2" /> {{ item.title }}
      </template>
      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props">
          <template v-slot:prepend>
            <img :src="item.raw.flag" alt="" class="flag-icon mr-2" />
          </template>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>
<style lang="scss" scoped>
.list-item {
  cursor: pointer;
  &:hover {
    color: #81c784 !important;
  }
}
.flag-icon {
  width: 25px;
}
</style>
