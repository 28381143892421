import { Word } from '@jaaluu/interfaces/Tooltip.types';
import { split } from 'sentence-splitter';


const markdownSymbols = {
  h6: '######',
  h5: '#####',
  h4: '####',
  h3: '###',
  h2: '##',
  h1: '#',
  code: '```',
  strike: '~~',
  bold: '**',
  italic: '*'
}

const regexes = {
  'h6': /^######\s+(.*)/gm,        // Matches H6 headers: "###### Header Text"
  'h5': /^#####\s+(.*)/gm,         // Matches H5 headers: "##### Header Text"
  'h4': /^####\s+(.*)/gm,          // Matches H4 headers: "#### Header Text"
  'h3': /^###\s+(.*)/gm,           // Matches H3 headers: "### Header Text"
  'h2': /^##\s+(.*)/gm,            // Matches H2 headers: "## Header Text"
  'h1': /^#\s+(.*)/gm,             // Matches H1 headers: "# Header Text"
  'code': /```([^]*?)```/gm,       // Matches code blocks: "```code```", supports multiline content
  'strike': /~~(.*?)~~/g,          // Matches strikethrough: "~~text~~"
  'bold': /\*\*(.*?)\*\*/g,        // Matches bold text: "**text**"
  'italic': /\*(.*?)\*/g           // Matches italic text: "*text*"
};

export default () => {
  const extractImageUrls = (s: any) => {
    const span = document.createElement('span');
    span.innerHTML = s;
    const imageUrls = [];
    const contents = span.children[0].children;
    for (let i = 0; i <= contents.length; i++) {
      const child = contents[i];
      if (child && child.tagName === 'DIV' && child.classList.contains('thumb')) {
        const image: any = child.querySelector('img');
        if (image) {
          imageUrls.push(image.src);
        }
      }
    }
    return imageUrls;
  };

  const extractContents = (s: any) => {
    const span = document.createElement('span');
    span.innerHTML = s;
    const content = [];
    const contents = span.children[0].children;
    for (let i = 0; i <= contents.length; i++) {
      const child: any = contents[i];
      if (child && child.tagName === 'P') {
        content.push(child.textContent || child.innerText);
      }
    }
    return content;
  };

  const getSentenceWithStyle = (sentence: string) => {
    const _sentStyle: Word[] = [];
    let style: string  = ''
    for (let word of sentence.split(' ')) {
      if (word === '') {
        continue;
      }
      let resetStyle = false
      for (const [key, regex] of Object.entries(regexes)) {
        const keyT = key as keyof typeof markdownSymbols;
        const replace = markdownSymbols[keyT]
        if (regex.test(word)) {
          style = key;
          word = word.replaceAll(replace, '');
          resetStyle = true;
        } else if (word.startsWith(key)) {
          style = key;
          word = word.replaceAll(replace, '');
        } else if (word.endsWith(key)) {
          resetStyle = true;
          word = word.replaceAll(replace, '');
        } else if (word.includes(key)) {
          style = key;
          word = word.replaceAll(replace, '');
          if (style !== '') {
            resetStyle = true;
          }
        }
      }
      _sentStyle.push({
        text: word,
        style
      });
      if (resetStyle) {
        style = '';
      }
    }
    return _sentStyle;
  };

  const splitBySentences = async (text: string, lang: string | null = null) => {
    let _sentences: Word[][] = [];
    if (!lang || lang !== 'zh') {
      const stentencesObj = split(text);
      let sentence = '';
      for (const el of stentencesObj) {
        if (el.type === 'WhiteSpace' && el.raw.startsWith('\n')) {
          if (sentence !== '') {
            _sentences.push(getSentenceWithStyle(sentence));
            sentence = '';
          }
          _sentences.push([{ text: '\n', style: '' }]);
        } else if (el.type === 'Sentence') {
          for (const sentenceEl of el.children) {
            if (sentenceEl.type === 'WhiteSpace' && sentenceEl.raw.startsWith('\n')) {
              _sentences.push(getSentenceWithStyle(sentence));
              _sentences.push([{ text: '\n', style: '' }]);
              sentence = '';
            } else if (sentenceEl.type === 'Punctuation') {
              sentence += sentenceEl.raw.replace('\n', ' ') + ' ';
            } else {
              sentence += sentenceEl.raw;
            }
          }
          _sentences.push(getSentenceWithStyle(sentence));
          sentence = '';
        }
      }
      if (sentence !== '') {
        _sentences.push(getSentenceWithStyle(sentence));
      }

      /* _sentences = stentencesObj.map((s) =>
        s.type === 'Sentence'
          ? s.children.map((c: any) => c.raw.split(' ').filter(removeEmpty)).flat()
          : s.raw.split(' ').filter(removeEmpty)
      ) */
      return Promise.resolve(_sentences.filter((s: Word[]) => s.length > 0));
    } else {
      /* await jaaluuApi.get(`/sentences/split-chinese-sentence/${text}`).then((res: any) => {
        _sentences.push(res.data.words);
      }); */
      return _sentences;
    }
  };
  return { funcs: { splitBySentences, extractContents, extractImageUrls } };
};
