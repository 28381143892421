import { ref } from 'vue';
const MAX_WORDS_BLOCK = 15;
export default () => {
  const activeText = ref('');

  const getMaxText = (text: string, maxWords = 30) => {
    const words = text.split(' ');
    if (words.length <= maxWords) {
      return text;
    }
    return words.slice(0, maxWords).join(' ') + '...';
  };
  return { state: { activeText }, funcs: { getMaxText }, consts: { MAX_WORDS_BLOCK } };
};
