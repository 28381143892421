import useApp from './app';
import useBooks from './books';
import useCategory from './category';
import useExtractor from './extractor';
import useFavorites from './favorites';
import useFeed from './feed';
import useKlexikon from './klexikon';
import useLanguage from './language';
import useRequests from './requests';
import useSettings from './settings';
import useText from './text';
import useTourGuide from './tour-guide';
import useTooltip from './tooltip';
import useTranslate from './translate';
import useVideo from './video';
import useWikipedia from './wikipedia';
import useError from './error';
import useProgress from './progress';
import useAssets from './assets';
import useFiles from './files';
import useUser from './user';
import useAutoText from './auto-text';
import useIntroMessage from './intro-message';
import useCookies from './cookies';
import useGAnalytics from './g-analytics';

export const user = useUser();
export const language = useLanguage();
export const settings = useSettings();
export const favorites = useFavorites();
export const error = useError();
export const tourGuide = useTourGuide();
export const requests = useRequests();
export const tooltip = useTooltip();
export const assets = useAssets();
export const app = useApp();
export const extractor = useExtractor();
export const text = useText();
export const video = useVideo();
export const wikipedia = useWikipedia();
export const feed = useFeed();
export const translate = useTranslate();
export const klexikon = useKlexikon();
export const books = useBooks();
export const category = useCategory();
export const progress = useProgress();
export const files = useFiles();
export const autoText = useAutoText();
export const introMessage = useIntroMessage();
export const cookies = useCookies();
export const gAnalytics = useGAnalytics();

export default {
  requests,
  language,
  tooltip,
  settings,
  app,
  extractor,
  text,
  video,
  wikipedia,
  feed,
  favorites,
  translate,
  klexikon,
  books,
  category,
  error,
  tourGuide,
  progress,
  assets,
  files,
  user,
  autoText,
  introMessage,
  cookies,
  gAnalytics
};
