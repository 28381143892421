import { ref, Ref } from 'vue';

import { Language, VideoDef } from '@shared/openapi/jaaluu-api';

export default () => {
  const ytInitDone = ref(false);
  const maxLoadVideos: Ref<Record<string, number> | null> = ref(null);
  const ytChannels: Ref<Record<Language, VideoDef[]> | null> = ref(null);

  return { state: { ytInitDone, ytChannels, maxLoadVideos }, consts: {} };
};
