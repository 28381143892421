<script setup lang="ts">
import IntroMessage from '@jaaluu/components/IntroMessage.vue';
import TextAndAudio from '@jaaluu/components/TextAndAudio.vue';
import WordLinks from '@jaaluu/components/WordLinks.vue';
import stores from '@jaaluu/stores';
import { FavType } from '@jaaluu/stores/favorites';
import { IntroMessageType } from '@jaaluu/stores/intro-message';
import { Tooltip } from '@jaaluu/stores/tooltip';
import Wordmap from '@jaaluu/views/Wordmap/Wordmap.vue';
import { t } from '@shared/i18n';
import { WordInfoService } from '@shared/openapi/jaaluu-api';
import sharedStores from '@shared/shared-stores';
import { PropType, Ref, computed, onMounted, ref } from 'vue';

const props = defineProps({
  tooltip: {
    required: true,
    type: Object as PropType<Tooltip>
  }
});
const { langNative, langForeign } = stores.language.state;
const { favWordList, favSentenceList } = stores.favorites.state;
const { toggleFavorite } = stores.favorites.funcs;
const { removeTooltip } = stores.tooltip.funcs;
const { handleDefaultError } = stores.error.funcs;
const { getImageFileUrl } = stores.files.funcs;
const { animate, animateAnime } = sharedStores.animate.funcs;
const { setIntroMsgSeen } = stores.introMessage.funcs;

const wordInfo = ref({
  translation: null,
  example: null,
  synonyms: null,
  extensions: [],
  definition: {
    native: null,
    foreign: null
  },
  antonyms: null,
  wordType: '',
  article: null,
  conjugations: null,
  grammer: null,
  image: {
    sentence: null,
    url: null
  },
  wordmap: false
});
const imageUrls: Ref<any> = ref([null, null, null]);
const examples: Ref<any> = ref([]);
const emoExamples: Ref<any> = ref(null);
const expansionPanelKeys: Ref<number | null> = ref(null);
const expansionPanelKeysSentence: Ref<number | null> = ref(null);
const tooltipContentEng = ref('');
const maxHeights: Ref<any> = ref({});
const hideShowMoreButtons: Ref<string[]> = ref([]);
const hasMoreContent = ref(false);
const loadExplanation = ref(false);
const cleanContent = computed(() => {
  let str = props.tooltip.content;
  ['.', ',', '!', '?', ';'].forEach((rep: string) => {
    str = str.replace(rep, '');
  });
  return str;
});

const resteValus = () => {
  console.log('resetting values');
  loadExplanation.value = false;
  expansionPanelKeys.value = null;
  imageUrls.value = [null, null, null];
  maxHeights.value = {};
  hideShowMoreButtons.value = [];
  examples.value = [];
  emoExamples.value = null;
  wordInfo.value = {
    translation: null,
    example: null,
    synonyms: null,
    definition: {
      native: null,
      foreign: null
    },
    grammer: null,
    antonyms: null,
    extensions: [],
    wordType: '',
    article: null,
    conjugations: null,
    image: {
      sentence: null,
      url: null
    },
    wordmap: false
  };
  tooltipContentEng.value = '';
  hasMoreContent.value = false;
};

// @Watch("$store.state.tooltip.open")
const getWordInfo = () => {
  if (props.tooltip.open && langForeign.value && langNative.value) {
    const query: any = {
      q: cleanContent.value,
      context_sentence: props.tooltip.sentence,
      foreign: props.tooltip.isReverseTranslation ? langNative.value : langForeign.value,
      native: props.tooltip.isReverseTranslation ? langForeign.value : langNative.value
    };
    WordInfoService.getTranslationV1WordInfoTranslationGet({ query })
      .then((res: any) => {
        wordInfo.value.translation = res.data.translation;
        wordInfo.value.wordType = res.data.word_type.toLowerCase();
        if (props.tooltip.openedCount === 1) {
          localStorage.setItem('tooltipOpenedCount', props.tooltip.openedCount.toString());
          setTimeout(() => {
            animateAnime('#tooltip-word', { opacity: [0.8, 1], translateX: [50, 0] }, 1500);
            animate('#tooltip-word', 'color', 1500);
          }, 500);
          setTimeout(() => {
            animateAnime('#tooltip-translation', { opacity: [0.8, 1], translateX: [50, 0] }, 1500);
            animate('#tooltip-translation', 'color', 1500);
          }, 2000);
        }
      })
      .catch(handleDefaultError);
    WordInfoService.getImagesUnsplashV1WordInfoImagesGet({ query })
      .then((res: any) => {
        imageUrls.value = res.data.image_urls;
      })
      .catch(handleDefaultError);

    setArticle();
  } else {
    resteValus();
  }
};

const openSynonyms = () => {
  if (wordInfo.value.synonyms) return;
  if (!langForeign.value) return;
  const query = {
    q: cleanContent.value,
    foreign: langForeign.value
  };
  WordInfoService.getSynonymsV1WordInfoSynonymsGet({ query })
    .then((res: any) => {
      wordInfo.value.synonyms = res.data.synonyms;
    })
    .catch(handleDefaultError);
};

const openAntonyms = () => {
  if (wordInfo.value.antonyms) return;
  if (!langForeign.value) return;
  const query = {
    q: cleanContent.value,
    foreign: langForeign.value
  };
  WordInfoService.getAntonymsV1WordInfoAntonymsGet({ query })
    .then((res: any) => {
      wordInfo.value.antonyms = res.data.antonyms;
    })
    .catch(handleDefaultError);
};
const openDefinition = () => {
  if (wordInfo.value.definition.native) return;
  if (!langForeign.value || !langNative.value) return;
  const query = {
    q: cleanContent.value,
    context_sentence: props.tooltip.sentence,
    foreign: langForeign.value,
    native: langNative.value
  };
  WordInfoService.getDefinitionV1WordInfoDefinitionGet({ query })
    .then((res: any) => {
      wordInfo.value.definition = res.data.definition;
    })
    .catch(handleDefaultError);
};

const setArticle = () => {
  if (wordInfo.value.article) return;
  if (!langForeign.value || !langNative.value) return;
  const query = {
    q: cleanContent.value,
    context_sentence: props.tooltip.sentence,
    foreign: langForeign.value,
    native: langNative.value
  };
  WordInfoService.getArticleV1WordInfoArticleGet({ query })
    .then((res: any) => {
      wordInfo.value.article = res.data.article.toLowerCase();
    })
    .catch(handleDefaultError);
};

const openConjunctions = () => {
  if (wordInfo.value.conjugations) return;
  if (!langForeign.value || !langNative.value) return;
  const query = {
    q: cleanContent.value,
    context_sentence: props.tooltip.sentence,
    foreign: langForeign.value,
    native: langNative.value
  };
  WordInfoService.getConjugationsV1WordInfoConjugationsGet({ query })
    .then((res: any) => {
      wordInfo.value.conjugations = res.data.conjugations;
    })
    .catch(handleDefaultError);
};

const openGrammer = () => {
  if (wordInfo.value.extensions.length > 0) return;
  if (!langForeign.value || !langNative.value || !props.tooltip.sentence) return;
  const query = {
    q: props.tooltip.sentence,
    foreign: langForeign.value,
    native: langNative.value
  };
  WordInfoService.getGrammerV1WordInfoGrammerGet({ query })
    .then((res: any) => {
      wordInfo.value.grammer = res.data.grammer;
    })
    .catch(handleDefaultError);
};

const openImage = () => {
  if (wordInfo.value.image.sentence) return;
  if (!langForeign.value || !langNative.value) return;
  const query = {
    q: cleanContent.value,
    foreign: langForeign.value
  };
  WordInfoService.getImageSentenceV1WordInfoImageSentenceGet({ query })
    .then((res: any) => {
      wordInfo.value.image.sentence = res.data.sentence;
      wordInfo.value.image.url = res.data.url;
    })
    .catch(handleDefaultError);
};

const openWordmap = () => {
  wordInfo.value.wordmap = true;
};

const atUpdateExpansionPanel = () => {
  if (expansionPanelKeys.value === undefined || expansionPanelKeys.value === null) return;
  const panels = [
    openExamples,
    openDefinition,
    openWordmap,
    openImage,
    openEmotionalExamples,
    openSynonyms,
    openAntonyms,
    openConjunctions
  ];
  expansionPanelKeys.value.forEach((key: number) => {
    panels[key]();
  });
};
const atUpdateExpansionPanelSentence = () => {
  if (expansionPanelKeysSentence.value === undefined || expansionPanelKeysSentence.value === null)
    return;
  console.log('expansionPanelKeysSentence', expansionPanelKeysSentence.value);
  const panels = [openGrammer];
  panels[expansionPanelKeysSentence.value]();
};

const openExamples = () => {
  if (examples.value.length > 0) return;
  if (!langForeign.value || !langNative.value) return;
  const query = {
    q: cleanContent.value,
    context_sentence: props.tooltip.isReverseTranslation ? null : props.tooltip.sentence,
    foreign: props.tooltip.isReverseTranslation ? langNative.value : langForeign.value,
    native: props.tooltip.isReverseTranslation ? langForeign.value : langNative.value,
    count: 3
  };
  WordInfoService.getExamplesV1WordInfoExamplesGet({ query })
    .then((res: any) => {
      examples.value = res.data.examples;
    })
    .catch(handleDefaultError);
};

const openEmotionalExamples = () => {
  if (examples.value.length > 0) return;
  if (!langForeign.value || !langNative.value) return;
  const query = {
    q: cleanContent.value,
    context_sentence: props.tooltip.isReverseTranslation ? null : props.tooltip.sentence,
    foreign: props.tooltip.isReverseTranslation ? langNative.value : langForeign.value,
    native: props.tooltip.isReverseTranslation ? langForeign.value : langNative.value
  };
  WordInfoService.getEmotionalExamplesV1WordInfoEmotionalExamplesGet({ query })
    .then((res: any) => {
      emoExamples.value = res.data.examples;
    })
    .catch(handleDefaultError);
};

const getHtml = (text: string) => {
  // Regular expression to extract content regardless of the format string
  const pattern = /```(.*?)\n([\s\S]*?)\n```/;

  // Match the pattern
  const match = text.match(pattern);

  if (match) {
    return match[2]; // The second group is the content
  }
};

const wordTypeIsNoun = computed(() => {
  if (!wordInfo.value.wordType) return false;
  return wordInfo.value.wordType?.toLowerCase() === 'noun';
});

const wordTypeIsVerb = computed(() => {
  if (!wordInfo.value.wordType) return false;
  return wordInfo.value.wordType?.toLowerCase() === 'verb';
});

const isFavorite = computed(() => {
  if (props.tooltip.isCompleteSentence) {
    return favSentenceList.value.includes(cleanContent.value);
  }
  return favWordList.value.includes(cleanContent.value);
});

onMounted(() => {
  getWordInfo();
});
</script>

<template>
  <v-card class="relative tooltip-card" :class="{ 'max-width-small': expansionPanelKeys !== 4 }">
    <div class="d-flex overflow-x-hidden">
      <div v-for="(imageUrl, index) in imageUrls.slice(0, 3)" :key="index" class="image_33">
        <v-img height="100" :src="imageUrl" v-if="imageUrl" cover> </v-img>
        <v-skeleton-loader
          v-else
          height="100"
          tile
          class="skeleton-image"
          type="image"
        ></v-skeleton-loader>
      </div>
    </div>
    <v-btn
      class="close-icon"
      size="x-small"
      icon
      @click="() => removeTooltip(tooltip.idx)"
      color="secondary"
    >
      <v-icon size="18">mdi-close</v-icon>
    </v-btn>

    <v-card-text class="overflow-auto pa-0">
      <div class="d-flex align-center mt-2 ml-2">
        <TextAndAudio
          id="tooltip-word"
          :text="cleanContent"
          textClass="title-16 bold d-flex align-center"
          class="mr-4"
          @audio-started="() => setIntroMsgSeen(IntroMessageType.PLAY_WORD)"
        />
        <v-btn
          variant="text"
          size="x-small"
          v-if="langForeign"
          @click="
            () => {
              setIntroMsgSeen(IntroMessageType.SAVE_WORD_AS_FAV);
              toggleFavorite(
                cleanContent,
                langForeign!,
                tooltip.isCompleteSentence ? FavType.SENTENCE : FavType.WORD
              );
            }
          "
          color="green"
          :icon="isFavorite ? 'mdi-star' : 'mdi-star-outline'"
        />
        <WordLinks class="ml-4" :word="cleanContent" :tooltip-idx="tooltip.idx" />
      </div>

      <!-- Content -->
      <div v-if="wordInfo.translation">
        <div
          class="mb-4 subtitle-2 native-text pl-2 mt-2 d-flex align-baseline"
          id="tooltip-translation"
        >
          <div>{{ wordInfo.translation }}</div>
          <div v-if="!tooltip.isCompleteSentence" class="word-type ml-8 subtitle">
            {{ wordInfo.wordType }}
          </div>
          <div
            class="subtitle"
            v-if="!tooltip.isCompleteSentence && wordTypeIsNoun && wordInfo.wordType"
          >
            ,
          </div>
          <div class="ml-2 subtitle" v-if="!tooltip.isCompleteSentence && wordTypeIsNoun">
            {{ wordInfo.article }}
          </div>
        </div>
      </div>

      <v-skeleton-loader
        v-else
        height="20"
        width="100"
        class="skeleton-no-margin ml-2 mb-4"
        type="text"
      ></v-skeleton-loader>

      <IntroMessage :type="IntroMessageType.SAVE_WORD_AS_FAV" icon="mdi-star" class="mt-2" />
      <IntroMessage :type="IntroMessageType.PLAY_WORD" icon="mdi-play" class="mt-2 mb-2" />
      <v-expansion-panels
        multiple
        variant="accordion"
        v-model="expansionPanelKeys"
        v-if="!tooltip.isCompleteSentence && !tooltip.isReverseTranslation"
        @update:modelValue="atUpdateExpansionPanel"
      >
        <v-expansion-panel>
          <v-expansion-panel-title
            ><v-icon icon="mdi-text-short" size="14" class="mr-2" />{{
              t('tooltip.examples')
            }}</v-expansion-panel-title
          >
          <v-expansion-panel-text>
            <ul class="ml-2">
              <li v-for="example in examples" :key="example.native" class="mb-3">
                <div><TextAndAudio :text="example.foreign" /></div>
                <div class="native-text mt-0">{{ example.native }}</div>
              </li>
            </ul>
            <v-skeleton-loader
              v-if="examples.length < 3"
              v-for="_ in Array.from({ length: 3 }, (_, i) => i + 1)"
              height="50"
              class=""
              type="sentences"
            ></v-skeleton-loader>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-title
            ><v-icon icon="mdi-exclamation" size="14" class="mr-2" />{{
              t('tooltip.definition')
            }}</v-expansion-panel-title
          >
          <v-expansion-panel-text>
            <div class="" v-if="wordInfo.definition.foreign">
              <div><TextAndAudio :text="wordInfo.definition.foreign" /></div>
              <div class="native-text mt-1">{{ wordInfo.definition.native }}</div>
            </div>
            <v-skeleton-loader v-else height="50" class="" type="sentences"></v-skeleton-loader>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-title
            ><v-icon icon="mdi-transit-connection-horizontal" size="14" class="mr-2" />{{
              t('general.wordmap')
            }}</v-expansion-panel-title
          >
          <v-expansion-panel-text>
            <Wordmap :word="cleanContent" />
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel :class="{ 'no-height': !wordTypeIsNoun }">
          <v-expansion-panel-title
            ><v-icon icon="mdi-image" size="14" class="mr-2" />{{
              t('general.image')
            }}</v-expansion-panel-title
          >
          <v-expansion-panel-text>
            <div v-if="wordInfo.image.sentence">
              <div class="d-flex justify-center mb-3">
                <TextAndAudio :text="wordInfo.image.sentence" />
              </div>
              <v-img
                class="mt-2 mx-auto"
                :max-width="200"
                :src="getImageFileUrl(wordInfo.image.url)"
                cover
              ></v-img>
            </div>
            <div class="skeleton-no-margin" v-else>
              <v-skeleton-loader height="20" type="text"></v-skeleton-loader>
              <v-skeleton-loader height="200" type="image"></v-skeleton-loader>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-title
            ><v-icon icon="mdi-heart" size="14" class="mr-2" />{{
              t('tooltip.emotional_examples')
            }}</v-expansion-panel-title
          >
          <v-expansion-panel-text>
            <div v-if="emoExamples" class="py-2">
              <div
                :key="def.type"
                v-for="(def, defIdx) in [
                  { type: 'positive', icon: 'mdi-emoticon-happy-outline' },
                  { type: 'negative', icon: 'mdi-emoticon-sad-outline' },
                  { type: 'joke', icon: 'mdi-emoticon-lol-outline' }
                ]"
              >
                <div class="d-flex align-center">
                  <v-icon :icon="def.icon" size="14" class="mr-3" />
                  <div>
                    <div>
                      <TextAndAudio :text="emoExamples[def.type].foreign.replaceAll('\n', '')" />
                    </div>
                    <div class="native-text">
                      {{ emoExamples[def.type].native.replaceAll('\n', '') }}
                    </div>
                  </div>
                </div>
                <v-divider v-if="defIdx < 2" class="my-4" />
              </div>
            </div>
            <v-skeleton-loader
              v-else
              v-for="_ in Array.from({ length: 3 }, (_, i) => i + 1)"
              height="50"
              class=""
              type="sentences"
            ></v-skeleton-loader>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-title
            ><v-icon icon="mdi-equal" size="14" class="mr-2" />{{
              t('tooltip.synonyms')
            }}</v-expansion-panel-title
          >
          <v-expansion-panel-text>
            <div class="d-flex align-center flex-wrap" v-if="wordInfo.synonyms">
              <div v-for="s in wordInfo.synonyms" :key="s" class="mr-4">
                <TextAndAudio :text="s" />
              </div>
            </div>
            <v-skeleton-loader v-else height="50" class="" type="sentences"></v-skeleton-loader>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-title
            ><v-icon icon="mdi-not-equal-variant" size="14" class="mr-2" />{{
              t('tooltip.antonyms')
            }}</v-expansion-panel-title
          >
          <v-expansion-panel-text>
            <div class="d-flex align-center flex-wrap" v-if="wordInfo.antonyms">
              <div v-for="a in wordInfo.antonyms" :key="a" class="mr-4">
                <TextAndAudio :text="a" />
              </div>
            </div>
            <v-skeleton-loader v-else height="50" class="" type="sentences"></v-skeleton-loader>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel v-if="wordTypeIsVerb">
          <v-expansion-panel-title
            ><v-icon icon="mdi-table" size="14" class="mr-2" />{{
              t('tooltip.conjugations')
            }}</v-expansion-panel-title
          >
          <v-expansion-panel-text
            :class="{ 'expansion-text-no-padding': wordTypeIsVerb, 'no-height': wordTypeIsNoun }"
          >
            <div v-if="wordInfo.conjugations" class="pb-12 unformatted-content table-strong">
              <div v-html="getHtml(wordInfo.conjugations)"></div>
            </div>
            <v-skeleton-loader
              v-else
              height="100"
              class=""
              type="paragraph,paragraph"
            ></v-skeleton-loader>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels
        v-model="expansionPanelKeysSentence"
        v-else-if="!tooltip.isReverseTranslation"
        @update:modelValue="atUpdateExpansionPanelSentence"
      >
        <v-expansion-panel>
          <v-expansion-panel-title
            ><v-icon icon="mdi-information-variant" size="14" class="mr-2" />{{
              t('tooltip.grammer')
            }}</v-expansion-panel-title
          >
          <v-expansion-panel-text>
            <div v-if="wordInfo.grammer">
              <div v-html="getHtml(wordInfo.grammer)"></div>
              <TextAndAudio :text="wordInfo.grammer" :play-icon-on-top="true" />
            </div>
            <v-skeleton-loader
              v-else
              height="200"
              class=""
              type="paragraph,paragraph"
            ></v-skeleton-loader>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
.tooltip-card {
  z-index: $z-level-10;
  min-width: 300px;
  max-height: 90vh;
  overflow-y: auto;
  width: 100%;
}
.image_33 {
  width: 34% !important;
}
.image_50 {
  width: 50% !important;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
audio {
  width: 111px;
  overflow: hidden;
  scale: 0.8;
}
h1 {
  margin-top: 30px;
}

h2 {
  margin-top: 20px;
}

h3 {
  margin-top: 10px;
  font-weight: 300;
}

h4 {
  margin-top: 10px;
}
.thumb {
  float: right;
  margin: 10px;
}
.close-icon {
  position: absolute !important;
  top: 10px;
  right: 10px;
  z-index: 1000;
}
dd {
  margin-left: 20px;
}
.wiktionary-html {
  overflow-y: hidden;
}
.v-expansion-panel-title {
  background-color: rgb(var(--v-theme-secondary));
  padding: 10px !important;
  min-height: 36px !important;
  border-radius: 0px !important;
}
.i-frame-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.i-frame {
  width: 100%;
  height: 70vh;
  flex-grow: 1;
  border: none;
  margin: 0;
  padding: 0;
}
.skeleton-image {
  border-right: 1px solid darkgray;
}
.native-text {
  color: darkgray;
}
.unformatted-content {
  overflow-x: auto;
}
.max-width-small {
  max-width: 550px;
}
.no-height {
  height: 0px !important;
  pointer-events: none;
  opacity: 0;
}
</style>
