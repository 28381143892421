<script lang="ts" setup>
import stores from '@jaaluu/stores';
import { t } from '@shared/i18n';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const { favSentencesLang, favWordmapsList } = stores.favorites.state;
const { langForeign } = stores.language.state;
const { removeTooltip } = stores.tooltip.funcs;
const props = defineProps({
  word: {
    type: String,
    required: true
  },
  tooltipIdx: {
    type: Number,
    required: true
  }
});

const router = useRouter();

const favWordMapLinksCount = computed(() => {
  if (favWordmapsList.value.includes(props.word)) {
    return 1;
  }
  return 0;
});

const favSentencesLinkCount = computed(() => {
  return favSentencesLang.value
    .map((s) => s.sentence.split(' '))
    .flat()
    .filter((w) => w === props.word).length;
});

const linkCount = computed(() => {
  return favWordMapLinksCount.value + favSentencesLinkCount.value;
});
const openUrl = (url: string) => {
  router.push(url);
  removeTooltip(props.tooltipIdx);
};
</script>

<template>
  <div v-if="linkCount > 0">
    <v-menu id="word-links">
      <template v-slot:activator="{ props }">
        <v-badge
          v-bind="props"
          offset-x="0"
          offset-y="0"
          color="green"
          :content="linkCount"
        >
          <v-btn v-bind="props" color="grey" size="x-small" variant="text" icon="mdi-link" />
        </v-badge>
      </template>
      <v-list>
        <v-list-item
          @click="() => openUrl(`/${langForeign}/wordmap/${props.word}`)"
          v-if="favWordMapLinksCount > 0"
        >
          <v-list-item-title class="py-2 pr-12">
            <v-badge offset-x="-15" offset-y="-5" color="green" :content="favWordMapLinksCount">
              {{ t('general.wordmap') }}</v-badge
            ></v-list-item-title
          >
        </v-list-item>
        <v-list-item
          @click="() => openUrl(`/${langForeign}/favorites/sentences/${props.word}`)"
          v-if="favSentencesLinkCount > 0"
        >
          <v-list-item-title class="py-2 pr-12">
            <v-badge offset-x="-15" offset-y="-5" color="green" :content="favSentencesLinkCount">
              {{ t('favorites.sentences') }}</v-badge
            ></v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<style scoped lang="scss">
#word-links {
  z-index: $z-level-11 !important;
}
</style>
