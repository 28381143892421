<script setup lang="ts">
import AnimationLoading from '@jaaluu/components/AnimationLoading.vue';
import CookieConsent from '@jaaluu/components/CookieConsent.vue';
import Snackbar from '@shared/components/Snackbar.vue';
import i18n from '@shared/i18n';
import { ConfigService, GoogleRssTopic, Language, VideoDef } from '@shared/openapi/jaaluu-api';
import { client } from '@shared/openapi/jaaluu-api/services.gen';
import sharedStores from '@shared/shared-stores';
import { computed, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AppBar from './components/AppBar.vue';
import Footer from './components/Footer.vue';
import Tooltips from './components/Tooltips.vue';
import TourGuide from './components/TourGuide.vue';
import stores from './stores';

const BASE_URL_API = (import.meta as any).env.VITE_JAALUU_API_URL;
const BASE_URL_APP = (import.meta as any).env.VITE_JAALUU_URL;

const getSubdomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');
  if (parts.length >= 2) {
    const lang = parts[0];
    if (['de', 'en', 'fr', 'es', 'pl', 'pt', 'it', 'ru'].includes(lang)) {
      return lang;
    }
  }
};
const langDomain = getSubdomain();
if (langDomain) {
  client.setConfig({
    baseURL: BASE_URL_API.replace('://', `://${langDomain}.`)
  });
} else {
  client.setConfig({
    baseURL: BASE_URL_API
  });
}

const { handleDefaultError } = stores.error.funcs;
const { getBrowserDefaultLanguage } = stores.language.funcs;
const { getSeenGuidesLocalStorage, tourRepeatWords, initClickOnWordAndSentence } =
  stores.tourGuide.funcs;
const { getThemeFromLocalSt, setNotifyMeFromLocalStorage } = stores.settings.funcs;
const { getIntroMsgSeenFromLs } = stores.introMessage.funcs;
const { setFavorites } = stores.favorites.funcs;
const { initProgressState } = stores.progress.funcs;
const { setUserData } = stores.user.funcs;
const { getCookieConsentFromLs } = stores.cookies.funcs;
const { initGoogleAnalytics } = stores.gAnalytics.funcs;
const { langNative, langForeign, supportedLangs, langCodeToVerbose, langCodes, langLevel } =
  stores.language.state;
const { ytInitDone } = stores.video.state;
const { inFullscreen } = stores.settings.state;
const { initDone } = stores.app.state;
const { count } = sharedStores.loading.state;
const { ytChannels, maxLoadVideos } = stores.video.state;
const { maxLoadFeeds } = stores.feed.state;
const { maxLoadKlexikon } = stores.klexikon.state;

const router = useRouter();
const route = useRoute();

const getConfig = () => {
  ConfigService.getConfigV1ConfigGet()
    .then((res) => {
      supportedLangs.value = res.data!.languages;
      langCodeToVerbose.value = res.data!.lang_code_to_verbose;
      langCodes.value = res.data!.language_codes as Record<Language, string>;
      ytChannels.value = res.data!.video_channels_def as Record<Language, VideoDef[]>;
      let langsStore: any = localStorage.getItem('languages');
      const pathLangForeign = route.params.langForeign;
      if (!langDomain) {
        if (langsStore) {
          langsStore = JSON.parse(langsStore);
          const baseAppUrlLangDomain =
          BASE_URL_APP.replace('://', `://${langsStore.native}.`) +
            `/${langsStore.foreign}`;
          window.location.href = baseAppUrlLangDomain;
        } else if (!langsStore) {
          const langDefault = getBrowserDefaultLanguage();
          const baseAppUrlLangDomain =
          BASE_URL_APP.replace('://', `://${langDefault}.`) + `/welcome`;
          window.location.href = baseAppUrlLangDomain;
        }
      } else if (route.path != '/welcome' && pathLangForeign) {
        langNative.value = langDomain.toString() as Language;
        langForeign.value = pathLangForeign.toString() as Language;
        i18n.global.locale = langNative.value as any;
        getLoadMax();
        initClickOnWordAndSentence();
      } else if (!pathLangForeign && route.path == '/welcome') {
        langNative.value = langDomain.toString() as Language;
        i18n.global.locale = langNative.value as any;
      } else if (!pathLangForeign && route.path != '/welcome') {
        const langDomain = getSubdomain();
        langNative.value = langDomain as Language;
        langNative.value = Language.EN;
        i18n.global.locale = langNative.value as any;
        setTimeout(() => {
          router.push('/welcome');
        }, 500);
      } else {
        console.error('Init detection failed');
      }
      let langLevelStore: any = localStorage.getItem('langLevel');
      if (langLevelStore) {
        langLevel.value = langLevelStore;
      }
      setTimeout(() => {
        initDone.value = true;
      }, 500);
    })
    .catch(handleDefaultError);
};

const initYoutubeApi = () => {
  var tag = document.createElement('script');
  tag.src = 'https://www.youtube.com/iframe_api';
  const firstScriptTag: any = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  setTimeout(() => {
    ytInitDone.value = true;
  }, 1000);
};

onMounted(() => {
  getThemeFromLocalSt();
  getConfig();
  initYoutubeApi();
  setFavorites();
  getSeenGuidesLocalStorage();
  tourRepeatWords();
  initProgressState();
  setUserData();
  initGoogleAnalytics();
  setNotifyMeFromLocalStorage();
  getIntroMsgSeenFromLs();
  getCookieConsentFromLs();
});


const isFullscreenPage = computed(() => {
  if (!route.name) return false;
  return ['welcome', 'intro', 'about'].includes(route.name as string);
});

const getLoadMax = () => {
  if (!langForeign.value) return;
  ConfigService.getLoadCountsV1ConfigLoadCountsGet({ query: { lang: langForeign.value } })
    .then((res) => {
      maxLoadVideos.value = res.data!.videos;
      maxLoadFeeds.value = res.data!.feeds as Record<GoogleRssTopic, number>;
      maxLoadKlexikon.value = res.data!.klexikon;
    })
    .catch(handleDefaultError);
};

watch(
  () => langForeign.value,
  () => getLoadMax()
);
</script>

<template>
  <v-app>
    <v-icon color="error" icon="mdi-emoticon-sad-outline" id="sad-face" />
    <v-progress-linear
      v-if="count > 0"
      indeterminate
      color="green"
      class="global-progress-linear"
    ></v-progress-linear>
    <Snackbar />
    <AppBar v-if="!isFullscreenPage" />
    <TourGuide />
    <CookieConsent />
    <AnimationLoading v-if="!initDone && !langDomain" />
    <div class="">
      <div
        class="flex-grow min-height"
        :class="{ 'pt-2 pa-sm-2 pa-md-4 pa-xl-8 max-width': !inFullscreen && !isFullscreenPage }"
      >
        <v-fade-transition>
          <v-main class="main-wrapper" v-show="initDone">
            <div fluid class="pa-xs-2 pa-lg-0">
              <router-view />
            </div>
          </v-main>
        </v-fade-transition>
      </div>
      <Footer :class="{ 'mt-12': !isFullscreenPage }" />
    </div>
    <Tooltips />
  </v-app>
</template>

<style scopes lang="scss">
#sad-face {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5rem;
  z-index: 9999;
  display: none;
}
.min-height {
  min-height: 100vh;
}
</style>
