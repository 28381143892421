<script setup lang="ts">
import { defineProps, onMounted } from 'vue';

import anime from 'animejs';
import { ref } from 'vue';

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  text: {
    type: String,
    required: false
  },
  videoSrc: {
    type: String,
    required: true
  },
  videoFirst: {
    type: Boolean,
    required: false,
    default: true
  },
  animate: {
    type: Boolean,
    required: false,
    default: false
  },
  big: {
    type: Boolean,
    required: false,
    default: false
  }
});

const ID = Math.random().toString(36).substr(2, 9);
const isVisible = ref(false);
let timeout: NodeJS.Timeout | null = null;

onMounted(() => {
  if (props.animate) {
    window.addEventListener('scroll', onScroll);
  }
});

const onScroll = () => {
  const rect = document.getElementById(ID)?.getBoundingClientRect();
  if (!rect || rect.top < 0) return;
  let visibleHeight =
    Math.max(0, Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0)) / rect.height;

  const duration = 750;
  if (visibleHeight !== null && visibleHeight < 0.3 && isVisible) {
    if (timeout) {
      clearTimeout(timeout);
    }
    const shiftPx = window.innerWidth / 2 + 300;
    timeout = setTimeout(() => {
      isVisible.value = false;
      anime({
        targets: `#text-${ID}`,
        translateX: props.videoFirst ? shiftPx : shiftPx * -1,
        easing: 'easeInOutQuad',
        duration
      });
      anime({
        targets: `#video-${ID}`,
        translateX: props.videoFirst ? shiftPx * -1 : shiftPx,
        easing: 'easeInOutQuad',
        duration
      });
    }, 100);
  } else if (!isVisible.value) {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      isVisible.value = true;
      anime({
        targets: `#text-${ID}`,
        translateX: 0,
        easing: 'easeInOutQuad',
        duration
      });
      anime({
        targets: `#video-${ID}`,
        translateX: 0,
        easing: 'easeInOutQuad',
        duration
      });
    }, 100);
  }
};
</script>
<template>
  <div
    :id="ID"
    class="d-flex flex-wrap align-center mt-16 welcome-content-width mx-auto"
    :class="{ 'flex-dir-reverse': !videoFirst }"
  >
    <div
      class="d-flex justify-center w-100 w-md-50 animate video mx-auto"
      :id="`video-${ID}`"
      :class="{ small: !big, big: big }"
    >
      <video
        playsinline
        autoplay
        muted
        loop
        :controls="big"
        class="video"
        :class="{ 'small': !big, 'big': big }"
      >
        <source :src="videoSrc" type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
    </div>
    <div class="w-100 w-md-50 animate text-center" :id="`text-${ID}`">
      <h2 class="title-welcome">{{ props.title }}</h2>
      <div class="text-welcome" v-if="props.text">
        {{ props.text }}
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.video {
  width: 400px;
  height: 400px;
  &.small {
    max-height: 45vw;
    max-width: 45vw;
  }
  &.big {
    max-height: 85vw;
    max-width: 85vw;
  }
  filter: grayscale(0);
}
.flex-dir-reverse {
  flex-direction: row-reverse;
}
</style>
