import flag_de from '@jaaluu/assets/images/de.jpg';
import flag_en from '@jaaluu/assets/images/en.jpg';
import flag_es from '@jaaluu/assets/images/es.jpg';
import flag_fr from '@jaaluu/assets/images/fr.jpg';
import flag_it from '@jaaluu/assets/images/it.jpg';
import flag_pl from '@jaaluu/assets/images/pl.jpg';
import flag_pt from '@jaaluu/assets/images/pt.jpg';
import flag_ru from '@jaaluu/assets/images/ru.jpg';
import flag_zu from '@jaaluu/assets/images/zh.jpg';
import { LangLevel } from '@jaaluu/interfaces/LangLevel';
import { t } from '@shared/i18n';
import { Language } from '@shared/openapi/jaaluu-api';
import { computed, ref, Ref } from 'vue';

const FLAGS = {
  de: flag_de,
  en: flag_en,
  fr: flag_fr,
  es: flag_es,
  ru: flag_ru,
  it: flag_it,
  pl: flag_pl,
  pt: flag_pt,
  zh: flag_zu
};

export default () => {
  const langForeign: Ref<Language | null> = ref(null);
  const langNative: Ref<Language | null> = ref(null);
  const supportedLangs: Ref<Language[]> = ref([]);
  const langCodeToVerbose: Ref<{ [key: string]: string }> = ref({});
  const langCodes: Ref<Record<Language, string> | null> = ref(null);
  const langLevel: Ref<LangLevel | null> = ref(null);

  const langOptions = computed(() => {
    return supportedLangs.value.map((l) => ({
      title: t(`languages.${l}`),
      value: l,
      flag: FLAGS[l]
    }));
  });

  const setSelectedLangsToLocalStore = (
    langForeign: Language | null,
    langNative: Language | null
  ) => {
    if (!langForeign || !langNative) return;
    localStorage.setItem(
      'languages',
      JSON.stringify({
        native: langNative,
        foreign: langForeign
      })
    );
  };

  const setLangLevelToLocalStore = (langLevel: LangLevel | null) => {
    if (!langLevel) return;
    localStorage.setItem('langLevel', langLevel);
  };

  const getBrowserDefaultLanguage = () => {
    let lang = navigator.language || navigator.userLanguage;
    if (!lang && navigator.languages && navigator.languages.length > 0) {
      lang = navigator.languages[0];
    }
    if (lang) {
      console.debug('Found browser default lang: ', lang);
    } else {
      console.debug('No default language found');
    }
    return lang.slice(0, 2);
  };

  return {
    state: {
      langForeign,
      langNative,
      supportedLangs,
      langCodeToVerbose,
      langOptions,
      langCodes,
      langLevel
    },
    funcs: { getBrowserDefaultLanguage, setSelectedLangsToLocalStore, setLangLevelToLocalStore },
    consts: { FLAGS }
  };
};
