<script setup lang="ts">
import { defineProps } from 'vue';

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  text: {
    type: String,
    required: true
  },
  icon: {
    type: String,
    required: true
  },
  id: {
    type: String,
    required: true
  }
});
</script>
<template>
  <div class="d-block d-sm-flex align-center max-w-details mx-auto mt-16 mb-16" :id="id">
    <v-icon size="70" :icon="props.icon" class="mr-4" />
    <div>
      <h2 class="text-welcome">{{ props.title }}</h2>
      <div>
        {{ props.text }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.max-w-details {
  max-width: 800px;
}

</style>
