<script lang="ts" setup>
import stores from '@jaaluu/stores';
import { t } from '@shared/i18n';
import { GoogleRssTopic } from '@shared/openapi/jaaluu-api';
import sharedStores from '@shared/shared-stores';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const PROGRESS_HIGHT = 2;

const { TRANS } = stores.translate.consts;
const router = useRouter();
const { CAT_TYPE_ICON_NAMES } = stores.category.consts;
const { favWordsLang, favUrlsLang, favSentencesLang, favWordmapsLang } = stores.favorites.state;
const { langSettingsOpen, audioVideoSettingsIsOpen, themeSettingsIsOpen } = stores.settings.state;
const { cookieDialogIsOpen } = stores.cookies.state;
const { langForeign } = stores.language.state;
const { ytChannels } = stores.video.state;
const { animate } = sharedStores.animate.funcs;
const { initMenu } = stores.tourGuide.funcs;

const menuOpen = ref(false);

const toggleMenu = () => {
  menuOpen.value = !menuOpen.value;
  if (menuOpen.value) {
    initMenu();
  }
};

const openUrl = (url: string) => {
  router.push(url);
  closeMenu();
};

const openLangSettings = () => {
  langSettingsOpen.value = true;
  closeMenu();
};

const openAudioVideoSettings = () => {
  audioVideoSettingsIsOpen.value = true;
  closeMenu();
};
const openThemeSettings = () => {
  themeSettingsIsOpen.value = true;
  closeMenu();
};
const openCookieSettings = () => {
  cookieDialogIsOpen.value = true;
  closeMenu();
};

const closeMenu = () => {
  menuOpen.value = false;
};

watch(
  () => favWordsLang.value.length,
  () => {
    animate('#fav-badge');
  }
);
watch(
  () => favUrlsLang.value.length,
  () => {
    animate('#fav-badge');
  }
);
watch(
  () => favWordmapsLang.value.length,
  () => {
    animate('#fav-badge');
  }
);
watch(
  () => favSentencesLang.value.length,
  () => {
    animate('#fav-badge');
  }
);
</script>
<template>
  <div class="d-flex ml-2 align-center" v-if="ytChannels">
    <div
      :class="{
        opac:
          favWordsLang.length === 0 &&
          favUrlsLang.length === 0 &&
          favSentencesLang.length === 0 &&
          favWordmapsLang.length === 0
      }"
    >
      <v-menu density="compact">
        <template v-slot:activator="{ props }">
          <v-badge
            id="fav-badge"
            offset-x="3"
            offset-y="3"
            color="green"
            v-bind="props"
            :content="
              favWordsLang.length +
              favUrlsLang.length +
              favSentencesLang.length +
              favWordmapsLang.length
            "
          >
            <v-btn color="grey" :size="35" icon> <v-icon size="22" icon="mdi-star" /></v-btn>
          </v-badge>
        </template>
        <v-list>
          <v-list-item @click="() => openUrl(`/${langForeign}/favorites/words`)" v-if="favWordsLang.length > 0">
            <v-list-item-title class="py-2 pr-12">
              <v-badge offset-x="-15" offset-y="-5" color="green" :content="favWordsLang.length">
                {{ t('general.words') }}</v-badge
              ></v-list-item-title
            >
          </v-list-item>
          <v-list-item
            @click="() => openUrl(`/${langForeign}/favorites/wordmaps`)"
            v-if="favWordmapsLang.length > 0"
          >
            <v-list-item-title class="py-2 pr-12">
              <v-badge offset-x="-15" offset-y="-5" color="green" :content="favWordmapsLang.length">
                {{ t('favorites.wordmaps') }}</v-badge
              ></v-list-item-title
            >
          </v-list-item>
          <v-list-item
            @click="() => openUrl(`/${langForeign}/favorites/sentences`)"
            v-if="favSentencesLang.length > 0"
          >
            <v-list-item-title class="py-2 pr-12">
              <v-badge
                offset-x="-15"
                offset-y="-5"
                color="green"
                :content="favSentencesLang.length"
              >
                {{ t('favorites.sentences') }}</v-badge
              ></v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="() => openUrl(`/${langForeign}/favorites/urls`)" v-if="favUrlsLang.length > 0">
            <v-list-item-title class="py-2 pr-12">
              <v-badge offset-x="-15" offset-y="-5" color="green" :content="favUrlsLang.length">
                {{ t('favorites.texts_videos') }}</v-badge
              ></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-btn icon="mdi-menu" @click="() => toggleMenu()"></v-btn>
    <div class="menu" :class="{ active: menuOpen }">
      <v-list color="green" density="compact">
        <div class="d-flex justify-space-between align-center mb-4">
          <div class="ml-4 subtitle">{{ t('app_bar.menu') }}</div>
          <v-btn size="small" variant="text" icon="mdi-close" @click="() => (menuOpen = false)" />
        </div>

        <v-list-item
          v-if="langForeign"
          :prepend-icon="CAT_TYPE_ICON_NAMES.write"
          :title="TRANS[langForeign].general.write"
          :value="TRANS[langForeign].general.write"
          @click="() => openUrl(`/${langForeign}/category/write`)"
          ><v-progress-linear
            :model-value="100"
            :height="PROGRESS_HIGHT"
            color="grey"
            class="difficult-progress"
            size="x-small"
        /></v-list-item>

        <v-list-group :value="'videos-news'" v-if="langForeign">
          <template v-slot:activator="{ props }">
            <v-list-item
              prepend-icon="mdi-youtube"
              v-bind="props"
              :title="TRANS[langForeign].general.videos + ' - ' + TRANS[langForeign].general.feeds"
              ><v-progress-linear
                :model-value="100"
                :height="PROGRESS_HIGHT"
                color="grey"
                vertical
                class="difficult-progress"
                size="x-small"
            /></v-list-item>
          </template>
          <v-list-item
            v-if="ytChannels"
            v-for="(channel, i) in ytChannels[langForeign].slice(0, 2)"
            :key="i"
            :title="channel.title"
            :value="channel.title"
            @click="() => openUrl(`/${langForeign}/videos/${channel.channel_id}`)"
          ></v-list-item>
        </v-list-group>

        <v-list-item
          v-if="langForeign"
          prepend-icon="mdi-book-open-variant-outline"
          :title="TRANS[langForeign].general.books"
          :value="TRANS[langForeign].general.books"
          @click="() => openUrl(`/${langForeign}/books`)"
          ><v-progress-linear
            :model-value="90"
            :height="PROGRESS_HIGHT"
            color="grey"
            class="difficult-progress"
            size="x-small"
        /></v-list-item>

        <v-list-item
          v-if="langForeign"
          :prepend-icon="CAT_TYPE_ICON_NAMES.conversations"
          :title="TRANS[langForeign].general.conversations"
          :value="TRANS[langForeign].general.conversations"
          @click="() => openUrl(`/${langForeign}/category/conversations`)"
          ><v-progress-linear
            :model-value="90"
            color="grey"
            :height="PROGRESS_HIGHT"
            class="difficult-progress"
            size="x-small"
        /></v-list-item>

        <v-list-item
          prepend-icon="mdi-wikipedia"
          :title="'Wikipedia'"
          :value="'Wikipedia'"
          @click="() => openUrl(`/${langForeign}/wikipedia`)"
          ><v-progress-linear
            :model-value="90"
            color="grey"
            :height="PROGRESS_HIGHT"
            class="difficult-progress color-wikipedia"
            size="x-small"
        /></v-list-item>

        <v-list-group :value="t('general.feeds')" v-if="langForeign">
          <template v-slot:activator="{ props }">
            <v-list-item
              prepend-icon="mdi-newspaper-variant-outline"
              v-bind="props"
              :title="TRANS[langForeign].general.feeds"
            >
              <v-progress-linear
                :model-value="70"
                color="grey"
                :height="PROGRESS_HIGHT"
                class="difficult-progress"
                size="x-small"
            /></v-list-item>
          </template>
          <v-list-item
            v-for="(topicKey, i) in GoogleRssTopic"
            :key="i"
            :title="TRANS[langForeign].feed_topics[topicKey]"
            :value="TRANS[langForeign].feed_topics[topicKey]"
            @click="() => openUrl(`/${langForeign}/feeds/${topicKey}`)"
          >
          </v-list-item>
        </v-list-group>

        <!-- <v-list-item
    v-if="langForeign"
    prepend-icon="mdi-message"
    :title="TRANS[langForeign].chat.chat"
    :value="TRANS[langForeign].chat.chat"
    @click="() => openUrl(`/${langForeign.value}/chat`)"
    ><v-progress-linear
      :model-value="50"
      color="green"
      :height="PROGRESS_HIGHT"
      class="difficult-progress"
      size="x-small"
  /></v-list-item> -->

        <v-list-group :value="'videos-kids'" v-if="langForeign">
          <template v-slot:activator="{ props }">
            <v-list-item
              prepend-icon="mdi-youtube"
              v-bind="props"
              :title="TRANS[langForeign].general.videos + ' - ' + TRANS[langForeign].general.kids"
              ><v-progress-linear
                :model-value="60"
                color="grey"
                :height="PROGRESS_HIGHT"
                class="difficult-progress"
                size="x-small"
            /></v-list-item>
          </template>
          <v-list-item
            v-if="ytChannels"
            v-for="(channel, i) in ytChannels[langForeign].slice(2, 4)"
            :key="i"
            :title="channel.title"
            :value="channel.title"
            @click="() => openUrl(`/${langForeign}/videos/${channel.channel_id}`)"
          ></v-list-item>
        </v-list-group>

        <v-list-item
          v-if="langForeign"
          prepend-icon="mdi-teddy-bear"
          :title="TRANS[langForeign].general.kids_lexicon"
          :value="TRANS[langForeign].general.kids_lexicon"
          @click="() => openUrl(`/${langForeign}/kids_lexicon`)"
          ><v-progress-linear
            :model-value="50"
            color="grey"
            :height="PROGRESS_HIGHT"
            class="difficult-progress"
            size="x-small"
        /></v-list-item>

        <v-list-item
          v-if="langForeign"
          :prepend-icon="CAT_TYPE_ICON_NAMES.simple_texts"
          :title="TRANS[langForeign].category.simple_texts"
          :value="TRANS[langForeign].category.simple_texts"
          @click="() => openUrl(`/${langForeign}/category/simple_texts`)"
          ><v-progress-linear
            :model-value="40"
            color="grey"
            :height="PROGRESS_HIGHT"
            class="difficult-progress"
            size="x-small"
        /></v-list-item>

        <v-list-item
          v-if="langForeign"
          :prepend-icon="CAT_TYPE_ICON_NAMES.sayings"
          :title="TRANS[langForeign].category.sayings"
          :value="TRANS[langForeign].category.sayings"
          @click="() => openUrl(`/${langForeign}/category/sayings`)"
          ><v-progress-linear
            :model-value="30"
            color="grey"
            :height="PROGRESS_HIGHT"
            class="difficult-progress"
            size="x-small"
        /></v-list-item>

        <v-list-item
          v-if="langForeign"
          :prepend-icon="CAT_TYPE_ICON_NAMES.phrases"
          :title="TRANS[langForeign].category.phrases"
          :value="TRANS[langForeign].category.phrases"
          @click="() => openUrl(`/${langForeign}/category/phrases`)"
          ><v-progress-linear
            :model-value="10"
            color="grey"
            :height="PROGRESS_HIGHT"
            class="difficult-progress"
            size="x-small"
        /></v-list-item>

        <v-divider class="mt-4"></v-divider>
        <v-list-item
          v-if="langForeign"
          prepend-icon="mdi-receipt-text-edit-outline"
          :title="TRANS[langForeign].special_vocabulary.special_vocabulary"
          :value="TRANS[langForeign].special_vocabulary.special_vocabulary"
          @click="() => openUrl(`/${langForeign}/add-auto-text`)"
        ></v-list-item>
        <v-list-item
          v-if="langForeign"
          prepend-icon="mdi-content-copy"
          :title="TRANS[langForeign].custom.own_text"
          :value="TRANS[langForeign].custom.own_text"
          @click="() => openUrl(`/${langForeign}/custom`)"
        ></v-list-item>
        <v-divider class="mt-2" />
        <v-list-group :value="t('general.settings')" v-if="langForeign">
          <template v-slot:activator="{ props }">
            <v-list-item
              prepend-icon="mdi-cog"
              v-bind="props"
              :title="t('general.settings')"
            ></v-list-item>
          </template>

          <v-list-item
            prepend-icon="mdi-translate-variant"
            @click="() => openLangSettings()"
            class="list-item"
          >
            <v-list-item-title>{{ t('general.language') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            prepend-icon="mdi-headphones"
            @click="() => openAudioVideoSettings()"
            class="list-item"
          >
            <v-list-item-title>{{ t('settings.audio_video') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            prepend-icon="mdi-invert-colors"
            @click="() => openThemeSettings()"
            class="list-item"
          >
            <v-list-item-title>{{ t('settings.colors') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            prepend-icon="mdi-cookie"
            @click="() => openCookieSettings()"
            class="list-item"
          >
            <v-list-item-title>Cookies</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <div class="d-flex justify-end subtitle-sm mt-12 mr-3">
          <div>
            <v-progress-linear
              :model-value="20"
              color="grey"
              :height="PROGRESS_HIGHT"
              class="difficult-progress"
              size="x-small"
            />{{ t('general.easy') }}
          </div>
          <div class="ml-4 mr-1">
            <v-progress-linear
              :model-value="100"
              color="grey"
              :height="PROGRESS_HIGHT"
              class="difficult-progress"
              size="x-small"
            />
            {{ t('general.hard') }}
          </div>
        </div>
      </v-list>
    </div>
    <v-overlay class="overlay" v-model="menuOpen"></v-overlay>
  </div>
</template>

<style scoped lang="scss">
.overlay {
  z-index: $z-level-1 !important;
}
.menu {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: $z-level-10 !important;
  background-color: rgb(var(--v-theme-background));
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  padding-right: 2px;
  padding-bottom: 80px;
  width: 0px;
  overflow: hidden;
  &.active {
    width: 300px;
    max-width: 90vw;
    overflow: auto;
  }
  transition: width 0.3s;
}
.difficult-progress {
  width: 15px;
}
</style>
