import {
  CommunicationBasics,
  EntertainmentAndLeisure,
  FeelingsAndEmotions,
  FoodAndDining,
  HealthAndWellBeing,
  MainCategories,
  NubmersAndQuantity,
  SocialContexts,
  TimeAndDates,
  TravelAndDirections
} from '@shared/openapi/jaaluu-api';

export const CATEGORIES_PL = {
  [MainCategories.COMMUNICATION_BASICS]: {
    title: 'Komunikacja',
    categories: {
      [CommunicationBasics.GREETINGS]: 'Powitania',
      [CommunicationBasics.QUESTIONS]: 'Pytania',
      [CommunicationBasics.EXPRESSIONS]: 'Wyrażenia'
    }
  },
  [MainCategories.FEELINGS_AND_EMOTIONS]: {
    title: 'Uczucia i emocje',
    categories: {
      [FeelingsAndEmotions.POSITIVE_EMOTIONS]: 'Pozytywne emocje',
      [FeelingsAndEmotions.NEGATIVE_EMOTIONS]: 'Negatywne emocje',
      [FeelingsAndEmotions.NEUTRAL_OR_MIXED_FEELINGS]: 'Neutralne lub mieszane uczucia'
    }
  },
  [MainCategories.TRAVEL_AND_DIRECTIONS]: {
    title: 'Podróże i kierunki',
    categories: {
      [TravelAndDirections.DIRECTIONS]: 'Kierunki',
      [TravelAndDirections.LOCATIONS]: 'Lokalizacje',
      [TravelAndDirections.TRANSPORTATION]: 'Transport',
      [TravelAndDirections.ACCOMODATION]: 'Zakwaterowanie'
    }
  },
  [MainCategories.NUMBERS_AND_QUANTITY]: {
    title: 'Liczby i ilość',
    categories: {
      [NubmersAndQuantity.NUMBERS]: 'Liczby',
      [NubmersAndQuantity.QUANTITY]: 'Ilość'
    }
  },
  [MainCategories.TIME_AND_DATES]: {
    title: 'Czas i daty',
    categories: {
      [TimeAndDates.TIME]: 'Czas',
      [TimeAndDates.DATES]: 'Daty'
    }
  },
  [MainCategories.ENTERTAINMENT_AND_LEISURE]: {
    title: 'Rozrywka i wypoczynek',
    categories: {
      [EntertainmentAndLeisure.ENTERTAINMENT]: 'Rozrywka',
      [EntertainmentAndLeisure.LEISURE]: 'Wypoczynek'
    }
  },
  [MainCategories.HEALTH_AND_WELL_BEING]: {
    title: 'Zdrowie i dobre samopoczucie',
    categories: {
      [HealthAndWellBeing.HEALTH]: 'Zdrowie',
      [HealthAndWellBeing.WELL_BEING]: 'Dobre samopoczucie'
    }
  },
  [MainCategories.SOCIAL_CONTEXTS]: {
    title: 'Konteksty społeczne',
    categories: {
      [SocialContexts.SOCIAL]: 'Społeczne',
      [SocialContexts.CULTURAL]: 'Kulturowe',
      [SocialContexts.RELIGIOUS]: 'Religijne',
      [SocialContexts.POLITICAL]: 'Polityczne'
    }
  },
  [MainCategories.FOOD_AND_DINING]: {
    title: 'Jedzenie i posiłki',
    categories: {
      [FoodAndDining.FOOD]: 'Jedzenie',
      [FoodAndDining.DINING]: 'Posiłki'
    }
  }
};
