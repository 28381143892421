// vuetify
import '@mdi/font/css/materialdesignicons.css';
import { createVuetify, type ThemeDefinition } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { VTreeview } from 'vuetify/labs/VTreeview';
import 'vuetify/styles';

const lightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    'background-darken-1': '#FFFFFF',
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#4CAF50', // green
    'primary-darken-1': '#449147',
    'primary-lighten-1': '#5bba5e',
    secondary: '#F6F6F7', // gray light
    'secondary-darken-1': '#f1f1f1',
    'secondary-darken-2': '#e1e1e1',
    'secondary-lighten-1': '#f9f9f9',
    error: '#e11717',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    'on-background': '#4c4c4c',
    'on-surface': '#4c4c4c',
    'on-primary': '#F6F6F7',
    'on-secondary': '#8d8d8f',
    'on-success': '#F6F6F7',
    'on-warning': '#F6F6F7',
    'on-error': '#F6F6F7',
    'on-info': '#F6F6F7'
  },
  variables: {
    'secondary-text': '#b1b1b1',
    'on-primary-gray': '#c6c6c7'
  }
};

const darkTheme: ThemeDefinition = {
  dark: true,
  colors: {
    'background-darken-1': '#000000',
    background: '#242424',
    surface: '#242424',
    primary: '#4CAF50', // green
    'primary-darken-1': '#449147',
    'primary-lighten-1': '#66bb69',
    secondary: '#2e2e2e', // gray dark
    'secondary-darken-1': '#2b2b2b',
    'secondary-darken-2': '#212121',
    'secondary-lighten-1': '#9c9a9a',
    error: '#e11717',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    'on-background': '#ffffff',
    'on-surface': '#ffffff',
    'on-primary': '#F6F6F7',
    'on-secondary': '#F6F6F7',
    'on-success': '#F6F6F7',
    'on-warning': '#F6F6F7',
    'on-error': '#F6F6F7',
    'on-info': '#F6F6F7'
  },
  variables: {
    'secondary-text': '#959595',
    'on-primary-gray': '#c6c6c7'
  }
};

export default createVuetify({
  components: {
    ...components,
    VTreeview
  },
  directives: {
    ...directives
  },
  icons: {
    defaultSet: 'mdi'
  },
  theme: {
    defaultTheme: 'lightTheme',
    themes: {
      darkTheme,
      lightTheme
    }
  }
});
