import { ref } from 'vue';

export default () => {
  const count = ref(0);

  const increment = () => {
    count.value++;
  };

  const decrement = () => {
    count.value--;
    if (count.value < 0) {
      count.value = 0;
    }
  };

  return { state: { count }, funcs: { increment, decrement } };
};
