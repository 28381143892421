import jaaluu_de from '@shared/i18n/jaaluu/de';
import jaaluu_en from '@shared/i18n/jaaluu/en';
import jaaluu_es from '@shared/i18n/jaaluu/es';
import jaaluu_fr from '@shared/i18n/jaaluu/fr';
import jaaluu_it from '@shared/i18n/jaaluu/it';
import jaaluu_pl from '@shared/i18n/jaaluu/pl';
import jaaluu_pt from '@shared/i18n/jaaluu/pt';
import jaaluu_ru from '@shared/i18n/jaaluu/ru';

import { createI18n } from 'vue-i18n';
const APP_NAME = (import.meta as any).env.VITE_APP_NAME;

const all_messages: any = {
  jaaluu: {
    en: jaaluu_en,
    de: jaaluu_de,
    es: jaaluu_es,
    fr: jaaluu_fr,
    it: jaaluu_it,
    pt: jaaluu_pt,
    pl: jaaluu_pl,
    ru: jaaluu_ru
  }
};
const messages = {
  en: { ...all_messages[APP_NAME].en },
  de: { ...all_messages[APP_NAME].de },
  es: { ...all_messages[APP_NAME].es },
  fr: { ...all_messages[APP_NAME].fr },
  it: { ...all_messages[APP_NAME].it },
  pt: { ...all_messages[APP_NAME].pt },
  pl: { ...all_messages[APP_NAME].pl },
  ru: { ...all_messages[APP_NAME].ru }
};

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
  allowComposition: true
});
// @ts-ignore
const { t, tc } = i18n.global;
export default i18n;
export { t, tc };
