<script lang="ts" setup>
import AudioPlayer from '@jaaluu/components/AudioPlayer.vue';
import TextWithTooltips from '@jaaluu/components/TextWithTooltips.vue';

const emit = defineEmits(['audio-started']);
const props = defineProps({
  text: {
    type: String,
    required: true
  },
  textClass: {
    type: String,
    default: ''
  },
  showListenText: {
    required: false,
    type: Boolean,
    default: false
  },
  isSticky: {
    required: false,
    type: Boolean,
    default: false
  },
  playIconOnTop: {
    required: false,
    type: Boolean,
    default: false
  }
});
</script>

<template>
  <div class="d-flex align-center" :class="{ 'flex-column-reverse': playIconOnTop }">
    <div :class="textClass">
      <TextWithTooltips :text="text" />
      <!-- <ChinesePinyin :text="text" :lang="langForeign" /> -->
    </div>
    <AudioPlayer
      :class="{ 'mb-3': playIconOnTop }"
      :sentences-list="[props.text]"
      :show-listen-text="props.showListenText"
      :is-sticky="props.isSticky"
      @audio-started="() => emit('audio-started')"
    />
  </div>
</template>
<style lang="scss" scoped></style>
