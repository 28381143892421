import { ref } from 'vue';
import vuetify from '@shared/vuetify';

const NOTIFY_ME_SRS_STORAGE_KEY = 'notifyMeSrs';

export default () => {
  const langSettingsOpen = ref(false);
  const audioVideoSettingsIsOpen = ref(false);
  const themeSettingsIsOpen = ref(false);
  const playbackRate = ref(1);
  const inDarkTheme = ref(false);
  const inFullscreen = ref(false);
  const notifyMeSrs = ref(true);
  const theme = vuetify.theme.global.current;

  const setThemeInLocalSt = () => {
    localStorage.setItem('theme', theme.value.dark ? 'dark' : 'light');
  }
  const getThemeFromLocalSt = () => {
    inDarkTheme.value = localStorage.getItem('theme') === 'dark';
    if (inDarkTheme.value) {
      vuetify.theme.global.name.value = 'darkTheme'
    }
  }

  const setTheme = () => {
    vuetify.theme.global.name.value = theme.value.dark ? 'lightTheme' : 'darkTheme';
  }

  const toggleTheme = () => {
    setTheme();
    setThemeInLocalSt();
  };

  const saveNotifyMeSrsInLocalStorage = (value: boolean) => {
    localStorage.setItem(NOTIFY_ME_SRS_STORAGE_KEY, JSON.stringify(value));
  }

  const setNotifyMeFromLocalStorage = () => {
    notifyMeSrs.value = JSON.parse(localStorage.getItem(NOTIFY_ME_SRS_STORAGE_KEY) || 'true');
  }

  return {
    state: {
      langSettingsOpen,
      playbackRate,
      audioVideoSettingsIsOpen,
      themeSettingsIsOpen,
      theme,
      inDarkTheme,
      inFullscreen,
      notifyMeSrs
    },
    funcs: {
      toggleTheme,
      getThemeFromLocalSt,
      saveNotifyMeSrsInLocalStorage,
      setNotifyMeFromLocalStorage
    }
  };
};
