import sharedStores from '@shared/shared-stores';
import { EmailService } from '@shared/openapi/jaaluu-api';


export default () => {
  const {notify} = sharedStores.snackbar.funcs

  const handleDefaultError = async (error: any) => {
    console.error('Error:', error);
    notify({
      text: 'Sorry, something went wrong',
      type: 'error',
      icon: 'mdi-alert-circle',
    })

    EmailService.sendEmailV1EmailSendPost({
      body: {
        app_name: 'Jaaluu',
        tier: 'frontend',
        subject: 'Error in Jaaluu',
        message: {error: JSON.stringify(error)},
      }
    }).catch((error) => {
      console.error('Error sending email:', error);
    });

  }
  

  return { state: { }, funcs: { handleDefaultError } };
};
