// https://imgonline.tools/invert
// https://www.manypixels.co/gallery

import achievement from '@jaaluu/assets/images/achievement.svg';
import logoBallBlack from '@jaaluu/assets/images/logo_ball.png';
import logoBallTransBlack from '@jaaluu/assets/images/logo_ball_trans.png';
import logoBallTransWhite from '@jaaluu/assets/images/logo_ball_trans_white.png';
import logoBallWhite from '@jaaluu/assets/images/logo_ball_white.png';
import logoWideTransBlack from '@jaaluu/assets/images/logo_wide_trans.png';
import logoWideTransWhite from '@jaaluu/assets/images/logo_wide_trans_white.png';
import logoWideBlackSmall from '@jaaluu/assets/images/logo_wide_trans_small.png';
import notFound404 from '@jaaluu/assets/images/not_found_404.svg';
import nothingFound from '@jaaluu/assets/images/nothing_found.svg';
import rocketLaunch from '@jaaluu/assets/images/rocket_launch.svg';
import target from '@jaaluu/assets/images/target.svg';
import { computed, Ref } from 'vue';
import { settings } from '.';

export default () => {
  const { inDarkTheme } = settings.state;
  const logoBall: Ref<any> = computed(() => (inDarkTheme.value ? logoBallWhite : logoBallBlack));
  const logoWide = computed(() => (inDarkTheme.value ? logoWideTransWhite : logoWideTransBlack));
  const logoBallTrans = computed(() =>
    inDarkTheme.value ? logoBallTransWhite : logoBallTransBlack
  );
  return { state: { logoBall, logoWide, logoBallTrans, logoWideBlackSmall, nothingFound, notFound404, achievement, rocketLaunch, target } };
};
