<script setup lang="ts">
import { LangLevel } from '@jaaluu/interfaces/LangLevel';
import stores from '@jaaluu/stores';
import { t } from '@shared/i18n';
import { watch } from 'vue';
import Dialog from '@jaaluu/components/Dialog.vue';
import SelectLanguages from './SelectLanguages.vue';

const { langSettingsOpen } = stores.settings.state;
const { langLevel } = stores.language.state;
const { setLangLevelToLocalStore } = stores.language.funcs;

watch(
  () => langLevel.value,
  () => {
    setLangLevelToLocalStore(langLevel.value);
  }
);
</script>

<template>
  <Dialog v-model="langSettingsOpen" :max-width="400" @close="() => (langSettingsOpen = false)" :title="t('general.settings')" >
    <SelectLanguages />

    <!-- <div class="mt-12">
      <div class="title-sm">{{ t('settings.lang_level') }}</div>
      <v-radio-group v-model="langLevel" row color="primary">
        <v-radio :label="t('general.beginner')" :value="LangLevel.BEGINNER" />
        <v-radio :label="t('general.advanced')" :value="LangLevel.ADVANCED" />
      </v-radio-group>
    </div> -->
  </Dialog>
</template>
<style lang="scss" scoped></style>
