<script setup lang="ts">
import Tooltip from '@jaaluu/components/Tooltip.vue';
import stores from '@jaaluu/stores';
import vuetify from '@shared/vuetify';


const { removeTooltip } = stores.tooltip.funcs;

const { tooltips } = stores.tooltip.state;



</script>

<template>
    <v-dialog
      v-for="(tooltip, idx) in tooltips"
      v-model="tooltip.open"
      :min-width="vuetify.display.smAndUp.value ? 500: 300"
      max-width="95vw"
      class="tooltip-wrapper"
      @close="() => removeTooltip(idx)"
    >
      <Tooltip :tooltip="tooltip" />
    </v-dialog>
</template>

<style></style>
