export default {
  welcome: {
    your_language: 'Votre langue',
    language_to_learn: 'Langue que vous souhaitez apprendre',
    start_learning: 'Commencer à apprendre',
    how_does_this_work: 'Comment ça marche ?',
    welcome_1: 'Bienvenue sur JAALUU',
    welcome_2: 'Ici, vous pouvez apprendre des langues',
    welcome_3: 'Lisez des textes ou regardez des vidéos avec des sous-titres',
    welcome_4: 'Cliquez sur un mot pour des traductions, exemples et définitions',
    main_teaser: 'Améliorez vos compétences linguistiques - facilement et gratuitement',
    select_language_and_start: 'Sélectionnez la langue et commencez',
    how_to_title: 'Lisez des textes, des livres, des nouvelles ou regardez des vidéos',
    how_to_text: 'Vous pouvez choisir parmi une variété de textes, livres, nouvelles ou vidéos',
    click_on_word_title: 'Traduction automatique, exemples et définitions',
    click_on_word_text:
      "Cliquez sur n'importe quel mot pour obtenir une traduction automatique, des phrases d'exemple et des définitions. Vous pouvez également traduire des phrases entières automatiquement ou lier des mots avec des images et créer des réseaux de mots.",
    listen_title: 'Écoutez des mots et des textes',
    listen_text:
      "Vous pouvez écouter n'importe quel mot, phrase ou texte entier et pratiquer la prononciation",
    conversations_title: 'Pratiquez des conversations',
    conversations_text:
      'Vous pouvez pratiquer des conversations en parlant à un chatbot virtuel. Vous devez résoudre des tâches comme : `Commander de la nourriture dans un restaurant` ou `Demander des directions.`',
    writing_title: 'Orthographe',
    writing_text_1: "Pratiquez l'orthographe en écrivant des textes",
    writing_text_2:
      "Vos textes seront vérifiés pour les fautes d'orthographe, le contenu et la grammaire.",
    texts_and_videos_title: 'Trouvez votre niveau',
    texts_and_videos_text:
      'Vous pouvez choisir entre des phrases simples et des textes pour enfants, des nouvelles, des articles Wikipedia ou des livres, selon ce qui vous convient le mieux',
    srs_title: 'Répétez et entraînez le vocabulaire',
    srs_text_1:
      "Vous pouvez enregistrer n'importe quel mot ou phrase entière comme favoris pour répéter plus tard",
    srs_text_2:
      'Entraînez votre vocabulaire avec des flashcards et des exercices simples, et suivez vos progrès',
    srs_text_3:
      'Le système de répétition SRS vous aide à apprendre plus efficacement en répétant les mots au bon moment',
    own_text_title: 'Vocabulaire spécialisé',
    own_text_text:
      'Vous pouvez créer un texte sur un sujet spécifique pour apprendre du vocabulaire spécialisé. Vous pouvez également créer des textes contenant les mots que vous souhaitez apprendre.',
    wordmap_title: 'Réseaux de mots',
    wordmap_text_1:
      'Vous pouvez créer des réseaux de mots et lier des mots avec des phrases simples',
    wordmap_text_2:
      'Les cartes de mots peuvent être enregistrées comme favoris, ce qui permet une répétition et un entraînement faciles',
    sayings_title: 'Idiomatiques',
    sayings_text:
      'Ici, vous trouverez des phrases et des idiomes couramment utilisés, classés. Vous pouvez suivre vos progrès et continuer à vous développer de manière structurée.',
    details: 'Détails',
    details_text_and_videos_title:
      'Découvrez Jaaluu – Votre clé pour un apprentissage rapide et efficace des langues!',
    details_text_and_videos_text:
      "Jaaluu vous offre une richesse de textes et de vidéos pour améliorer votre niveau de langue. La plateforme est idéale si vous pouvez déjà comprendre des mots et des phrases simples. Pour les débutants, il y a des phrases simples et des idiomes, des textes pour enfants, des lexiques et des vidéos pour un démarrage facile. Pour les apprenants avancés, il y a des actualités, des articles Wikipedia, des livres et des vidéos passionnantes pour une compréhension plus approfondie, ou des conversations interactives avec un chatbot. De plus, vous pouvez entraîner votre orthographe et obtenir des conseils pour améliorer les fautes d'orthographe, le contenu et la grammaire.",
    details_click_on_words_title:
      'Apprendre le vocabulaire facilité : Rapide, interactif et durable!',
    details_click_on_words_text:
      "Apprendre le vocabulaire peut être fastidieux car rechercher des mots et des définitions prend beaucoup de temps. Jaaluu résout ce problème : il suffit de cliquer sur un mot pour obtenir instantanément des traductions, des phrases d'exemple, des définitions et des explications. Cela vous fait gagner du temps et vous aide à apprendre plus rapidement et plus efficacement!",
    details_listen_title:
      'Écoutez et pratiquez : Entendez des mots, des phrases et des textes pour améliorer vos compétences linguistiques!',
    details_listen_text:
      "Vous pouvez tout écouter – n'importe quel mot, n'importe quelle phrase, ou même des textes entiers. Il suffit de jouer et d'écouter! Cette fonctionnalité vous aide à pratiquer la prononciation correcte et à améliorer la compréhension orale. Vous pouvez également écouter des conversations et développer un ressenti pour une communication naturelle. Parfait pour intégrer du vocabulaire et renforcer vos compétences linguistiques dans des situations quotidiennes.",
    details_conversations_title:
      'Formation linguistique pratique : Conversations réalistes pour la vie quotidienne!',
    details_conversations_text:
      "Avec Jaaluu, vous pouvez pratiquer des conversations réalistes avec un chatbot et vous préparer aux situations quotidiennes typiques. Par exemple, vous pouvez commander de la nourriture dans un restaurant et payer l'addition ou demander des directions. Ou, vous pouvez discuter avec votre nouveau collègue le premier jour dans un nouveau travail. Vos phrases seront automatiquement analysées pour vous donner un retour direct et améliorer vos compétences linguistiques. Ainsi, vous vous entraînez de manière pratique et efficace pour de vraies conversations!",
    details_writing_title:
      "Pratique d'écriture pour la vie quotidienne : Améliorez vos compétences avec des tâches réalistes!",
    details_writing_text:
      "Avec Jaaluu, vous pouvez pratiquer l'écriture en maîtrisant de petites tâches réalistes, comme rédiger des candidatures ou des lettres de plainte. Votre texte sera ensuite automatiquement analysé, et vous recevrez un retour direct sur l'orthographe, le contenu et la grammaire. Ainsi, vous améliorez vos compétences en écriture et êtes bien préparé pour la vie quotidienne!",
    details_srs_title:
      'Apprentissage efficace du vocabulaire avec SRS : Répétitions automatiques et analyse de progrès personnalisée!',
    details_srs_text:
      "Avec le système SRS (Système de Répétition Espacée), vous pouvez apprendre et répéter du vocabulaire efficacement. Ce système vous rappelle quand répéter les mots pour les retenir à long terme. SRS est basé sur la méthode scientifique qui consiste à répéter à certains intervalles pour renforcer la mémoire. Vous recevrez également des statistiques sur l'avancement de l'apprentissage de chaque mot et votre taux de réussite, afin de vous concentrer sur vos faiblesses et savoir toujours à quel point vous progressez dans l'apprentissage du vocabulaire.",
    details_own_text_title:
      'Apprentissage personnalisé : Créez des textes sur mesure pour des exercices ciblés de vocabulaire et de sujets!',
    details_own_text_text:
      "Avec Jaaluu, vous pouvez facilement créer vos propres textes dans les domaines sur lesquels vous souhaitez vous concentrer. Décrivez le domaine ou le vocabulaire que vous voulez apprendre, et vous obtiendrez un texte à lire et à pratiquer. De cette façon, vous pouvez vous concentrer sur des sujets spécifiques ou du vocabulaire et élargir vos connaissances dans le domaine qui vous intéresse. Cette approche personnalisée vous aide à apprendre plus efficacement et précisément! De plus, vous pouvez créer des textes qui contiennent tout le vocabulaire que vous souhaitez réviser. Cela vous permet d'apprendre et de réviser le vocabulaire en contexte, ce qui facilite la mémorisation.",
    details_wordmap_title:
      'Cartes de mots : Connectez vos connaissances et souvenez-vous des mots facilement!',
    details_wordmap_text:
      'Avec Jaaluu, vous pouvez créer des cartes de mots qui vous aident à relier les mots et à vous en souvenir plus efficacement. Ajoutez des mots et des phrases de manière interactive à votre carte de mots et développez progressivement votre vocabulaire. Cela crée et renforce des connexions dans votre cerveau et établit des associations de manière ludique. De cette façon, vous améliorez votre compréhension linguistique – de manière durable!',
    details_paste_text_title:
      'Utilisez vos propres textes : Collez votre contenu pour apprendre avec concentration!',
    details_paste_text_text:
      "Avec Jaaluu, vous pouvez également coller vos propres textes pour les utiliser pour la pratique. Cela vous permet de travailler avec votre propre matériel et d'entraîner votre vocabulaire dans des contextes réels.",
    site_also_available: 'Le site est également disponible en',
    which_languages_title: 'Quelles langues puis-je apprendre?',
    which_languages_text: 'Ici, vous pouvez apprendre l`anglais, l`espagnol, l`allemand, l`italien, le portugais, le russe et le polonais. Sélectionnez simplement la langue que vous souhaitez apprendre et vous êtes prêt à partir !'
  },
  intro: {
    your_level: 'Quel est votre niveau de langue ?',
    beginner_explained: 'Vous pouvez lire des phrases simples',
    advanced_explained: 'Vous pouvez lire des textes plus complexes',
    your_cefr_level: 'Votre niveau CECR est',
    lets_start: 'Par quoi aimeriez-vous commencer ?',
    beginner_options: {
      simple_texts: 'Lire des textes',
      simple_articles: 'Lire des articles',
      sayings: 'Apprendre des expressions',
      view_videos: 'Regarder des vidéos',
      simple_phrases: 'Lire des phrases typiques'
    },
    advanced_options: {
      news: 'Lire des actualités',
      videos: 'Regarder des vidéos',
      wikipedia: 'Lire des articles',
      books: 'Lire des livres'
    }
  },
  languages: {
    de: 'Allemand',
    en: 'Anglais',
    es: 'Espagnol',
    fr: 'Français',
    it: 'Italien',
    pt: 'Portuguese',
    ru: 'Russe',
    zh: 'Chinois',
    pl: 'Polonais'
  },
  general: {
    beginner: 'Débutant',
    advanced: 'Avancé',
    search: 'Rechercher',
    search_imperative: 'Recherche',
    translate: 'Traduire',
    settings: 'Paramètres',
    vocabulary: 'Vocabulaire',
    shorts: 'Textes courts',
    articles: 'Articles',
    feeds: 'Actualités',
    videos: 'Vidéos',
    read: 'Lire',
    view: 'Voir',
    listen: 'Écouter',
    save: 'Enregistrer',
    more: 'plus',
    back: 'Retour',
    next: 'Suivant',
    easy: 'Facile',
    medium: 'Moyen',
    hard: 'Difficile',
    all: 'Tous',
    level: 'Niveau',
    font_size: 'Taille de police',
    language: 'Langue',
    send: 'Envoyer',
    image: 'Image',
    wordmap: 'Carte de mots',
    open: 'Ouvrir',
    add: 'Ajouter',
    question: 'Question',
    start: 'Démarrer',
    words: 'Mot | Mots'
  },
  app_bar: {
    search_in_foreign_language: 'Rechercher dans une langue étrangère',
    menu: 'Menu'
  },
  category: {
    chapter: 'Chapitre'
  },
  favorites: {
    favorites: 'Favoris',
    word: 'Mot',
    sentence: 'Phrase',
    sentences: 'Phrases',
    texts_videos: 'Textes et Vidéos',
    wordmaps: 'Cartes de mots',
    saved: 'Enregistré',
    title: 'Titre',
    type: 'Type',
    no_favorites_found: 'Aucun favori trouvé',
    filtered_by: 'filtré par',
    train: 'Entraîner',
    notify_me_srs: 'Prévenez-moi de répéter le vocabulaire',
    srs_level: 'Niveau SRS',
    success_rate: 'Taux de réussite',
    last_seen: 'Dernière fois vu',
    next_review: 'Prochaine révision',
    success_level_up: 'Le mot a été déplacé au niveau {level}',
    what_is_srs: "Qu'est-ce que le SRS ?",
    srs_short:
      'Le SRS est un système de répétition espacée. Il vous aide à mieux mémoriser les mots en les répétant au bon moment.',
    srs_long:
      "Le système est basé sur le principe que plus vous répétez un mot, mieux vous le mémorisez. Les intervalles entre les répétitions sont déterminés par le système. Si vous vous souvenez bien d'un mot, les intervalles deviennent plus longs. Si vous avez des difficultés, les intervalles deviennent plus courts. Ainsi, vous pouvez apprendre les mots plus efficacement.",
    srs_intervals: 'Les {nIntervals} intervalles sont : {intervals} jours.',
    when_does_a_word_move: 'Quand un mot passe-t-il au niveau suivant ?',
    srs_next_level_example_1:
      "Si vous ajoutez un mot aux favoris, il sera au niveau 1. Un mot au niveau 1 passera au niveau suivant si 1 jour s'est écoulé depuis que vous avez ajouté le mot, votre taux de réussite est supérieur à 50 % et vous venez de vous souvenir correctement du mot.",
    srs_next_level_example_2:
      'Un mot au niveau {level} passera au niveau suivant après que le dernier changement au niveau {levelM1} ait eu lieu il y a au moins {days} jours, votre taux de réussite est supérieur à 50 % et vous venez de vous souvenir correctement du mot.'
  },
  player: {
    maximize: 'Maximiser',
    minimize: 'Minimiser'
  },
  video: {
    error:
      "Une erreur s'est produite lors du chargement des sous-titres. Malheureusement, tous les sous-titres ne sont pas accessibles gratuitement. Veuillez essayer une autre vidéo."
  },
  feeds: {
    disclaimer:
      "Ceci est un résumé généré automatiquement par Chatgpt. L'article original est disponible",
    here: 'ici',
    generating_article: 'Générer un article...',
    error:
      "Une erreur s'est produite lors du chargement de l'article. Malheureusement, tous les articles ne sont pas accessibles gratuitement. Veuillez essayer un autre article ou copier le texte manuellement",
    open_org: "1. Ouvrir l'article original et copier le texte",
    open_own: '2. Coller le texte ici'
  },
  books: {
    read_now: 'Lire maintenant',
    continue_on_page: 'Continuer sur la page'
  },
  tooltip: {
    examples: 'Exemples',
    emotional_examples: 'Exemples émotionnels',
    definition: 'Définition',
    synonyms: 'Synonymes',
    antonyms: 'Antonymes',
    article: 'Article',
    conjugations: 'Conjugaison',
    grammer: 'Grammaire'
  },
  custom_text: {
    enter_your_own_text: 'Entrez votre propre texte',
    read_text: 'Lire le texte',
    enter_new_text: 'Entrez un nouveau',
    own_text: 'Copiez votre propre texte et collez-le ici',
    special_vocabulary: 'Vocabulaire spécial'
  },
  special_vocabulary: {
    special_vocabulary: 'Vocabulaire spécial',
    describe:
      'Entrez un texte en anglais sur un sujet ou un vocabulaire spécial que vous souhaitez apprendre',
    language_level: 'Niveau de langue',
    generate_text: 'Générer un texte'
  },
  intro_message: {
    save_word_as_fav: 'Enregistrer le mot comme favori',
    save_video_as_fav: 'Enregistrer la vidéo comme favori',
    save_article_as_fav: 'Enregistrer l’article comme favori',
    save_book_as_fav: 'Enregistrer le livre comme favori',
    save_wordmap_as_fav: 'Enregistrer la carte des mots comme favori',
    play_word: 'Écouter la prononciation du mot',
    play_article: 'Écouter l’article',
    play_book: 'Écouter le livre',
    select_main_category: 'Sélectionner une catégorie principale',
    select_sub_category: 'Sélectionner une sous-catégorie',
    click_on_read: 'Cliquez sur `LIRE` pour lire le texte complet',
    click_on_view: 'Cliquez sur `REGARDER` pour regarder une vidéo',
    last_book_page: 'Votre dernière page sera automatiquement enregistrée et affichée ici',
    wordmap_click_on_word: 'Cliquez sur le mot pour voir les mots les plus fréquemment utilisés',
    wordmap_click_on_line: 'Cliquez sur une ligne entre deux mots pour voir une phrase',
    category_progress: 'Suivez vos progrès en cochant les cases',
    conversations_select_scene: 'Sélectionnez un exercice',
    conversations_warn: 'Il semble y avoir une erreur dans votre phrase. Cliquez sur le triangle pour plus d’explications.',
    conversations_task: 'Formulez une phrase selon l’instruction à la fin du chat',
  },
  tour_guide: {
    click_on_every_word: {
      title: 'Traduire un mot',
      text: 'Vous pouvez cliquer sur n’importe quel mot pour voir les traductions, des exemples de phrases et des définitions'
    },
    click_on_i_for_sentence: {
      title: 'Phrase entière',
      text: 'Pour traduire une phrase entière, cliquez sur le symbole i. Ici vous pouvez également obtenir des explications sur la grammaire.'
    },
    no_save_custom: {
      title: 'Impossible de sauvegarder',
      text: 'Vous ne pouvez pas sauvegarder de texte personnalisé'
    },
    change_playback_rate: {
      title: 'Modifier la Vitesse de Lecture',
      text: 'Modifiez la vitesse de lecture via Menu/Paramètres/Audio et Vidéo.'
    },
    theme: {
      title: 'Couleurs',
      text: 'Vous pouvez changer les couleurs dans le menu en mode sombre'
    },
    explain_menu: {
      title: 'Menu',
      text: 'Les éléments du menu sont classés de très difficile à facile de haut en bas'
    },
    repeat_words: {
      title: 'Répéter le vocabulaire',
      text: 'Vous devez répéter {nWords} mots de vocabulaire.',
      call_to_action: 'Apprendre maintenant'
    }
  },
  chat: {
    your_message: 'Votre message',
    in_de: 'en allemand',
    in_en: 'en anglais',
    in_es: 'en espagnol',
    in_it: 'en italien',
    in_pt: 'en portugais',
    in_ru: 'en russe',
    in_zh: 'en chinois',
    in_pl: 'en polonais',
    wait: 'Veuillez patienter...',
    speak_now: 'Parlez maintenant',
    new_chat: 'Nouvelle conversation'
  },
  langs: {
    de: 'Allemand',
    en: 'Anglais',
    es: 'Espagnol',
    fr: 'Français',
    it: 'Italien',
    pt: 'Portuguese',
    ru: 'Russe',
    zh: 'Chinois'
  },
  about: {
    how_does_it_work: 'Comment ça marche ?',
    here_you_can: 'Ici, vous pouvez',
    read_texts: 'Lire des textes',
    watch_videos: 'Regarder des vidéos',
    click_on_every_wrord_for: 'Cliquez sur chaque mot pour',
    tranlations: 'traductions',
    examples: 'exemples',
    definitions: 'définitions',
    synonyms: 'synonymes',
    copy_your_own_text: 'Copier et lire vos propres textes',
    chat_with_chatbot: 'Discutez avec un chatbot',
    level: 'Votre niveau devrait être entre B1 et C2'
  },
  settings: {
    audio_video: 'Audio et vidéo',
    colors: 'Couleur',
    dark_mode: 'Mode sombre',
    lang_level: 'Niveau de langue'
  },
  error: {
    resource_not_found: "Désolé, nous n'avons pas trouvé ce que vous recherchez"
  },
  train: {
    check: 'Vérifier',
    flashcards: 'Cartes mémoire',
    exercises: 'Exercices',
    finished: 'Exercices terminés',
    go_to_favorites: 'Aller aux favoris',
    restart_exercise: 'Recommencer l’exercice',
    go_to_home: 'Aller à l’accueil',
    text_with_words: 'Texte avec des mots',
    text_includes: 'Le texte inclut les mots suivants'
  },
  wordmap: {
    click_on_circle:
      'Cliquez sur le cercle pour voir les mots les plus fréquemment utilisés avec {word}',
    click_on_edge: 'Cliquez sur la ligne entre deux mots pour voir une phrase avec les deux mots',
    open_fullscreen: 'Ouvrir en plein écran',
    add_own_word: 'Ajouter un mot personnel',
    add_sentence_with_all_words: 'Ajouter une phrase avec tous les mots'
  },
  conversations: {
    you_are: 'Vous êtes',
    you_talk_to: 'Vous parlez à',
    your_tasks: 'Vos tâches',
  },
  write: {
    min_words: 'Mots minimum',
    your_email_needs_to_contain: 'Votre e-mail doit contenir',
    rate: 'Note',
    analysis: 'Analyse',
    overall: 'Global',
    grammar: 'Grammaire',
    content: 'Contenu',
    spelling: 'Orthographe'
  }
};
