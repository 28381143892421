import { ref, Ref } from 'vue';
import { tourGuide } from './';

const LOCAL_STORAGE_KEY = 'tooltipOpenedCount';

export interface Tooltip {
  idx: number;
  timeout: any;
  content: string;
  event: any;
  open: boolean;
  openedCount: number;
  sentence: string | null;
  isCompleteSentence: boolean;
  isReverseTranslation: boolean;
}

export default () => {
  const ACTIVE_WORD = 'active-word';
  const { initTheme } = tourGuide.funcs;
  const tooltips: Ref<Tooltip[]> = ref([]);
  const openedCount = ref(0);

  const openedCounttLs = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (openedCounttLs) {
    openedCount.value = parseInt(openedCounttLs);
  }

  const openTooltip = (
    event: any,
    content: string,
    sentence: string | null,
    isCompleteSentence = false,
    isReverseTranslation = false
  ) => {
    openedCount.value += 1;
    localStorage.setItem(LOCAL_STORAGE_KEY, openedCount.value.toString());
    if (openedCount.value > 10) {
      initTheme();
    }
    event.stopPropagation();
    event.target.id = ACTIVE_WORD;
    event.target.classList.add(ACTIVE_WORD);
    const _tooltip = {
      idx: tooltips.value.length,
      timeout: null,
      content,
      event,
      open: true,
      sentence,
      openedCount: openedCount.value,
      inFullscreen: false,
      isCompleteSentence,
      isReverseTranslation
    };
    setTimeout(() => {
      tooltips.value.push(_tooltip);
    }, 0);
  };

  const removeTooltip = (idx: number) => {
    const _tooltip = tooltips.value[idx];
    if (!_tooltip) return;
    _tooltip.open = false;
    const activeWord = document.getElementById(ACTIVE_WORD);
    console.log('activ word', activeWord);
    setTimeout(() => {
      if (activeWord) {
        console.log('removed activ word');
        activeWord.classList.remove(ACTIVE_WORD);
      }
      tooltips.value.splice(idx, 1);
    }, 800);
  };

  return {
    state: { tooltips },
    funcs: { openTooltip, removeTooltip }
  };
};
