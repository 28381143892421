// @ts-nocheck
import {
  CategoryType,
  CommunicationBasics,
  EntertainmentAndLeisure,
  FeelingsAndEmotions,
  FoodAndDining,
  HealthAndWellBeing,
  MainCategories,
  NubmersAndQuantity,
  SocialContexts,
  TimeAndDates,
  TravelAndDirections
} from '@shared/openapi/jaaluu-api';
// source: https://www.manypixels.co/gallery
import COMMUNICATION_BASICS from '@jaaluu/assets/phrases/main_category_images/communication_basics.svg';
import ENTERTAINMENT_AND_LEISURE from '@jaaluu/assets/phrases/main_category_images/entertainment_and_leisure.svg';
import FEELINGS_AND_EMOTIONS from '@jaaluu/assets/phrases/main_category_images/feelings_and_emotions.svg';
import FOOD_AND_DINING from '@jaaluu/assets/phrases/main_category_images/food_and_dining.svg';
import HEALTH_AND_WELL_BEING from '@jaaluu/assets/phrases/main_category_images/health_and_well_being.svg';
import NUMBERS_AND_QUANTITY from '@jaaluu/assets/phrases/main_category_images/numbers_and_quantity.svg';
import SOCIAL_CONTEXTS from '@jaaluu/assets/phrases/main_category_images/social_contexts.svg';
import TIME_AND_DATES from '@jaaluu/assets/phrases/main_category_images/time_and_dates.svg';
import TRAVEL_AND_DIRECTIONS from '@jaaluu/assets/phrases/main_category_images/travel_and_directions.svg';

// COMMUNICATION_BASICS
import EXPRESSIONS from '@jaaluu/assets/phrases/communication_basics/expressions.svg';
import GREETINGS from '@jaaluu/assets/phrases/communication_basics/greetings.svg';
import QUESTIONS from '@jaaluu/assets/phrases/communication_basics/questions.svg';

// FEELINGS_AND_EMOTIONS
import NEGATIVE_EMOTIONS from '@jaaluu/assets/phrases/feelings_and_emotions/negative_emotions.svg';
import NEUTRAL_OR_MIXED_FEELINGS from '@jaaluu/assets/phrases/feelings_and_emotions/neutral_or_mixed_feelings.svg';
import POSITIVE_EMOTIONS from '@jaaluu/assets/phrases/feelings_and_emotions/positive_emotions.svg';

// TRAVEL_AND_DIRECTIONS
import ACCOMODATION from '@jaaluu/assets/phrases/travel_and_directions/accomodation.svg';
import DIRECTIONS from '@jaaluu/assets/phrases/travel_and_directions/directions.svg';
import LOCATIONS from '@jaaluu/assets/phrases/travel_and_directions/locations.svg';
import TRANSPORTATION from '@jaaluu/assets/phrases/travel_and_directions/transportation.svg';

// NUMBERS_AND_QUANTITY
import NUMBERS from '@jaaluu/assets/phrases/numbers_and_quantity/numbers.svg';
import QUANTITY from '@jaaluu/assets/phrases/numbers_and_quantity/quantity.svg';

// TIME_AND_DATES
import DATES from '@jaaluu/assets/phrases/time_and_dates/dates.svg';
import TIME from '@jaaluu/assets/phrases/time_and_dates/time.svg';

// ENTERTAINMENT_AND_LEISURE
import ENTERTAINMENT from '@jaaluu/assets/phrases/entertainment_and_leisure/entertainment.svg';
import LEISURE from '@jaaluu/assets/phrases/entertainment_and_leisure/leisure.svg';

// HEALTH_AND_WELL_BEING
import HEALTH from '@jaaluu/assets/phrases/health_and_well_being/health.svg';
import WELL_BEING from '@jaaluu/assets/phrases/health_and_well_being/well_being.svg';

// SOCIAL_CONTEXTS
import CULTURAL from '@jaaluu/assets/phrases/social_contexts/cultural.svg';
import POLITICAL from '@jaaluu/assets/phrases/social_contexts/political.svg';
import RELIGIOUS from '@jaaluu/assets/phrases/social_contexts/religous.svg';
import SOCIAL from '@jaaluu/assets/phrases/social_contexts/social.svg';

// FOOD_AND_DINING
import DINING from '@jaaluu/assets/phrases/food_and_dining/dining.svg';
import DRINKS from '@jaaluu/assets/phrases/food_and_dining/drinks.svg';
import FOOD from '@jaaluu/assets/phrases/food_and_dining/food.svg';

export type SubCategory =
  | CommunicationBasics
  | FeelingsAndEmotions
  | TravelAndDirections
  | NubmersAndQuantity
  | TimeAndDates
  | EntertainmentAndLeisure
  | HealthAndWellBeing
  | SocialContexts
  | FoodAndDining;

  const CAT_TYPE_ICON_NAMES = {
    [CategoryType.WRITE]: 'mdi-email-edit-outline',
    [CategoryType.CONVERSATIONS]: 'mdi-message-outline',
    [CategoryType.PHRASES]: 'mdi-text-short',
    [CategoryType.SAYINGS]: 'mdi-thought-bubble',
    [CategoryType.SIMPLE_TEXTS]: 'mdi-card-text-outline',
  }

const MAIN_CAT_IMAGES = {
  [MainCategories.COMMUNICATION_BASICS]: COMMUNICATION_BASICS,
  [MainCategories.FEELINGS_AND_EMOTIONS]: FEELINGS_AND_EMOTIONS,
  [MainCategories.TRAVEL_AND_DIRECTIONS]: TRAVEL_AND_DIRECTIONS,
  [MainCategories.NUMBERS_AND_QUANTITY]: NUMBERS_AND_QUANTITY,
  [MainCategories.TIME_AND_DATES]: TIME_AND_DATES,
  [MainCategories.ENTERTAINMENT_AND_LEISURE]: ENTERTAINMENT_AND_LEISURE,
  [MainCategories.HEALTH_AND_WELL_BEING]: HEALTH_AND_WELL_BEING,
  [MainCategories.SOCIAL_CONTEXTS]: SOCIAL_CONTEXTS,
  [MainCategories.FOOD_AND_DINING]: FOOD_AND_DINING
};

const SUB_CAT_IMAGES = {
  [MainCategories.COMMUNICATION_BASICS]: {
    [CommunicationBasics.GREETINGS]: GREETINGS,
    [CommunicationBasics.QUESTIONS]: QUESTIONS,
    [CommunicationBasics.EXPRESSIONS]: EXPRESSIONS
  },
  [MainCategories.FEELINGS_AND_EMOTIONS]: {
    [FeelingsAndEmotions.POSITIVE_EMOTIONS]: POSITIVE_EMOTIONS,
    [FeelingsAndEmotions.NEGATIVE_EMOTIONS]: NEGATIVE_EMOTIONS,
    [FeelingsAndEmotions.NEUTRAL_OR_MIXED_FEELINGS]: NEUTRAL_OR_MIXED_FEELINGS
  },
  [MainCategories.TRAVEL_AND_DIRECTIONS]: {
    [TravelAndDirections.DIRECTIONS]: DIRECTIONS,
    [TravelAndDirections.LOCATIONS]: LOCATIONS,
    [TravelAndDirections.TRANSPORTATION]: TRANSPORTATION,
    [TravelAndDirections.ACCOMODATION]: ACCOMODATION
  },
  [MainCategories.NUMBERS_AND_QUANTITY]: {
    [NubmersAndQuantity.NUMBERS]: NUMBERS,
    [NubmersAndQuantity.QUANTITY]: QUANTITY
  },
  [MainCategories.TIME_AND_DATES]: {
    [TimeAndDates.TIME]: TIME,
    [TimeAndDates.DATES]: DATES
  },
  [MainCategories.ENTERTAINMENT_AND_LEISURE]: {
    [EntertainmentAndLeisure.ENTERTAINMENT]: ENTERTAINMENT,
    [EntertainmentAndLeisure.LEISURE]: LEISURE
  },
  [MainCategories.HEALTH_AND_WELL_BEING]: {
    [HealthAndWellBeing.HEALTH]: HEALTH,
    [HealthAndWellBeing.WELL_BEING]: WELL_BEING
  },
  [MainCategories.SOCIAL_CONTEXTS]: {
    [SocialContexts.SOCIAL]: SOCIAL,
    [SocialContexts.CULTURAL]: CULTURAL,
    [SocialContexts.RELIGIOUS]: RELIGIOUS,
    [SocialContexts.POLITICAL]: POLITICAL
  },
  [MainCategories.FOOD_AND_DINING]: {
    [FoodAndDining.FOOD]: FOOD,
    [FoodAndDining.DRINKS]: DRINKS,
    [FoodAndDining.DINING]: DINING
  }
};


export default () => {


  return { consts: { MAIN_CAT_IMAGES, SUB_CAT_IMAGES, CAT_TYPE_ICON_NAMES} };
};
