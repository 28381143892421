<script setup lang="ts">
const emits = defineEmits(['close']);
const props = defineProps({
  title: {
    type: String,
    required: true
  },
  icon: {
    type: String,
    required: false
  },
  showClose: {
    type: Boolean,
    default: true
  }
});
</script>
<template>
  <v-dialog v-bind="$attrs">
    <v-card class="pa-4 pa-md-6 pa-xl-6">
      <div class="d-flex justify-space-between mb-6 align-center" >
        <div class="title-sm">{{ props.title }} <v-icon size="18" :icon="icon" v-if="icon" /></div>
        <v-btn v-if="showClose" icon size="x-small" variant="text" @click="() => emits('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>
