<script setup lang="ts">
import stores from '@jaaluu/stores';
// @ts-ignore
import logoBallTrans from '@jaaluu/assets/images/logo_ball_trans.png';
import Dialog from '@jaaluu/components/Dialog.vue';
import { ref } from 'vue';

const { APP_VERSION } = stores.app.consts;
const { inFullscreen } = stores.settings.state;
const appVersionOpen = ref(false);
const links = [
  { name: 'About', route: '/about' },
  { name: 'Support', route: '/support' },
  { name: 'Licence', route: '/licence' },
  { name: 'Privacy Policy', route: '/privacy-policy' },
  { name: 'Disclaimer', route: '/disclaimer' },
  { name: 'Impressum', route: '/impressum' },
];
</script>

<template>
  <v-footer color="green lighten-1 footer" padless v-if="!inFullscreen">
    <div class="w-full mt-2">
      <div class="d-block d-sm-flex justify-center flex-wrap align-content-center text-center">
        <v-btn
          v-for="link in links"
          :key="link.route"
          color="black"
          variant="text"
          size="x-small"
          :to="link.route"
        >
          {{ link.name }}
        </v-btn>
      </div>
      <div
        class="green subtitle-sm lighten-2 mt-2 d-flex align-center justify-center text-black"
        @click="() => (appVersionOpen = true)"
      >
        <div>{{ new Date().getFullYear() }}</div>
        <v-img contain class="logo-ball ml-1" :src="logoBallTrans" />
        <div>JAALUU</div>
      </div>
    </div>
    <Dialog v-model="appVersionOpen" :max-width="250" :title="'App Version'">
      <div class="subtitle d-flex justify-center">Version: {{ APP_VERSION }}</div>
    </Dialog>
  </v-footer>
</template>

<style lang="css" scoped>
.footer {
  max-height: 80px;
}
@media screen and (max-width: 300px) {
  .max-footer {
    max-height: 200px;
  }
}
.logo-ball {
  max-width: 15px;
  max-height: 15px;
}
</style>
