import { ref, Ref } from 'vue';
import dayjs from 'dayjs';

const USER_ID_STORAGE_KEY = 'user-id';
const REQUSESTS_STORAGE_KEY = 'audio-requests';
const MAX_AUDIO_FILE_REQUESTS_PER_DAY = 500;
const today = dayjs().format('YYYY_MM_DD');

export default () => {
  const userId = ref('');
  const audioFileReqCount: Ref<Record<string, number>> = ref({});

  const setUserIdInLs = (id: string) => {
    localStorage.setItem(USER_ID_STORAGE_KEY, id);
  };

  const setUserData = () => {
    setUserId();
    setRequests();
  };

  const setUserId = () => {
    userId.value = localStorage.getItem(USER_ID_STORAGE_KEY) || '';
    if (!userId.value) {
      userId.value =
        Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      setUserIdInLs(userId.value);
    }
  };

  const setRequests = () => {
    const requestsLs = localStorage.getItem(REQUSESTS_STORAGE_KEY);
    if (requestsLs) {
      try {
        audioFileReqCount.value = JSON.parse(requestsLs);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const setRequestsInLs = () => {
    localStorage.setItem(REQUSESTS_STORAGE_KEY, JSON.stringify(audioFileReqCount.value));
  };

  const isMaxReqReached = () => {
    if (!audioFileReqCount.value[today]) {
      audioFileReqCount.value[today] = 0;
    }
    audioFileReqCount.value[today] += 1;
    setRequestsInLs();
    return audioFileReqCount.value[today] >= MAX_AUDIO_FILE_REQUESTS_PER_DAY;
  }

  return {
    state: { audioFileReqCount },
    funcs: {
      setUserData,
      setRequestsInLs,
      isMaxReqReached
    }
  };
};
