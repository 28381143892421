import LangForeignWrapper from '@jaaluu/views/LangForeignWrapper.vue';
import { createRouter, createWebHistory } from 'vue-router';

import Welcome from '@jaaluu/views/Welcome/Welcome.vue';

const router = createRouter({
  history: createWebHistory((import.meta as any).env.BASE_URL),
  routes: [
    {
      path: '/welcome',
      name: 'welcome',
      component: Welcome
    },
    {
      path: '/intro',
      name: 'intro',
      component: () => import('@jaaluu/views/Intro.vue')
    },
    {
      path: '/:langForeign',
      component: LangForeignWrapper,
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('@jaaluu/views/Home/Home.vue')
        },
        {
          path: 'feeds/:topic',
          name: 'feed-list',
          component: () => import('@jaaluu/views/Lists/FeedList/FeedList.vue')
        },
        {
          path: 'feeds/:topic/:id',
          name: 'feed-details',
          component: () => import('@jaaluu/views/Details/FeedDetails/FeedDetails.vue')
        },
        {
          path: 'videos/:channelId',
          name: 'video-list',
          component: () => import('@jaaluu/views/Lists/VideoList/VideoList.vue')
        },
        {
          path: 'videos/:channelId/:id',
          name: 'video-details',
          component: () => import('@jaaluu/views/Details/VideoDetails/VideoDetails.vue')
        },
        {
          path: 'wikipedia',
          name: 'wikipedia-list',
          component: () => import('@jaaluu/views/Lists/WikipediaList/WikipediaList.vue')
        },
        {
          path: 'wikipedia/:title',
          name: 'wikipedia-details',
          component: () => import('@jaaluu/views/Details/WikipediaDetails/WikipediaDetails.vue')
        },
        {
          path: 'kids_lexicon',
          name: 'kids-lexicon-list',
          component: () => import('@jaaluu/views/Lists/KlexikonList/KlexikonList.vue')
        },
        {
          path: 'kids_lexicon/:title',
          name: 'kids-lexicon-details',
          component: () => import('@jaaluu/views/Details/KlexikonDetails/KlexikonDetails.vue')
        },
        {
          path: 'books',
          name: 'books-list',
          component: () => import('@jaaluu/views/Lists/BooksList/BooksList.vue')
        },
        {
          path: 'books/:id',
          name: 'book-details',
          component: () => import('@jaaluu/views/Details/BookDetails/BookDetails.vue')
        },
        {
          path: 'category/:categoryType/',
          name: 'category-list-main',
          component: () => import('@jaaluu/views/Lists/CategoryList/CategoryListMain.vue')
        },
        {
          path: 'category/:categoryType/:category',
          name: 'category-list-sub',
          component: () => import('@jaaluu/views/Lists/CategoryList/CategoryListSub.vue')
        },
        {
          path: 'category/conversations/:mainCategory/:subCategory',
          name: 'conversation-details',
          component: () =>
            import(
              '@jaaluu/views/Details/CategoryDetails/ConversationDetails/ConversationDetails.vue'
            )
        },
        {
          path: 'category/write/:mainCategory/:subCategory',
          name: 'write-details',
          component: () => import('@jaaluu/views/Details/WriteDetails/WriteDetails.vue')
        },
        {
          path: 'category/:categoryType/:mainCategory/:subCategory',
          name: 'category-details',
          component: () => import('@jaaluu/views/Details/CategoryDetails/CategoryDetails.vue')
        },
        {
          path: 'favorites/words',
          name: 'favorite-words',
          component: () => import('@jaaluu/views/Favorites/FavoriteWords.vue')
        },
        {
          path: 'favorites/sentences',
          name: 'favorite-sentences',
          component: () => import('@jaaluu/views/Favorites/FavoriteSentences.vue')
        },
        {
          path: 'favorites/sentences/:word',
          name: 'favorite-sentences-word',
          component: () => import('@jaaluu/views/Favorites/FavoriteSentences.vue')
        },
        {
          path: 'favorites/urls',
          name: 'favorite-urls',
          component: () => import('@jaaluu/views/Favorites/FavoriteUrls.vue')
        },
        {
          path: 'favorites/wordmaps',
          name: 'favorite-wordmaps',
          component: () => import('@jaaluu/views/Favorites/FavoriteWordmaps.vue')
        },
        {
          path: 'chat',
          name: 'chat',
          component: () => import('@jaaluu/views/Details/ChatDetails/ChatDetails.vue')
        },
        {
          path: 'custom',
          name: 'custom',
          component: () => import('@jaaluu/views/Details/CustomTextDetails/CustomTextDetails.vue')
        },
        {
          path: 'about',
          name: 'about',
          component: () => import('@jaaluu/views/Footer/About.vue')
        },
        {
          path: 'support',
          name: 'support',
          component: () => import('@jaaluu/views/Footer/Support.vue')
        },
        {
          path: 'disclaimer',
          name: 'disclaimer',
          component: () => import('@jaaluu/views/Footer/Disclaimer.vue')
        },
        {
          path: 'privacy-policy',
          name: 'privacy-policy',
          component: () => import('@jaaluu/views/Footer/PrivacyPolicy.vue')
        },
        {
          path: 'impressum',
          name: 'impressum',
          component: () => import('@jaaluu/views/Footer/Impressum.vue')
        },
        {
          path: 'licence',
          name: 'licence',
          component: () => import('@jaaluu/views/Footer/Licence.vue')
        },
        {
          path: 'not_found',
          name: 'not-found',
          component: () => import('@jaaluu/views/NotFound.vue')
        },
        {
          path: 'learn/flipcards',
          name: 'learn-flipcards',
          component: () => import('@jaaluu/views/FlipCards.vue')
        },
        {
          path: 'wordmap/:word',
          name: 'wordmap',
          component: () => import('@jaaluu/views/Wordmap/Wordmap.vue')
        },
        {
          path: 'learn/exercises',
          name: 'learn-exercises',
          component: () => import('@jaaluu/views/Train/Train.vue')
        },
        {
          path: 'auto-text',
          name: 'auto-text',
          component: () => import('@jaaluu/views/AutoText.vue')
        },
        {
          path: 'add-auto-text',
          name: 'add-auto-text',
          component: () => import('@jaaluu/views/AddAutoText.vue')
        }
        /*
    {
      path: "/welcome",
      name: "welcome",
      component: Landing,
    },
    {
      path: "/registrate",
      name: "registrate",
      component: Registrate,
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/videos/:videoId",
      name: "video",
      component: Video,
    },
    {
      path: "/videos",
      name: "videos",
      component: Videos,
    },
    {
      path: "/articles/:title",
      name: "article-details",
      component: Article,
    },
    {
      path: "/news",
      name: "news",
      component: News,
    },
    {
      path: "/news/:title",
      name: "news-details",
      component: NewsDetails,
    },
    {
      path: "/categories/:categoryType",
      name: "unit-categories",
      component: UnitCategories,
    },
    {
      path: "/categories/:categoryType/:subCategoryId",
      name: "unit-details",
      component: UnitDetails,
    },
    {
      path: "/admin/login",
      name: "admin-login",
      component: AdminLogin,
    },
    {
      path: "/admin",
      name: "admin-home",
      component: AdminHome,
    },
    {
      path: "/admin/vocabulary-categories",
      name: "admin-vocabulary-categories",
      component: AdminCategories,
    },
    {
      path: "/admin/vocabulary-categories/:category",
      name: "admin-vocabulary-category",
      component: AdminWords,
    },
    {
      path: "/admin/vocabulary-categories/:category/edit-word",
      name: "admin-vocabulary-category-edit-word",
      component: AdminEditWord,
    },
    {
      path: "/admin/vocabulary-categories/:category/definitions",
      name: "admin-vocabulary-category-definitions",
      component: AdminVocabularyDefinitions,
    },
    {
      path: "/admin/vocabulary-categories/:category/vocabularies/:word",
      name: "admin-vocabulary-category-vocabularies",
      component: AdminVocabularies,
    } */
      ]
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    // Always scroll to the top of the page on navigation
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

/* 
const ADMIN_LOGIN_PATH = "/admin/login";
router.beforeEach(async (to, from, next) => {
  // admin
  if (to.path !== ADMIN_LOGIN_PATH && to.path.startsWith("/admin")) {
    const token = localStorage.getItem("token");
    if (!token) {
      next({ path: ADMIN_LOGIN_PATH });
    } else {
      const res = await jaaluuApi.get("/validate-token").catch((error: any) => {
        console.warn("Could not validate token", error);
        next({ path: ADMIN_LOGIN_PATH });
      });
    }
  }
  next();
});
*/

export default router;
