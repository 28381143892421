<script setup lang="ts">
import AudioPlayer from '@jaaluu/components/AudioPlayer.vue';
import TextFieldWithButton from '@jaaluu/components/TextFieldWithButton.vue';
import TextWithTooltips from '@jaaluu/components/TextWithTooltips.vue';
import stores from '@jaaluu/stores';
import { ExampleSentence, WordmapData } from '@jaaluu/stores/favorites';
import { t } from '@shared/i18n';
import { WordInfoService } from '@shared/openapi/jaaluu-api';
import sharedStores from '@shared/shared-stores';
import IntroMessage from '@jaaluu/components/IntroMessage.vue';
import { IntroMessageType } from '@jaaluu/stores/intro-message';
// @ts-ignore
import cytoscape from 'cytoscape';
import { v4 as uuidv4 } from 'uuid';
import { computed, onMounted, ref, Ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const LOCAL_KEY_FULLSCREEN_WORDMAP = 'lastFullscreenWordmap';

const props = defineProps({
  word: {
    required: false,
    type: String
  }
});
const route = useRoute();

interface WordmapWord {
  id: string;
  word: string;
  label: string;
}
const CY_ID = Math.random().toString(36).substring(7);

const { langForeign, langNative } = stores.language.state;
const { inDarkTheme } = stores.settings.state;
const { favWordmaps, favWordmapsList } = stores.favorites.state;
const { toggleFavoriteWordmap } = stores.favorites.funcs;
const { openNewTab } = sharedStores.router.funcs;
const { handleDefaultError } = stores.error.funcs;
const { setIntroMsgSeen } = stores.introMessage.funcs;

const cy: Ref<any> = ref(null);
const startWord: Ref<string> = ref(props.word ? props.word : route.params.word.toString());
const startWordId = uuidv4();
const exampleSentences: Ref<ExampleSentence[]> = ref([]);
const firstWordLoaded = ref(false);
const isLoadingSentence = ref(false);
const addedWords: Ref<WordmapWord[]> = ref([]);
const isLoadingOwnWord = ref(false);
const isRemovingTaphold = ref(false);
const currentZoom = ref(1);
const ownWordTranslation = ref({
  word: '',
  add_to_id: ''
});

const isTooltip = computed(() => {
  return props.word;
});

const isFavorite = computed(() => {
  return favWordmapsList.value.includes(startWord.value);
});

const layout = computed(() => ({
  name: 'concentric',
  animate: true,
  fit: false,
  minNodeSpacing: isTooltip.value ? 40 : 50
}));

const baseNodeStyle = computed(() => ({
  'background-color': !inDarkTheme.value ? '#f6f6f6' : '#4c4c4c',
  width: '20px',
  height: '20px',
  'font-size': isTooltip.value ? '12px' : '14px',
  label: 'data(label)',
  color: inDarkTheme.value ? '#f6f6f6' : '#4c4c4c',
  'text-valign': 'center'
}));

onMounted(() => {
  const id = startWordId;
  if (startWord.value) {
    addedWords.value.push({ word: startWord.value, id, label: startWord.value });
  }
  setTimeout(() => {
    cy.value = cytoscape({
      container: document.getElementById(CY_ID),
      zoomingEnabled: props.word ? false : true,
      elements: [
        {
          data: { id, word: startWord.value, label: startWord.value } as WordmapWord,
          style: baseNodeStyle.value
        }
      ],
      maxZoom: 2,
      style: [
        {
          selector: 'node',
          style: baseNodeStyle.value
        },
        {
          selector: 'edge',
          style: {
            width: 1,
            'line-color': '#ccc',
            'target-arrow-color': '#ccc',
            'curve-style': 'bezier',
            opacity: 0.5
          }
        }
      ],
      layout: layout.value
    });

    const _favWordmapDataLs = localStorage.getItem(LOCAL_KEY_FULLSCREEN_WORDMAP);
    let favWodmapData: WordmapData | undefined;
    if (_favWordmapDataLs) {
      favWodmapData = JSON.parse(_favWordmapDataLs);
      localStorage.removeItem(LOCAL_KEY_FULLSCREEN_WORDMAP);
    } else if (isFavorite.value) {
      const favWodmap = favWordmaps.value.find((fav) => fav.word === startWord.value);
      if (favWodmap) {
        favWodmapData = favWodmap.data;
      }
    }
    if (favWodmapData) {
      cy.value.json(favWodmapData.wordmap);
      exampleSentences.value = favWodmapData.exampleSentences;
      addedWords.value = favWodmapData.addedWords;
      relayout();
      cy.value.nodes().style(baseNodeStyle.value);
    }

    if (!cy.value) return;
    cy.value.on('tap', 'node', (evt: Event) => {
      console.log('evt in tap', evt);
      if (isRemovingTaphold.value) return;
      addNode(evt.target);
    });
    cy.value.on('cxttap taphold', 'node', (evt: Event) => {
      console.log('evt in taphold', evt);
      isRemovingTaphold.value = true;
      removeNode(evt.target);
      setTimeout(() => {
        isRemovingTaphold.value = false;
      }, 2000);
    });
    cy.value.on('tap', 'edge', (evt: any) => {
      var edge = evt.target;
      const sentence = exampleSentences.value.find((ex) => ex.id === edge.id());
      if (sentence) {
        console.log('sentence', sentence);
        const el = document.getElementById(sentence.id);
        el?.classList.add('highlight');
        setTimeout(() => {
          el?.classList.remove('highlight');
        }, 2000);
        return;
      }
      isLoadingSentence.value = true;
      const _words = getWordsByEdgeId(edge.id());
      if (!_words) return;
      setIntroMsgSeen(IntroMessageType.WORDMAP_CLICK_ON_LINE);
      WordInfoService.getExampleSentenceV1WordInfoExampleSentencePost({
        body: {
          words: _words.map((w) => w.word)
        },
        query: {
          foreign: langForeign.value!
        }
      }).then((res: any) => {
        if (!res.data || !res.data.sentence) return;
        exampleSentences.value.unshift({
          id: edge.id(),
          sentence: res.data.sentence
        });
        isLoadingSentence.value = false;
        if (isFavorite.value) {
          saveWordmapInLs();
        }
      });
    });
  }, 1000);
});

const addSentenceAllWords = () => {
  isLoadingSentence.value = true;
  WordInfoService.getExampleSentenceV1WordInfoExampleSentencePost({
    body: {
      words: addedWords.value.map((w) => w.word)
    },
    query: {
      foreign: langForeign.value!
    }
  }).then((res: any) => {
    if (!res.data || !res.data.sentence) return;
    exampleSentences.value.unshift({
      id: 'all',
      sentence: res.data.sentence
    });
    isLoadingSentence.value = false;
    if (isFavorite.value) {
      saveWordmapInLs();
    }
  });
};

const removeNode = (node: any) => {
  removeNodeById(node.id());
};

const removeNodeById = (nodeId: string) => {
  var j = cy.value.$(`$#${nodeId}`);
  cy.value.remove(j);
  addedWords.value = addedWords.value.filter((w) => w.id !== nodeId);
  if (isFavorite.value) {
    saveWordmapInLs();
  }
};

const getWordmapData = () => {
  return {
    wordmap: cy.value.json(),
    exampleSentences: exampleSentences.value,
    addedWords: addedWords.value
  };
};

const _toggleFavorit = () => {
  setIntroMsgSeen(IntroMessageType.SAVE_WORDMAP_AS_FAV);
  toggleFavoriteWordmap(startWord.value, getWordmapData(), langForeign.value!);
};

const relayout = () => {
  setTimeout(() => {
    if (!cy.value) return;
    console.log('relayout');
    cy.value.layout(layout.value).run();
    setTimeout(() => {
      fit();
    }, 500);
  }, 500);
};

const fit = () => {
  if (!cy.value) return;
  cy.value.fit(null, 20);
  cy.value.center();
  currentZoom.value = cy.value.zoom();
};

const getWordById = (id: string) => {
  return addedWords.value.find((w) => w.id === id);
};

const getWordsByEdgeId = (id: string) => {
  const ids = id.split('_');
  if (ids.length !== 2) return [];
  const _w1 = getWordById(ids[0]);
  const _w2 = getWordById(ids[1]);
  if (!_w1 || !_w2) return [];
  return [_w1, _w2];
};

const addNode = (parentNode: any) => {
  if (!langForeign.value) return;
  setIntroMsgSeen(IntroMessageType.WORDMAP_CLICK_ON_WORD);
  console.log('parentNode', parentNode.id());
  const word = getWordById(parentNode.id());
  if (!word) return;
  const query = {
    q: word.word,
    foreign: langForeign.value,
    n: 3,
    exclude: addedWords.value.map((w) => w.word)
  };
  WordInfoService.getMostCommonWordsV1WordInfoMostCommonWordsGet({ query }).then(
    async (res: any) => {
      if (!res.data || !res.data.words) return;
      firstWordLoaded.value = true;
      for await (const word of res.data.words) {
        if (addedWords.value.map((w) => w.word).includes(word)) continue;
        const id = uuidv4();
        addedWords.value.push({ word, id, label: word });
        const parentId = parentNode.id();
        cy.value.add([
          {
            group: 'nodes',
            data: { word, id, label: word } as WordmapWord,
            style: baseNodeStyle.value
          },
          { data: { id: `${id}_${parentId}`, source: parentId, target: id } }
        ]);
        if (isFavorite.value) {
          saveWordmapInLs();
        }
        console.log('word loop', word);
      }
      relayout();
    }
  );
};

const saveWordmapInLs = () => {
  localStorage.setItem(LOCAL_KEY_FULLSCREEN_WORDMAP, JSON.stringify(getWordmapData()));
};

const openInFullscreen = () => {
  saveWordmapInLs();
  openNewTab(`/wordmap/${startWord.value}`);
};

const addOwnWord = () => {
  const word = ownWordTranslation.value.word;
  const parentWord = startWord.value;
  const parendWordId = getWordById(ownWordTranslation.value.add_to_id)?.id;
  const newWordId = uuidv4();
  if (!parendWordId) return;
  addedWords.value.push({ word, id: newWordId, label: word });
  cy.value.add([
    {
      group: 'nodes',
      data: { word, id: newWordId, label: word },
      style: baseNodeStyle.value
    },
    {
      data: {
        id: `${parendWordId}_${newWordId}`,
        words: [parentWord, word],
        source: parendWordId,
        target: newWordId
      }
    }
  ]);
  ownWordTranslation.value.word = '';
  ownWordTranslation.value.add_to_id = '';
  relayout();
  if (isFavorite.value) {
    saveWordmapInLs();
  }
};

const searchOwnWord = (text: string) => {
  isLoadingOwnWord.value = true;
  const query: any = {
    q: text,
    native: langForeign.value,
    foreign: langNative.value
  };
  WordInfoService.getTranslationV1WordInfoTranslationGet({ query })
    .then((res: any) => {
      if (!res.data || !res.data.translation) return;
      ownWordTranslation.value.word = res.data.translation;
      ownWordTranslation.value.add_to_id = startWordId;
    })
    .catch(handleDefaultError)
    .finally(() => {
      isLoadingOwnWord.value = false;
    });
};

const removeSentence = (sentence: ExampleSentence) => {
  exampleSentences.value = exampleSentences.value.filter((s) => s.id !== sentence.id);
  if (isFavorite.value) {
    saveWordmapInLs();
  }
};

const zoom = (dir: 'plus' | 'minus') => {
  if (!cy.value) return;
  cy.value.zoomingEnabled(true);
  if (dir === 'plus') {
    currentZoom.value += 0.075;
  } else {
    currentZoom.value -= 0.075;
  }
  console.log('currentZoom', currentZoom.value);
  cy.value.zoom(currentZoom.value);
};

watch(
  () => inDarkTheme.value,
  () => cy.value.nodes().style(baseNodeStyle.value)
);
</script>

<template>
  <div :class="{ 'pa-2': !isTooltip }">
    <div class="title mb-4 d-flex align-center justify-space-between">
      <div>
        <span v-if="!isTooltip" class="mr-4">{{ t('general.wordmap') }}: {{ startWord }}</span>
        <v-btn
          variant="text"
          size="small"
          class="mr-4"
          v-if="exampleSentences.length > 0"
          @click="() => _toggleFavorit()"
          :color="isFavorite ? 'green' : undefined"
          :icon="isFavorite ? 'mdi-star' : 'mdi-star-outline'"
        />
      </div>
      <IntroMessage
        v-if="exampleSentences.length > 0"
        :type="IntroMessageType.SAVE_WORDMAP_AS_FAV"
        icon="mdi-star"
        class="mt-1"
      />
      <div class="dummy-div"></div>
    </div>
    <div class="width-left d-flex justify-end pr-0 pr-md-6" v-if="!isTooltip">
      <v-btn variant="text" size="x-small" icon="mdi-plus" @click="() => zoom('plus')" />
      <v-btn variant="text" size="x-small" icon="mdi-minus" @click="() => zoom('minus')" />
      <v-btn variant="text" size="x-small" icon="mdi-fit-to-page-outline" @click="() => fit()" />
    </div>
    <div class="d-block" :class="{ 'd-md-flex': !isTooltip }">
      <div :class="{ tooltip: isTooltip, 'width-left': !isTooltip }" class="cy" :id="CY_ID"></div>
      <v-divider vertical></v-divider>
      <div id="sentences" class="pa-1" :class="{ 'ml-2': !isTooltip }">
        <IntroMessage
          v-if="exampleSentences.length === 0 && !firstWordLoaded"
          :type="IntroMessageType.WORDMAP_CLICK_ON_WORD"
          icon="mdi-circle"
          class="mt-2"
        />
        <IntroMessage
          v-else-if="!isLoadingSentence && exampleSentences.length === 0 && firstWordLoaded"
          :type="IntroMessageType.WORDMAP_CLICK_ON_LINE"
          icon="mdi-minus"
          class="mt-2"
        />
        <div class="skeleton-no-margin" v-if="isLoadingSentence">
          <v-skeleton-loader height="20" width="200" type="text"></v-skeleton-loader>
        </div>
        <div v-if="exampleSentences.length > 0">
          <div
            class="d-flex align-center justify-space-between w-full"
            v-for="ex in exampleSentences"
            :id="ex.id"
            :key="ex.id"
          >
            <div class="d-flex align-center">
              <TextWithTooltips :text="ex.sentence" />
              <AudioPlayer :sentences-list="[ex.sentence]" :show-listen-text="false" />
            </div>
            <div v-if="!isTooltip" class="subtitle ml-6">
              <v-btn
                variant="text"
                size="x-small"
                icon="mdi-close"
                @click="() => removeSentence(ex)"
              />
            </div>
          </div>
          <div v-if="!exampleSentences.find((s) => s.id === 'all') && addedWords.length < 8">
            <v-btn
              size="x-small"
              color="primary"
              class="mt-4"
              variant="text"
              @click="() => addSentenceAllWords()"
              >{{ t('wordmap.add_sentence_with_all_words') }}</v-btn
            >
          </div>
        </div>
        <div v-if="!isTooltip">
          <v-divider class="my-4" />
          <div>
            <div v-for="word in addedWords" class="d-flex justify-space-between align-center">
              <TextWithTooltips :text="word.word" />
              <div v-if="addedWords.length > 1 && word.id !== startWordId" class="subtitle">
                <v-btn
                  variant="text"
                  size="x-small"
                  icon="mdi-close"
                  @click="() => removeNodeById(word.id)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-end mt-4"
        v-if="(exampleSentences.length > 0 || addedWords.length > 3) && props.word"
      >
        <v-btn
          size="x-small"
          color="primary"
          variant="text"
          class=""
          prepend-icon="mdi-fullscreen"
          @click="() => openInFullscreen()"
          >{{ t('wordmap.open_fullscreen') }}</v-btn
        >
      </div>
    </div>
    <div
      v-if="!isTooltip"
      class="width-left mt-4 d-flex align-center ml-3"
      :class="{ tooltip: isTooltip }"
    >
      <TextFieldWithButton
        clearable
        class="w-200"
        wrapperClasses="align-center"
        persistent-hint
        :hint="`${t('general.search_imperative')} ${t(`chat.in_${langNative}`)}`"
        @search="(text: string) => searchOwnWord(text)"
        :loading="isLoadingOwnWord"
        :clear-on-search="true"
        ><div>{{ t('wordmap.add_own_word') }}</div></TextFieldWithButton
      >
    </div>
    <div class="d-flex align-center mt-6" v-if="ownWordTranslation.word">
      <div class="mr-8">{{ ownWordTranslation.word }}</div>
      <v-select
        v-model="ownWordTranslation.add_to_id"
        :items="addedWords.map((w) => ({ title: w.word, value: w.id }))"
        density="compact"
        color="green"
        variant="underlined"
        :label="t('wordmap.add_to_word')"
        hide-details
        class="w-100px"
      />
      <v-btn
        :disabled="isLoadingOwnWord"
        size="x-small"
        color="primary"
        class="ml-4"
        variant="text"
        @click="() => addOwnWord()"
        >{{ t('general.add') }}</v-btn
      >
    </div>
  </div>
</template>
<style scoped lang="scss">
#sentences {
  overflow-y: auto;
}
.cy {
  height: 40vh;
  display: block;
  &.tooltip {
    height: 180px !important;
  }
}
.width-left {
  width: 100%;
  &.tooltip {
    width: 100% !important;
  }
}
.highlight {
  color: rgb(var(--v-theme-primary));
}
@media screen and (min-width: 600px) {
  #sentences {
    max-height: 50vh;
  }
  #cy {
    height: 50vh;
    display: block;
  }
  .width-left {
    width: calc(100% - 20vw);
  }
}
</style>
