import useLoading from './loading';
import useSnackbar from './snackbar'
import useDatetime from './datetime';
import useRouter from './router';
import useAnimate from './animate';
import useRandom from './random';

export const loading = useLoading();
export const snackbar = useSnackbar();
export const datetime = useDatetime();
export const router = useRouter();
export const animate = useAnimate();
export const random = useRandom();


export default {
  loading,
  snackbar,
  datetime,
  router,
  animate,
  random
};
