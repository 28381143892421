import {
  CommunicationBasics,
  EntertainmentAndLeisure,
  FeelingsAndEmotions,
  FoodAndDining,
  HealthAndWellBeing,
  MainCategories,
  NubmersAndQuantity,
  SocialContexts,
  TimeAndDates,
  TravelAndDirections
} from '@shared/openapi/jaaluu-api';

export const CATEGORIES_RU = {
  [MainCategories.COMMUNICATION_BASICS]: {
    title: 'Общение',
    categories: {
      [CommunicationBasics.GREETINGS]: 'Приветствия',
      [CommunicationBasics.QUESTIONS]: 'Вопросы',
      [CommunicationBasics.EXPRESSIONS]: 'Выражения'
    }
  },
  [MainCategories.FEELINGS_AND_EMOTIONS]: {
    title: 'Чувства и эмоции',
    categories: {
      [FeelingsAndEmotions.POSITIVE_EMOTIONS]: 'Положительные эмоции',
      [FeelingsAndEmotions.NEGATIVE_EMOTIONS]: 'Отрицательные эмоции',
      [FeelingsAndEmotions.NEUTRAL_OR_MIXED_FEELINGS]: 'Нейтральные или смешанные чувства'
    }
  },
  [MainCategories.TRAVEL_AND_DIRECTIONS]: {
    title: 'Путешествия и направления',
    categories: {
      [TravelAndDirections.DIRECTIONS]: 'Направления',
      [TravelAndDirections.LOCATIONS]: 'Места',
      [TravelAndDirections.TRANSPORTATION]: 'Транспорт',
      [TravelAndDirections.ACCOMODATION]: 'Проживание'
    }
  },
  [MainCategories.NUMBERS_AND_QUANTITY]: {
    title: 'Цифры и количество',
    categories: {
      [NubmersAndQuantity.NUMBERS]: 'Цифры',
      [NubmersAndQuantity.QUANTITY]: 'Количество'
    }
  },
  [MainCategories.TIME_AND_DATES]: {
    title: 'Время и даты',
    categories: {
      [TimeAndDates.TIME]: 'Время',
      [TimeAndDates.DATES]: 'Даты'
    }
  },
  [MainCategories.ENTERTAINMENT_AND_LEISURE]: {
    title: 'Развлечения и досуг',
    categories: {
      [EntertainmentAndLeisure.ENTERTAINMENT]: 'Развлечения',
      [EntertainmentAndLeisure.LEISURE]: 'Досуг'
    }
  },
  [MainCategories.HEALTH_AND_WELL_BEING]: {
    title: 'Здоровье и благополучие',
    categories: {
      [HealthAndWellBeing.HEALTH]: 'Здоровье',
      [HealthAndWellBeing.WELL_BEING]: 'Благополучие'
    }
  },
  [MainCategories.SOCIAL_CONTEXTS]: {
    title: 'Социальные контексты',
    categories: {
      [SocialContexts.SOCIAL]: 'Социальные',
      [SocialContexts.CULTURAL]: 'Культурные',
      [SocialContexts.RELIGIOUS]: 'Религиозные',
      [SocialContexts.POLITICAL]: 'Политические'
    }
  },
  [MainCategories.FOOD_AND_DINING]: {
    title: 'Еда и обед',
    categories: {
      [FoodAndDining.FOOD]: 'Еда',
      [FoodAndDining.DRINKS]: 'Напитки',
      [FoodAndDining.DINING]: 'Обед'
    }
  }
};
