export default () => {
  const formatNumber = (num: number) => {
    if (!num) return;

    if (num >= 1e9) {
      return (num / 1e9).toFixed(1) + 'B'; // Billions
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + 'M'; // Millions
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + 'K'; // Thousands
    } else {
      return num.toString(); // Less than 1000
    }
  };

  const getRelativeTime = (date, lang = 'en') => {
    const now = new Date();
    const elapsed = date - now; // difference in milliseconds

    const units = [
      { max: 60, value: 1000, name: 'second' },
      { max: 60, value: 1000 * 60, name: 'minute' },
      { max: 24, value: 1000 * 60 * 60, name: 'hour' },
      { max: 30, value: 1000 * 60 * 60 * 24, name: 'day' },
      { max: 12, value: 1000 * 60 * 60 * 24 * 30, name: 'month' },
      { max: Infinity, value: 1000 * 60 * 60 * 24 * 365, name: 'year' }
    ];

    let value = elapsed;
    let unit = 'second';

    for (const { max, value: unitValue, name } of units) {
      if (Math.abs(elapsed) < unitValue * max) {
        value = Math.round(elapsed / unitValue);
        unit = name;
        break;
      }
    }

    const rtf = new Intl.RelativeTimeFormat(lang, { numeric: 'auto' });
    return rtf.format(value, unit);
  };

  const getHumanizedDate = (datetime: string, lang: string) => {
    const _datetime = new Date(datetime);
    return getRelativeTime(_datetime, lang);
  };

  return { state: {}, funcs: { getHumanizedDate, formatNumber } };
};
