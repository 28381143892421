import {
  CommunicationBasics,
  EntertainmentAndLeisure,
  FeelingsAndEmotions,
  FoodAndDining,
  HealthAndWellBeing,
  MainCategories,
  NubmersAndQuantity,
  SocialContexts,
  TimeAndDates,
  TravelAndDirections
} from '@shared/openapi/jaaluu-api';

export const CATEGORIES_PT = {
  [MainCategories.COMMUNICATION_BASICS]: {
    title: 'Comunicação',
    categories: {
      [CommunicationBasics.GREETINGS]: 'Saudações',
      [CommunicationBasics.QUESTIONS]: 'Perguntas',
      [CommunicationBasics.EXPRESSIONS]: 'Expressões'
    }
  },
  [MainCategories.FEELINGS_AND_EMOTIONS]: {
    title: 'Sentimentos e Emoções',
    categories: {
      [FeelingsAndEmotions.POSITIVE_EMOTIONS]: 'Emoções positivas',
      [FeelingsAndEmotions.NEGATIVE_EMOTIONS]: 'Emoções negativas',
      [FeelingsAndEmotions.NEUTRAL_OR_MIXED_FEELINGS]: 'Sentimentos neutros ou mistos'
    }
  },
  [MainCategories.TRAVEL_AND_DIRECTIONS]: {
    title: 'Viagens e Direções',
    categories: {
      [TravelAndDirections.DIRECTIONS]: 'Direções',
      [TravelAndDirections.LOCATIONS]: 'Locais',
      [TravelAndDirections.TRANSPORTATION]: 'Transporte',
      [TravelAndDirections.ACCOMODATION]: 'Alojamento'
    }
  },
  [MainCategories.NUMBERS_AND_QUANTITY]: {
    title: 'Números e Quantidade',
    categories: {
      [NubmersAndQuantity.NUMBERS]: 'Números',
      [NubmersAndQuantity.QUANTITY]: 'Quantidade'
    }
  },
  [MainCategories.TIME_AND_DATES]: {
    title: 'Horas e Datas',
    categories: {
      [TimeAndDates.TIME]: 'Hora',
      [TimeAndDates.DATES]: 'Datas'
    }
  },
  [MainCategories.ENTERTAINMENT_AND_LEISURE]: {
    title: 'Entretenimento e Lazer',
    categories: {
      [EntertainmentAndLeisure.ENTERTAINMENT]: 'Entretenimento',
      [EntertainmentAndLeisure.LEISURE]: 'Lazer'
    }
  },
  [MainCategories.HEALTH_AND_WELL_BEING]: {
    title: 'Saúde e Bem-estar',
    categories: {
      [HealthAndWellBeing.HEALTH]: 'Saúde',
      [HealthAndWellBeing.WELL_BEING]: 'Bem-estar'
    }
  },
  [MainCategories.SOCIAL_CONTEXTS]: {
    title: 'Contextos Sociais',
    categories: {
      [SocialContexts.SOCIAL]: 'Social',
      [SocialContexts.CULTURAL]: 'Cultural',
      [SocialContexts.RELIGIOUS]: 'Religioso',
      [SocialContexts.POLITICAL]: 'Político'
    }
  },
  [MainCategories.FOOD_AND_DINING]: {
    title: 'Comida e Refeições',
    categories: {
      [FoodAndDining.FOOD]: 'Comida',
      [FoodAndDining.DRINKS]: 'Bebidas',
      [FoodAndDining.DINING]: 'Refeições'
    }
  }
};
