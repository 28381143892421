import {
  CommunicationBasics,
  EntertainmentAndLeisure,
  FeelingsAndEmotions,
  FoodAndDining,
  HealthAndWellBeing,
  MainCategories,
  NubmersAndQuantity,
  SocialContexts,
  TimeAndDates,
  TravelAndDirections
} from '@shared/openapi/jaaluu-api';

export const CATEGORIES_ES = {
  [MainCategories.COMMUNICATION_BASICS]: {
    title: 'Comunicación',
    categories: {
      [CommunicationBasics.GREETINGS]: 'Saludos',
      [CommunicationBasics.QUESTIONS]: 'Preguntas',
      [CommunicationBasics.EXPRESSIONS]: 'Expresiones'
    }
  },
  [MainCategories.FEELINGS_AND_EMOTIONS]: {
    title: 'Sentimientos y Emociones',
    categories: {
      [FeelingsAndEmotions.POSITIVE_EMOTIONS]: 'Emociones Positivas',
      [FeelingsAndEmotions.NEGATIVE_EMOTIONS]: 'Emociones Negativas',
      [FeelingsAndEmotions.NEUTRAL_OR_MIXED_FEELINGS]: 'Sentimientos Neutrales o Mixtos'
    }
  },
  [MainCategories.TRAVEL_AND_DIRECTIONS]: {
    title: 'Viajes y Direcciones',
    categories: {
      [TravelAndDirections.DIRECTIONS]: 'Direcciones',
      [TravelAndDirections.LOCATIONS]: 'Ubicaciones',
      [TravelAndDirections.TRANSPORTATION]: 'Transporte',
      [TravelAndDirections.ACCOMODATION]: 'Alojamiento'
    }
  },
  [MainCategories.NUMBERS_AND_QUANTITY]: {
    title: 'Números y Cantidad',
    categories: {
      [NubmersAndQuantity.NUMBERS]: 'Números',
      [NubmersAndQuantity.QUANTITY]: 'Cantidad'
    }
  },
  [MainCategories.TIME_AND_DATES]: {
    title: 'Tiempo y Fechas',
    categories: {
      [TimeAndDates.TIME]: 'Tiempo',
      [TimeAndDates.DATES]: 'Fechas'
    }
  },
  [MainCategories.ENTERTAINMENT_AND_LEISURE]: {
    title: 'Entretenimiento y Ocio',
    categories: {
      [EntertainmentAndLeisure.ENTERTAINMENT]: 'Entretenimiento',
      [EntertainmentAndLeisure.LEISURE]: 'Ocio'
    }
  },
  [MainCategories.HEALTH_AND_WELL_BEING]: {
    title: 'Salud y Bienestar',
    categories: {
      [HealthAndWellBeing.HEALTH]: 'Salud',
      [HealthAndWellBeing.WELL_BEING]: 'Bienestar'
    }
  },
  [MainCategories.SOCIAL_CONTEXTS]: {
    title: 'Contextos Sociales',
    categories: {
      [SocialContexts.SOCIAL]: 'Social',
      [SocialContexts.CULTURAL]: 'Cultural',
      [SocialContexts.RELIGIOUS]: 'Religioso',
      [SocialContexts.POLITICAL]: 'Político'
    }
  },
  [MainCategories.FOOD_AND_DINING]: {
    title: 'Comida y Comedor',
    categories: {
      [FoodAndDining.FOOD]: 'Comida',
      [FoodAndDining.DRINKS]: 'Bebidas',
      [FoodAndDining.DINING]: 'Comedor'
    }
  }
};
