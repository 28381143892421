export default {
  welcome: {
    your_language: 'Seu idioma',
    language_to_learn: 'Idioma que você quer aprender',
    start_learning: 'Começar a aprender',
    how_does_this_work: 'Como isso funciona?',
    welcome_1: 'Bem-vindo ao JAALUU',
    welcome_2: 'Aqui você pode aprender idiomas',
    welcome_3: 'Leia textos ou assista a vídeos com legendas',
    welcome_4: 'Clique em uma palavra para ver traduções, exemplos e definições',
    main_teaser: 'Melhore suas habilidades linguísticas - de forma fácil e gratuita',
    select_language_and_start: 'Selecione o idioma e comece',
    how_to_title: 'Leia textos, livros, notícias ou assista a vídeos',
    how_to_text: 'Você pode escolher entre uma variedade de textos, livros, notícias ou vídeos',
    click_on_word_title: 'Tradução automática, exemplos e definições',
    click_on_word_text:
      'Clique em qualquer palavra para tradução automática, frases de exemplo e definições. Você também pode traduzir sentenças inteiras automaticamente ou vincular palavras com imagens e criar redes de palavras.',
    listen_title: 'Ouça palavras e textos',
    listen_text:
      'Você pode ouvir qualquer palavra, sentença ou textos inteiros e praticar a pronúncia',
    conversations_title: 'Pratique conversas',
    conversations_text:
      "Você pode praticar conversas conversando com um chatbot virtual. Você precisa resolver tarefas como: 'Pedir comida em um restaurante' ou 'Pedir direções.'",
    writing_title: 'Ortografia',
    writing_text_1: 'Pratique a ortografia escrevendo textos',
    writing_text_2:
      'Seus textos serão verificados quanto a erros ortográficos, conteúdo e gramática.',
    texts_and_videos_title: 'Encontre seu nível',
    texts_and_videos_text:
      'Você pode escolher entre frases simples e textos para crianças, notícias, artigos da Wikipedia ou livros, o que melhor se adequar a você',
    srs_title: 'Repita e treine vocabulário',
    srs_text_1:
      'Você pode salvar qualquer palavra ou sentenças inteiras como favoritas para repetir depois',
    srs_text_2:
      'Treine seu vocabulário com flashcards e exercícios simples, e acompanhe seu progresso',
    srs_text_3:
      'O sistema de repetição SRS ajuda você a aprender de forma mais eficaz, repetindo palavras no momento certo',
    own_text_title: 'Vocabulário especializado',
    own_text_text:
      'Você pode criar textos sobre um tópico específico para aprender vocabulário especializado. Você também pode criar textos contendo palavras que deseja aprender.',
    wordmap_title: 'Redes de palavras',
    wordmap_text_1: 'Você pode criar redes de palavras e vincular palavras com frases simples',
    wordmap_text_2:
      'Os cartões de palavras podem ser salvos como favoritos, permitindo repetição e treinamento fáceis',
    sayings_title: 'Expressões idiomáticas',
    sayings_text:
      'Aqui você encontrará frases e expressões comuns, categorizadas. Você pode acompanhar seu progresso e continuar a se desenvolver de forma estruturada.',
    details: 'Detalhes',
    details_text_and_videos_title:
      'Descubra Jaaluu – Sua chave para um aprendizado rápido e eficaz de idiomas!',
    details_text_and_videos_text:
      'Jaaluu oferece uma abundância de textos e vídeos para melhorar seu nível de idioma. A plataforma é ideal se você já consegue entender palavras e frases simples. Para iniciantes, há frases simples e expressões idiomáticas, textos para crianças, dicionários e vídeos para começar com facilidade. Para aprendizes avançados, há notícias, artigos da Wikipedia, livros e vídeos interessantes para um entendimento mais profundo, ou conversas interativas com chatbots. Além disso, você pode treinar sua ortografia e receber dicas para melhorar erros ortográficos, conteúdo e gramática.',
    details_click_on_words_title:
      'Aprendizado de vocabulário facilitado: Rápido, interativo e sustentável!',
    details_click_on_words_text:
      'Aprender vocabulário pode ser tedioso porque procurar palavras e definições toma muito tempo. O Jaaluu resolve esse problema: basta clicar em uma palavra para obter instantaneamente traduções, frases de exemplo, definições e explicações. Isso economiza tempo e ajuda você a aprender mais rápido e de forma mais eficaz!',
    details_listen_title:
      'Ouça e pratique: Ouça palavras, frases e textos para melhorar suas habilidades linguísticas!',
    details_listen_text:
      'Você pode ouvir tudo – qualquer palavra, qualquer frase ou até mesmo textos inteiros. Basta reproduzir e ouvir! Esse recurso ajuda você a praticar a pronúncia correta e melhorar a compreensão auditiva. Você também pode ouvir conversas e desenvolver um senso de comunicação natural. Perfeito para internalizar vocabulário e fortalecer suas habilidades linguísticas em situações do dia a dia.',
    details_conversations_title:
      'Treinamento linguístico prático: Conversas realistas para o dia a dia!',
    details_conversations_text:
      'Com Jaaluu, você pode praticar conversas realistas com um chatbot e se preparar para situações típicas do dia a dia. Por exemplo, você pode pedir comida em um restaurante e pagar a conta ou pedir direções. Ou, você pode conversar com seu novo colega no seu primeiro dia de trabalho. Suas frases serão automaticamente analisadas para fornecer feedback direto e melhorar suas habilidades linguísticas. Dessa forma, você treina de maneira prática e eficaz para conversas reais!',
    details_writing_title:
      'Prática de escrita para o dia a dia: Melhore suas habilidades com tarefas realistas!',
    details_writing_text:
      'Você pode praticar a escrita dominando pequenas tarefas realistas, como escrever cartas de candidatura ou reclamação. Seu texto será automaticamente analisado e você receberá feedback direto sobre ortografia, conteúdo e gramática. Dessa forma, você melhora suas habilidades de escrita e se prepara bem para o dia a dia!',
    details_srs_title:
      'Aprendizado eficaz de vocabulário com SRS: Repetições automáticas e análise personalizada de progresso!',
    details_srs_text:
      'Com o sistema SRS (Sistema de Repetição Espacial), você pode aprender e repetir vocabulário de forma eficaz. Este sistema te lembra quando repetir palavras para retê-las a longo prazo. O SRS é baseado no método científico de que repetir em intervalos certos fortalece a memória. Você também receberá estatísticas sobre o progresso de aprendizado de cada palavra e sua taxa de sucesso, para que possa focar em suas fraquezas e sempre saber como está seu progresso no aprendizado de vocabulário.',
    details_own_text_title:
      'Aprendizado personalizado: Crie textos personalizados para vocabulário direcionado e exercícios sobre tópicos!',
    details_own_text_text:
      'Com Jaaluu, você pode facilmente criar seus próprios textos nas áreas em que deseja se concentrar. Descreva a área ou o vocabulário que você deseja aprender, e você receberá um texto para ler e praticar. Dessa forma, você pode se concentrar em tópicos ou vocabulário específicos e expandir seu conhecimento na área que mais importa para você. Essa abordagem personalizada ajuda você a aprender de forma mais eficaz e precisa! Além disso, você pode criar textos que contenham todo o vocabulário que deseja revisar. Isso permite que você aprenda e revise vocabulário no contexto, tornando mais fácil de lembrar.',
    details_wordmap_title:
      'Cartões de palavras: Conecte seu conhecimento e lembre-se de palavras com facilidade!',
    details_wordmap_text:
      'Você pode criar cartões de palavras que ajudam a vincular palavras e lembrar delas de forma mais eficaz. Adicione palavras e frases de forma interativa ao seu cartão de palavras e expanda seu vocabulário gradualmente. Isso cria e fortalece conexões no seu cérebro e constrói associações de forma lúdica. Dessa forma, você aprimora sua compreensão linguística de maneira sustentável!',
    details_paste_text_title: 'Use seus próprios textos: Cole seu conteúdo para aprender com foco!',
    details_paste_text_text:
      'Com Jaaluu, você também pode colar seus próprios textos para usá-los na prática. Isso permite que você trabalhe com seu próprio material e treine seu vocabulário em contextos da vida real.',
    site_also_available: 'O site também está disponível em',
    which_languages_title: 'Quais idiomas posso aprender?',
    which_languages_text: 'Aqui você pode aprender inglês, espanhol, alemão, francês, russo, polonês e italiano. Basta selecionar o idioma que deseja aprender e pronto!'
  },
  intro: {
    your_level: 'Qual é o seu nível de idioma?',
    beginner_explained: 'Você consegue ler frases simples',
    advanced_explained: 'Você consegue ler textos mais complexos',
    your_cefr_level: 'O seu nível CEFR é',
    lets_start: 'Com o que você gostaria de começar?',
    beginner_options: {
      simple_texts: 'Ler textos',
      simple_articles: 'Ler artigos',
      sayings: 'Aprender expressões',
      view_videos: 'Assistir a vídeos',
      simple_phrases: 'Ler frases típicas'
    },
    advanced_options: {
      news: 'Ler notícias',
      videos: 'Assistir a vídeos',
      wikipedia: 'Ler artigos',
      books: 'Ler livros'
    }
  },
  languages: {
    de: 'Alemão',
    en: 'Inglês',
    es: 'Espanhol',
    fr: 'Francês',
    it: 'Italiano',
    pt: 'Português',
    ru: 'Russo',
    zh: 'Chinês',
    pl: 'Polonês'
  },
  general: {
    beginner: 'Iniciante',
    advanced: 'Avançado',
    search: 'Pesquisar',
    search_imperative: 'Pesquisar',
    translate: 'Traduzir',
    settings: 'Configurações',
    vocabulary: 'Vocabulário',
    shorts: 'Textos curtos',
    articles: 'Artigos',
    feeds: 'Notícias',
    videos: 'Vídeos',
    read: 'Ler',
    view: 'Visualizar',
    listen: 'Ouvir',
    save: 'Salvar',
    more: 'mais',
    back: 'Voltar',
    next: 'Próximo',
    easy: 'Fácil',
    medium: 'Médio',
    hard: 'Difícil',
    all: 'Todos',
    level: 'Nível',
    font_size: 'Tamanho da fonte',
    language: 'Idioma',
    send: 'Enviar',
    image: 'Imagem',
    wordmap: 'Mapa de palavras',
    open: 'Abrir',
    add: 'Adicionar',
    question: 'Pergunta',
    start: 'Começar',
    words: 'Palavra | Palavras'
  },
  app_bar: {
    search_in_foreign_language: 'Pesquisar em idioma estrangeiro',
    menu: 'Menu'
  },
  category: {
    chapter: 'Capítulo'
  },
  favorites: {
    favorites: 'Favoritos',
    word: 'Palavra',
    sentence: 'Frase',
    sentences: 'Frases',
    texts_videos: 'Textos e Vídeos',
    wordmaps: 'Mapas de palavras',
    saved: 'Salvo',
    title: 'Título',
    type: 'Tipo',
    no_favorites_found: 'Nenhum favorito encontrado',
    filtered_by: 'filtrado por',
    train: 'Treinar',
    notify_me_srs: 'Notifique-me para repetir o vocabulário',
    srs_level: 'Nível SRS',
    success_rate: 'Taxa de sucesso',
    last_seen: 'Última vez visto',
    next_review: 'Próxima revisão',
    success_level_up: 'Palavra movida para o nível {level}',
    what_is_srs: 'O que é SRS?',
    srs_short:
      'SRS é um sistema de repetição espaçada. Ele ajuda você a lembrar palavras melhor, repetindo-as no momento certo.',
    srs_long:
      'O sistema é baseado no princípio de que quanto mais você repete uma palavra, melhor você a lembra. Os intervalos entre as repetições são determinados pelo sistema. Se você lembra bem de uma palavra, os intervalos se tornam mais longos. Se você tiver dificuldades, os intervalos se tornam mais curtos. Dessa forma, você pode aprender palavras de maneira mais eficiente.',
    srs_intervals: 'Os {nIntervals} intervalos são: {intervals} dias.',
    when_does_a_word_move: 'Quando uma palavra se move para o próximo nível?',
    srs_next_level_example_1:
      'Se você adicionar uma palavra aos favoritos, ela estará no nível 1. Uma palavra no nível 1 passará para o próximo nível se 1 dia se passar desde que você adicionou a palavra, sua taxa de sucesso for superior a 50% e você acabou de lembrar a palavra corretamente.',
    srs_next_level_example_2:
      'Uma palavra no nível {level} passará para o próximo nível após a última mudança para o nível {levelM1} ter ocorrido há pelo menos {days} dias, sua taxa de sucesso for superior a 50% e você acabou de lembrar a palavra corretamente.'
  },
  player: {
    maximize: 'Maximizar',
    minimize: 'Minimizar'
  },
  video: {
    error:
      'Ocorreu um erro ao carregar as legendas. Infelizmente, nem todas as legendas estão disponíveis gratuitamente. Por favor, tente outro vídeo.'
  },
  feeds: {
    disclaimer:
      'Este é um resumo gerado automaticamente pelo ChatGPT. O artigo original está disponível',
    here: 'aqui',
    generating_article: 'Gerando artigo...',
    error:
      'Ocorreu um erro ao carregar o artigo. Infelizmente, nem todos os artigos estão disponíveis gratuitamente. Por favor, tente outro artigo ou copie o texto manualmente',
    open_org: '1. Abra o artigo original e copie o texto',
    open_own: '2. Cole o texto aqui'
  },
  books: {
    read_now: 'Ler agora',
    continue_on_page: 'Continuar na página'
  },
  tooltip: {
    examples: 'Exemplos',
    emotional_examples: 'Exemplos emocionais',
    definition: 'Definição',
    synonyms: 'Sinônimos',
    antonyms: 'Antônimos',
    article: 'Artigo',
    conjugations: 'Conjugação',
    grammer: 'Gramática'
  },
  custom_text: {
    enter_your_own_text: 'Digite seu próprio texto',
    read_text: 'Ler texto',
    enter_new_text: 'Digite um novo texto',
    own_text: 'Copie seu próprio texto e cole-o aqui',
    special_vocabulary: 'Vocabulário especial'
  },
  special_vocabulary: {
    special_vocabulary: 'Vocabulário especial',
    describe:
      'Digite um texto em inglês sobre um tópico ou vocabulário especial que você deseja aprender',
    language_level: 'Nível de linguagem',
    generate_text: 'Gerar texto'
  },
  intro_message: {
    save_word_as_fav: 'Salve a palavra como favorita',
    save_video_as_fav: 'Salve o vídeo como favorito',
    save_article_as_fav: 'Salve o artigo como favorito',
    save_book_as_fav: 'Salve o livro como favorito',
    save_wordmap_as_fav: 'Salve o mapa de palavras como favorito',
    play_word: 'Ouça a pronúncia da palavra',
    play_article: 'Ouça o artigo',
    play_book: 'Ouça o livro',
    select_main_category: 'Selecione uma categoria principal',
    select_sub_category: 'Selecione uma subcategoria',
    click_on_read: 'Clique em `LER` para ler o texto completo',
    click_on_view: 'Clique em `ASSISTIR` para assistir a um vídeo',
    last_book_page: 'Sua última página será salva automaticamente e exibida aqui',
    wordmap_click_on_word: 'Clique na palavra para ver as palavras mais usadas',
    wordmap_click_on_line: 'Clique em uma linha entre duas palavras para ver uma frase',
    category_progress: 'Acompanhe seu progresso marcando as caixas de seleção',
    conversations_select_scene: 'Selecione um exercício',
    conversations_warn: 'Parece que há um erro na sua frase. Clique no triângulo para mais explicações.',
    conversations_task: 'Formule uma frase de acordo com a instrução no final do chat',
  },
  tour_guide: {
    click_on_every_word: {
      title: 'Traduzir palavra',
      text: 'Você pode clicar em qualquer palavra para ver traduções, exemplos de frases e definições'
    },
    click_on_i_for_sentence: {
      title: 'Frase completa',
      text: 'Para traduzir uma frase inteira, clique no símbolo i. Aqui você também pode obter explicações sobre gramática'
    },
    no_save_custom: {
      title: 'Sem salvar',
      text: 'Textos personalizados não podem ser salvos'
    },
    change_playback_rate: {
      title: 'Alterar Velocidade de Reprodução',
      text: 'Altere a velocidade de reprodução em Menu/Configurações/Áudio e Vídeo.'
    },
    theme: {
      title: 'Cores',
      text: 'Você pode mudar as cores no menu para o modo escuro'
    },
    explain_menu: {
      title: 'Menu',
      text: 'Os itens do menu estão classificados de muito difícil a fácil de cima para baixo'
    },
    repeat_words: {
      title: 'Repetir Vocabulário',
      text: 'Você deve repetir {nWords} vocabulários.',
      call_to_action: 'Aprenda agora'
    }
  },
  chat: {
    your_message: 'Sua mensagem',
    in_de: 'em alemão',
    in_es: 'em espanhol',
    in_fr: 'em francês',
    in_it: 'em italiano',
    in_pt: 'em português',
    in_ru: 'em russo',
    in_zh: 'em chinês',
    in_pl: 'em polonês',
    wait: 'Por favor, aguarde...',
    speak_now: 'Fale agora',
    new_chat: 'Novo chat'
  },
  langs: {
    de: 'Alemão',
    en: 'Inglês',
    sp: 'Espanhol',
    fr: 'Francês',
    it: 'Italiano',
    pt: 'Português',
    pl: 'Polonês',
    ru: 'Russo',
    zh: 'Chinês'
  },
  about: {
    how_does_it_work: 'Como isso funciona?',
    here_you_can: 'Aqui você pode',
    read_texts: 'Ler textos',
    watch_videos: 'Assistir a vídeos',
    click_on_every_wrord_for: 'Clique em qualquer palavra para',
    translations: 'traduções',
    examples: 'exemplos',
    definitions: 'definições',
    synonyms: 'sinônimos',
    copy_your_own_text: 'Copiar e ler seu próprio texto',
    chat_with_chatbot: 'Conversar com um chatbot',
    level: 'Seu nível deve estar entre B1 e C2'
  },
  settings: {
    audio_video: 'Áudio e Vídeo',
    colors: 'Cores',
    dark_mode: 'Modo escuro',
    lang_level: 'Nível de idioma'
  },
  error: {
    resource_not_found: 'Desculpe, não conseguimos encontrar o que você está procurando'
  },
  train: {
    check: 'Verificar',
    flashcards: 'Cartões de memória',
    exercises: 'Exercícios',
    finished: 'Exercícios concluídos',
    go_to_favorites: 'Ir para favoritos',
    restart_exercise: 'Reiniciar exercício',
    go_to_home: 'Ir para a página inicial',
    text_with_words: 'Texto com palavras',
    text_includes: 'O texto inclui as seguintes palavras'
  },
  wordmap: {
    click_on_circle: 'Clique no círculo para ver as palavras mais frequentemente usadas com {word}',
    click_on_edge: 'Clique na linha entre duas palavras para ver uma frase com ambas as palavras',
    open_fullscreen: 'Abrir em tela cheia',
    add_own_word: 'Adicionar palavra própria',
    add_sentence_with_all_words: 'Adicionar frase com todas as palavras',
    add_to_word: 'Adicionar à palavra'
  },
  conversations: {
    you_are: 'Você é',
    you_talk_to: 'Você fala com',
    your_tasks: 'Suas tarefas',
  },
  write: {
    min_words: 'Palavras mínimas',
    your_email_needs_to_contain: 'Seu e-mail deve conter',
    rate: 'Avaliação',
    analysis: 'Análise',
    overall: 'Geral',
    grammar: 'Gramática',
    content: 'Conteúdo',
    spelling: 'Ortografia'
  }
};
