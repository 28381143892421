<script lang="ts" setup>
import stores from '@jaaluu/stores';
import { t } from '@shared/i18n';
import { LangVoiceType } from '@shared/openapi/jaaluu-api';
import { PropType } from 'vue';
import { onMounted, onUnmounted, ref, Ref, watch } from 'vue';

const emit = defineEmits(['play-all-finished', 'audio-started']);

const { playbackRate } = stores.settings.state;
const { langForeign } = stores.language.state;
const { activeText } = stores.text.state;
const allSentences: Ref<string[]> = ref([]);
const currentSentence = ref(0);
const audio = new Audio();
const playedCount = ref(0);
const audioIsPlaying = ref(false);
const STICKY_DIV = `sticky-${Math.random().toString(36).substring(7)}`;
const STICKY_WRAPPER_DIV = `sticky-wrapper-${Math.random().toString(36).substring(7)}`;
const { splitBySentences } = stores.extractor.funcs;
const { getAudioFileUrl } = stores.files.funcs;
const { initChangePlaybackRate } = stores.tourGuide.funcs;

const props = defineProps({
  sentencesList: {
    required: true,
    type: Array<string>
  },
  showListenText: {
    required: false,
    type: Boolean,
    default: true
  },
  isSticky: {
    required: false,
    type: Boolean,
    default: false
  },
  loop: {
    required: false,
    type: Boolean,
    default: false
  },
  voiceType: {
    type: String as PropType<LangVoiceType>,
    required: false,
    default: LangVoiceType.FEMALE
  }
});

onMounted(() => {
  setAllSentences();
  observeStickyDiv();
});

onUnmounted(() => {
  stopAudio();
});

const playAudio = (text: string) => {
  emit('audio-started');
  activeText.value = text;
  return new Promise<void>((resolve) => {
    if (!langForeign.value) return;
    const src = getAudioFileUrl(text, langForeign.value, props.voiceType)
    if (!src) return;
    audio.src = src;
    playedCount.value += 1;
    if (playedCount.value > 3) {
      initChangePlaybackRate();
    }
    audio.onended = () => {
      activeText.value = '';
      setTimeout(() => {
        resolve();
      }, 100);
    };
    audio.onplay = () => {
      audioIsPlaying.value = true;
    };
    audio.playbackRate = playbackRate.value;
    audio.play();
  });
};

const toogleAudioLoop = () => {
  if (audioIsPlaying.value) {
    stopAudio();
  } else {
    audioIsPlaying.value = true;
    playAudioLoop();
  }
};

const stopAudio = () => {
  audioIsPlaying.value = false;
  audio.pause();
};

const playAudioLoop = async () => {
  if (currentSentence.value >= allSentences.value.length) {
    currentSentence.value = 0;
    audioIsPlaying.value = false;
    emit('play-all-finished');
    setTimeout(() => {
      if (currentSentence.value === 0 && allSentences.value.length > 1 && props.loop) {
        playAudioLoop();
      }
    }, 1000);
    return;
  }
  await playAudio(allSentences.value[currentSentence.value]);
  currentSentence.value += 1;
  if (!audioIsPlaying.value) return;
  await playAudioLoop();
};

const setAllSentences = async () => {
  const _allSentences: string[] = [];
  for (let i = 0; i < props.sentencesList.length; i++) {
    const text = props.sentencesList[i];
    if (!text) return;
    const sentences = await splitBySentences(text, langForeign.value);
    for (let i = 0; i < sentences.length; i++) {
      const sentence = sentences[i];
      _allSentences.push(sentence.map((w) => w.text).join(' '));
    }
  }
  allSentences.value = _allSentences;
  currentSentence.value = 0;
};

const observeStickyDiv = () => {
  if (!props.isSticky) return;
  const stickyWrapperDiv = document.getElementById(STICKY_WRAPPER_DIV);
  window.addEventListener('scroll', () => {
    const dividerHomes = document.getElementsByClassName('divider-home');
    let articleOutOfView = false;
    for (let i = 0; i < dividerHomes.length; i++) {
      if (dividerHomes[i].getBoundingClientRect().top < 100) {
        articleOutOfView = true;
      }
    }
    if (!stickyWrapperDiv) return;
    const stickyDiv = document.getElementById(STICKY_DIV);
    if (!stickyDiv) return;
    if (stickyWrapperDiv.getBoundingClientRect().top < 70 && !articleOutOfView) {
      stickyDiv.classList.add('sticky');
    } else {
      stickyDiv.classList.remove('sticky');
    }
  });
};

watch(
  () => props.sentencesList,
  () => {
    setAllSentences();
  }
);

watch(
  () => playbackRate.value,
  () => {
    audio.playbackRate = playbackRate.value;
  }
);
</script>
<template>
  <div class="keep-height">
    <div :id="STICKY_WRAPPER_DIV"></div>
    <div class="d-flex align-center" :id="STICKY_DIV">
      <v-btn class="small-button" variant="text" size="x-small" @click="() => toogleAudioLoop()">
        <div v-if="showListenText" class="d-flex align-center mr-1">
          <v-icon v-if="audioIsPlaying" size="16" icon="mdi-pause" class="mr-1" />
          <v-icon v-else="showListenText" size="14" icon="mdi-headphones" class="mr-1" />
          <span>{{ t('general.listen') }}</span>
        </div>
        <v-progress-circular
          v-if="audioIsPlaying && showListenText"
          indeterminate
          class="ml-2 mr-1"
          width="1"
          size="12"
        ></v-progress-circular>
        <v-icon size="16" v-else-if="!audioIsPlaying" icon="mdi-play" />
        <v-icon v-else size="16" icon="mdi-pause" />
      </v-btn>
    </div>
  </div>
</template>
<style scoped lang="scss">
.sticky {
  position: fixed;
  background-color: rgb(var(--v-theme-background-darken-1));
  padding: 2px;
  border-radius: 0 0 70px 70px;
  padding: 5px 20px;
  top: 60px;
  z-index: $z-level-1;
  left: 50%;
  transform: translateX(-50%);
}
.keep-height {
  height: 20px;
}
.speed {
  width: 100px;
}
.small-button {
  min-width: 20px;
  padding: 0px;
}
</style>
