export default {
  welcome: {
    your_language: 'Your language',
    language_to_learn: 'Language you want to learn',
    start_learning: 'Start learning',
    how_does_this_work: 'How does this work?',
    welcome_1: 'Welcome to JAALUU',
    welcome_2: 'Here you can learn languages',
    welcome_3: 'Read texts or view videos with subtitles',
    welcome_4: 'Click on a word for translations, examples and definitions',
    main_teaser: 'Improve your language skills - easily and for free',
    select_language_and_start: 'Select language and start',
    how_to_title: 'Read texts, books, news, or watch videos',
    how_to_text: 'You can choose from a variety of texts, books, news, or videos',
    click_on_word_title: 'Automatic translation, examples, and definitions',
    click_on_word_text:
      'Click on any word for automatic translation, example sentences, and definitions. You can also translate whole sentences automatically or link words with images and create word networks.',
    listen_title: 'Listen to words and texts',
    listen_text: 'You can listen to any word, sentence, or entire texts and practice pronunciation',
    conversations_title: 'Practice conversations',
    conversations_text:
      'You can practice conversations by talking to a virtual chatbot. You need to solve tasks like: `Order food in a restaurant` or `Ask for directions.`',
    writing_title: 'Spelling',
    writing_text_1: 'Practice spelling by writing texts',
    writing_text_2: 'Your texts will be checked for spelling mistakes, content, and grammar.',
    texts_and_videos_title: 'Find your level',
    texts_and_videos_text:
      'You can choose between simple phrases and texts for children, news, Wikipedia articles, or books, whatever suits you best',
    srs_title: 'Repeat and train vocabulary',
    srs_text_1: 'You can save any word or entire sentences as favorites to repeat later',
    srs_text_2:
      'Train your vocabulary with flashcards and simple exercises, and track your progress',
    srs_text_3:
      'The SRS repetition system helps you learn more effectively by repeating words at the right time',
    own_text_title: 'Specialized vocabulary',
    own_text_text:
      'You can create text on a specific topic to learn special vocabulary. You can also create texts containing words you want to learn.',
    wordmap_title: 'Word networks',
    wordmap_text_1: 'You can create word networks and link words with simple sentences',
    wordmap_text_2:
      'Word cards can be saved as favorites, allowing for easy repetition and training',
    sayings_title: 'Idioms',
    sayings_text:
      'Here you will find commonly used phrases and idioms, categorized. You can track your progress and continue to develop in a structured way.',
    details: 'Details',
    details_text_and_videos_title:
      'Discover Jaaluu – Your key to fast and effective language learning!',
    details_text_and_videos_text:
      'Jaaluu offers you a wealth of texts and videos to improve your language level. The platform is ideal if you can already understand simple words and sentences. For beginners, there are simple phrases and idioms, texts for children, lexicons, and videos for an easy start. For advanced learners, there are news, Wikipedia articles, books, and exciting videos for deeper understanding, or interactive chatbot conversations. Additionally, you can train your spelling and get tips to improve spelling mistakes, content, and grammar.',
    details_click_on_words_title:
      'Learning vocabulary made easy: Fast, interactive, and sustainable!',
    details_click_on_words_text:
      'Learning vocabulary can be tedious because looking up words and definitions takes a lot of time. Jaaluu solves this problem: Just click on a word to instantly get translations, example sentences, definitions, and explanations. This saves time and helps you learn faster and more effectively!',
    details_listen_title:
      'Listen and practice: Hear words, sentences, and texts to improve your language skills!',
    details_listen_text:
      'You can hear everything – any word, any sentence, or even entire texts. Just play and listen! This feature helps you practice correct pronunciation and improve listening comprehension. You can also listen to conversations and develop a feel for natural communication. Perfect for internalizing vocabulary and strengthening your language skills in everyday situations.',
    details_conversations_title:
      'Practical language training: Realistic conversations for everyday life!',
    details_conversations_text:
      'With Jaaluu, you can practice realistic conversations with a chatbot and prepare for typical everyday situations. For example, you can order food in a restaurant and pay the bill or ask for directions. Or, you can chat with your new colleague on your first day in a new job. Your sentences will be automatically analyzed to give you direct feedback and improve your language skills. This way, you train in a practical and effective manner for real conversations!',
    details_writing_title:
      'Writing practice for everyday life: Improve your skills with realistic tasks!',
    details_writing_text:
      'You can practice writing by mastering small, realistic tasks, such as writing applications or complaint letters. Your text will then be automatically analyzed, and you will receive direct feedback on spelling, content, and grammar. This way, you improve your writing skills and are well-prepared for everyday life!',
    details_srs_title:
      'Effective vocabulary learning with SRS: Automatic repetitions and personalized progress analysis!',
    details_srs_text:
      'With the SRS system (Spaced Repetition System), you can learn and repeat vocabulary effectively. This system reminds you when to repeat words in order to retain them long-term. SRS is based on the scientific method that repeating at certain intervals strengthens memory. You will also receive statistics on the learning progress of each word and your success rate, so you can focus on your weaknesses and always know how well you are progressing in vocabulary learning.',
    details_own_text_title:
      'Personalized learning: Create custom texts for targeted vocabulary and topic exercises!',
    details_own_text_text:
      "With Jaaluu, you can easily create your own texts in areas you want to focus on. Describe the area or vocabulary you want to learn, and you'll get a text to read and practice. This way, you can concentrate on specific topics or vocabulary and expand your knowledge in the area that matters to you. This tailored approach helps you learn more effectively and precisely! Additionally, you can create texts that contain all the vocabulary you want to review. This allows you to learn and review vocabulary in context, making it easier to remember.",
    details_wordmap_title: 'Word cards: Connect your knowledge and remember words with ease!',
    details_wordmap_text:
      'You can create word cards that help you network words and remember them more effectively. Add words and sentences interactively to your word card and gradually expand your vocabulary. This creates and strengthens connections in your brain and builds associations playfully. This way, you enhance your language understanding – sustainably!',
    details_paste_text_title: 'Use your own texts: Paste your content to learn with focus!',
    details_paste_text_text:
      'With Jaaluu, you can also paste your own texts to use them for practice. This allows you to work with your own material and train your vocabulary in real-life contexts.',
    site_also_available: 'The site is also available in',
    which_languages_title: 'Which languages can I learn?',
    which_languages_text:
      'Here you can learn Spanish, French, Italian, German, Portuguese, Russian, and Polish. Simply select the language you want to learn and you´re ready to go!'
  },
  intro: {
    your_level: 'Your language level?',
    beginner_explained: 'You can read simple sentences',
    advanced_explained: 'You can read more complex texts',
    your_cefr_level: 'Your CEFR level is',
    lets_start: 'Lets start with?',
    beginner_options: {
      simple_texts: 'Read texts',
      simple_articles: 'Read articles',
      sayings: 'Learn sayings',
      view_videos: 'Watch videos',
      simple_phrases: 'Read typical sentences'
    },
    advanced_options: {
      news: 'Read news',
      videos: 'Watch videos',
      wikipedia: 'Read articles',
      books: 'Read books'
    }
  },
  languages: {
    de: 'German',
    en: 'English',
    es: 'Spanish',
    fr: 'French',
    it: 'Italian',
    pt: 'Portuguese',
    ru: 'Russian',
    zh: 'Chinese',
    pl: 'Polish'
  },
  general: {
    beginner: 'Beginner',
    advanced: 'Advanced',
    search: 'Search',
    search_imperative: 'Search',
    translate: 'Translate',
    settings: 'Settings',
    vocabulary: 'Vocaublary',
    shorts: 'Short texts',
    articles: 'Articles',
    feeds: 'News',
    videos: 'Videos',
    read: 'Read',
    view: 'View',
    listen: 'Listen',
    save: 'Save',
    more: 'more',
    back: 'Back',
    next: 'Next',
    easy: 'Easy',
    medium: 'Medium',
    hard: 'Hard',
    all: 'All',
    level: 'Level',
    font_size: 'Font size',
    language: 'Language',
    send: 'Send',
    image: 'Image',
    wordmap: 'Wordmap',
    open: 'Open',
    add: 'Add',
    question: 'Question',
    start: 'Start',
    words: 'Word | Words'
  },
  app_bar: {
    search_in_foreign_language: 'Search in foreign language',
    menu: 'Menu'
  },
  category: {
    chapter: 'Chapter'
  },
  favorites: {
    favorites: 'Favorites',
    word: 'Word',
    sentence: 'Sentence',
    sentences: 'Sentences',
    texts_videos: 'Texts and Videos',
    wordmaps: 'Wordmaps',
    saved: 'Saved',
    title: 'Title',
    type: 'Type',
    no_favorites_found: 'No favorites found',
    filtered_by: 'filtered by',
    train: 'Train',
    notify_me_srs: 'Notify me to repeat vocabulary',
    srs_level: 'SRS Level',
    success_rate: 'Success Rate',
    last_seen: 'Last time seen',
    next_review: 'Next review',
    success_level_up: 'Word moved to level {level}',
    what_is_srs: 'What is SRS?',
    srs_short:
      'SRS is a spaced repetition system. It helps you to remember words better by repeating them at the right time.',
    srs_long:
      'The system is based on the principle that the more often you repeat a word, the better you remember it. The intervals between repetitions are determined by the system. If you remember a word well, the intervals become longer. If you have difficulties, the intervals become shorter. This way you can learn words more efficiently.',
    srs_intervals: 'The {nIntervals} intervals are: {intervals} days.',
    when_does_a_word_move: 'When does a word move to the next level?',
    srs_next_level_example_1:
      'If you add a word to the favorites, it will be on level 1. A word on level 1 will go to the next level if 1 day passed since you added the word, your success rate is higher than 50% and you just remembered the word correctly.',
    srs_next_level_example_2:
      'A word on level {level} will go to the next level after the last change to level {levelM1} was at least {days} days ago, your success rate is higher than 50% and you just remembered the word correctly.'
  },
  player: {
    maximize: 'Maximize',
    minimize: 'Minimize'
  },
  video: {
    error:
      'There was an error loading the subtitles. Unfortunately, not all subtitles are freely accessible. Please try another video.',
    subtitles: 'Subtitles'
  },
  feeds: {
    disclaimer:
      'This is an automatically generate summary by Chatgpt. The original article is available',
    here: 'here',
    generating_article: 'Generating article...',
    error:
      'There was an error loading the article. Unfortunately, not all articles are freely accessible. Please try another article or copy the text manually',
    open_org: '1. Open original article and copy text',
    open_own: '2. Paste text here'
  },
  books: {
    read_now: 'Read now',
    continue_on_page: 'Continue on page'
  },
  tooltip: {
    examples: 'Examples',
    emotional_examples: 'Emotional Examples',
    definition: 'Definition',
    synonyms: 'Synonyms',
    antonyms: 'Antonyms',
    article: 'Article',
    conjugations: 'Conjugation',
    grammer: 'Grammar'
  },
  custom_text: {
    enter_your_own_text: 'Enter your own text',
    read_text: 'Read text',
    enter_new_text: 'Enter new text',
    own_text: 'Copy your own text and paste it here',
    special_vocabulary: 'Special vocabulary'
  },
  special_vocabulary: {
    special_vocabulary: 'Special vocabulary',
    describe: 'Enter a text in english about a topic or special vocabulary you want to learn',
    language_level: 'Language level',
    generate_text: 'Generate text'
  },
  intro_message: {
    save_word_as_fav: 'Save the word as favorite',
    save_video_as_fav: 'Save the video as favorite',
    save_article_as_fav: 'Save the article as favorite',
    save_book_as_fav: 'Save the book as favorite',
    save_wordmap_as_fav: 'Save the word map as favorite',
    play_word: 'Listen to the pronunciation of the word',
    play_article: 'Listen to the article',
    play_book: 'Listen to the book',
    select_main_category: 'Select a main category',
    select_sub_category: 'Select a subcategory',
    click_on_read: 'Click on `READ` to read the full text',
    click_on_view: 'Click on `WATCH` to watch a video',
    last_book_page: 'Your last page will be saved automatically and displayed here',
    wordmap_click_on_word: 'Click on the word to see the most frequently used words',
    wordmap_click_on_line: 'Click on a line between two words to see a sentence',
    category_progress: 'Track your progress by checking the checkboxes',
    conversations_select_scene: 'Select an exercise',
    conversations_warn:
      'It seems there is an error in your sentence. Click on the triangle for more explanations.',
    conversations_task: 'Formulate a sentence based on the instruction at the end of the chat'
  },
  tour_guide: {
    click_on_every_word: {
      title: 'Translate Word',
      text: 'You can click on any word to see translations, example sentences, and definitions'
    },
    click_on_i_for_sentence: {
      title: 'Full Sentence',
      text: 'To translate an entire sentence, click on the i-symbol. Here you can also get explanations about grammar.'
    },
    no_save_custom: {
      title: 'No saving',
      text: 'Custom text can not be saved'
    },
    change_playback_rate: {
      title: 'Change Playback Speed',
      text: 'Change the playback speed via Menu/Settings/Audio and Video.'
    },
    theme: {
      title: 'Colors',
      text: 'You can change the colors in the menu to dark mode'
    },
    explain_menu: {
      title: 'Menu',
      text: 'The menu items are sorted from very difficult to easy from top to bottom'
    },
    repeat_words: {
      title: 'Repeat Vocabulary',
      text: 'You should repeat {nWords} vocabularies.',
      call_to_action: 'Learn now'
    }
  },
  chat: {
    your_message: 'Your message',
    in_de: 'in German',
    in_es: 'in Spanish',
    in_fr: 'in French',
    in_it: 'in Italian',
    in_pt: 'in Portuguese',
    in_ru: 'in Russian',
    in_zh: 'in Chinese',
    in_pl: 'in Polish',
    wait: 'Please wait...',
    speak_now: 'Speak now',
    new_chat: 'New chat'
  },
  langs: {
    de: 'German',
    en: 'English',
    sp: 'Spanish',
    fr: 'French',
    it: 'Italian',
    pt: 'Portuguese',
    pl: 'Polish',
    ru: 'Russian',
    zh: 'Chinese'
  },
  about: {
    how_does_it_work: 'How does it work?',
    here_you_can: 'Here you can',
    read_texts: 'Read texts',
    watch_videos: 'Watch videos',
    click_on_every_wrord_for: 'Click on any word for',
    translations: 'translations',
    examples: 'examples',
    definitions: 'definitions',
    synonyms: 'synonyms',
    copy_your_own_text: 'Copy and read your own text',
    chat_with_chatbot: 'Chat with a chatbot',
    level: 'Your level should be between B1 and C2'
  },
  settings: {
    audio_video: 'Audio and Video',
    colors: 'Color',
    dark_mode: 'Dark mode',
    lang_level: 'Language level'
  },
  error: {
    resource_not_found: 'Sorry, we could not find what you are looking for'
  },
  train: {
    check: 'Check',
    flashcards: 'Flashcards',
    exercises: 'Exercises',
    finished: 'Exercises finished',
    go_to_favorites: 'Go to favorites',
    restart_exercise: 'Restart exercise',
    go_to_home: 'Go to home',
    text_with_words: 'Text with words',
    text_includes: 'The text includes the following words'
  },
  wordmap: {
    click_on_circle: 'Click on the circle to see the most frequently used words with {word}',
    click_on_edge: 'Click on the line between two words to see a sentence with both words',
    open_fullscreen: 'Open Wordmap in fullscreen',
    add_own_word: 'Add own word',
    add_sentence_with_all_words: 'Add sentence with all words',
    add_to_word: 'Add to word'
  },
  conversations: {
    you_are: 'You are',
    you_talk_to: 'You talk to',
    your_tasks: 'Your tasks'
  },
  write: {
    min_words: 'Minimum words',
    your_email_needs_to_contain: 'Your email needs to contain',
    rate: 'Rate',
    analysis: 'Analysis',
    overall: 'Overall',
    grammar: 'Grammar',
    content: 'Content',
    spelling: 'Spelling'
  }
};
