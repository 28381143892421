export enum GAnalyticsEvent {
  CLICK_COOKIE_ACCEPT_ALL = 'cookie_accept_all',
  CLICK_COOKIE_DENY_ALL = 'cookie_deny_all',
  CLICK_COOKIE_ACCEPT_SELECTED = 'cookie_accept_selected',
  SELECT_LANGUAGE_NATIVE = 'select_language_native',
  SELECT_LANGUAGE_FOREIGN = 'select_language_foreign',
  CLICK_INTRO_LEVEL_CARD = 'click_intro_level_card',
  CLICK_INTRO_START_WITH_CARD = 'click_intro_start_with_card',
  CLICK_SELECT_LANG_AND_START = 'click_select_lang_and_start',
  CLICK_START_LEARNING = 'click_start_learning',
  EXCEPTION = 'exception',
}
