// @ts-ignore
import anime from 'animejs/lib/anime.es.js';
import JSConfetti from 'js-confetti';
const jsConfetti = new JSConfetti();

export default () => {
  const animate = (selector: string, cssClass = 'scale', duration = 300) => {
    const el = document.querySelector(selector);
    if (!el) return;
    el.classList.add(cssClass);
    setTimeout(() => {
      el.classList.remove(cssClass);
    }, duration);
  };
  const animateAnime = (selector: string, props: any, duration = 300, timeout = 0) => {
    setTimeout(() => {
      const timeline = anime.timeline({ loop: false });
      timeline.add({
        targets: selector,
        duration: duration,
        ...props
      });
    }, timeout);
  };

  const addConfetti = () => {
    jsConfetti.addConfetti({
      confettiColors: [
        '#28a745',
        '#218838',
        '#1e7e34',
        '#155724',
        '#4caf50'
      ]
    });
  }

  const animateSadFace = () => {
    animateAnime(
      '#sad-face',
      {
        opacity: [0.7],
        scale: [0, 1.5],
        translateX: [0],
        begin: function () {
          document.querySelector('#sad-face').style.display = 'block';
        }
      },
      800
    );
    animateAnime(
      '#sad-face',
      {
        translateX: [
          { value: -10, duration: 20 },
          { value: 10, duration: 20 },
          { value: -8, duration: 20 },
          { value: 8, duration: 20 },
          { value: -5, duration: 20 },
          { value: 5, duration: 20 },
          { value: 0, duration: 20 }
        ]
      },
      120,
      700
    );
    animateAnime(
      '#sad-face',
      {
        opacity: [0.7, 0],
        scale: [1.8, 0],
        complete: function () {
          document.querySelector('#sad-face').style.display = 'none';
          document.querySelector('#sad-face').style.transform = 'translate(-50%, -50%)';
        }
      },
      1000,
      1100
    );
  };

  return {
    funcs: {
      animate,
      animateAnime,
      animateSadFace,
      addConfetti
    }
  };
};
