export default {
  welcome: {
    your_language: 'Tu idioma',
    language_to_learn: 'Idioma que quieres aprender',
    start_learning: 'Comienza a aprender',
    how_does_this_work: 'Como funciona?',
    welcome_1: 'Bienvenido a JAALUU',
    welcome_2: 'Aquí puedes aprender idiomas',
    welcome_3: 'Leer textos o ver vídeos con subtítulos',
    welcome_4: 'Haga clic en una palabra para ver traducciones, ejemplos y definiciones',
    main_teaser: 'Mejora tus habilidades lingüísticas - de manera fácil y gratuita',
    select_language_and_start: 'Selecciona el idioma y empieza',
    how_to_title: 'Lee textos, libros, noticias o mira videos',
    how_to_text: 'Puedes elegir entre una variedad de textos, libros, noticias o videos',
    click_on_word_title: 'Traducción automática, ejemplos y definiciones',
    click_on_word_text:
      'Haz clic en cualquier palabra para obtener traducción automática, ejemplos de frases y definiciones. También puedes traducir oraciones completas automáticamente o vincular palabras con imágenes y crear redes de palabras.',
    listen_title: 'Escucha palabras y textos',
    listen_text:
      'Puedes escuchar cualquier palabra, oración o texto completo y practicar la pronunciación',
    conversations_title: 'Practica conversaciones',
    conversations_text:
      "Puedes practicar conversaciones hablando con un chatbot virtual. Debes resolver tareas como: 'Pedir comida en un restaurante' o 'Pedir direcciones'.",
    writing_title: 'Ortografía',
    writing_text_1: 'Practica la ortografía escribiendo textos',
    writing_text_2: 'Tus textos serán revisados por errores ortográficos, contenido y gramática.',
    texts_and_videos_title: 'Encuentra tu nivel',
    texts_and_videos_text:
      'Puedes elegir entre frases simples y textos para niños, noticias, artículos de Wikipedia o libros, lo que más te convenga',
    srs_title: 'Repite y entrena vocabulario',
    srs_text_1:
      'Puedes guardar cualquier palabra u oración completa como favoritos para repetirla más tarde',
    srs_text_2:
      'Entrena tu vocabulario con tarjetas didácticas y ejercicios simples, y sigue tu progreso',
    srs_text_3:
      'El sistema de repetición SRS te ayuda a aprender de manera más eficaz repitiendo palabras en el momento adecuado',
    own_text_title: 'Vocabulario especializado',
    own_text_text:
      'Puedes crear un texto sobre un tema específico para aprender vocabulario especializado. También puedes crear textos que contengan palabras que quieras aprender.',
    wordmap_title: 'Redes de palabras',
    wordmap_text_1: 'Puedes crear redes de palabras y vincular palabras con frases simples',
    wordmap_text_2:
      'Las tarjetas de palabras se pueden guardar como favoritos, lo que permite una repetición y entrenamiento fáciles',
    sayings_title: 'Modismos',
    sayings_text:
      'Aquí encontrarás frases y modismos comunes, categorizados. Puedes seguir tu progreso y continuar desarrollándote de manera estructurada.',
    details: 'Detalles',
    details_text_and_videos_title:
      'Descubre Jaaluu – ¡Tu clave para aprender idiomas rápido y eficazmente!',
    details_text_and_videos_text:
      'Jaaluu te ofrece una gran cantidad de textos y videos para mejorar tu nivel de idioma. La plataforma es ideal si ya puedes entender palabras y frases simples. Para principiantes, hay frases simples e idioms, textos para niños, diccionarios y videos para un fácil comienzo. Para estudiantes avanzados, hay noticias, artículos de Wikipedia, libros y videos interesantes para una comprensión más profunda, o conversaciones interactivas con chatbots. Además, puedes entrenar tu ortografía y recibir consejos para mejorar los errores ortográficos, el contenido y la gramática.',
    details_click_on_words_title: '¡Aprender vocabulario fácil: rápido, interactivo y sostenible!',
    details_click_on_words_text:
      'Aprender vocabulario puede ser tedioso porque buscar palabras y definiciones lleva mucho tiempo. Jaaluu resuelve este problema: simplemente haz clic en una palabra para obtener traducciones, ejemplos de frases, definiciones y explicaciones de inmediato. ¡Esto ahorra tiempo y te ayuda a aprender más rápido y eficazmente!',
    details_listen_title:
      'Escuchar y practicar: ¡Escucha palabras, frases y textos para mejorar tus habilidades lingüísticas!',
    details_listen_text:
      'Puedes escuchar todo: cualquier palabra, cualquier frase o incluso textos completos. ¡Solo haz clic y escucha! Esta función te ayuda a practicar la pronunciación correcta y mejorar la comprensión auditiva. También puedes escuchar conversaciones y desarrollar un sentido de comunicación natural. Perfecto para interiorizar vocabulario y fortalecer tus habilidades lingüísticas en situaciones cotidianas.',
    details_conversations_title:
      'Entrenamiento práctico de idiomas: ¡Conversaciones realistas para la vida diaria!',
    details_conversations_text:
      'Con Jaaluu, puedes practicar conversaciones realistas con un chatbot y prepararte para situaciones cotidianas típicas. Por ejemplo, puedes pedir comida en un restaurante y pagar la cuenta o pedir direcciones. O puedes chatear con tu nuevo colega en tu primer día de trabajo. Tus oraciones serán analizadas automáticamente para darte retroalimentación directa y mejorar tus habilidades lingüísticas. ¡De esta manera, entrenas de manera práctica y eficaz para conversaciones reales!',
    details_writing_title:
      'Práctica de escritura para la vida diaria: ¡Mejora tus habilidades con tareas realistas!',
    details_writing_text:
      'Puedes practicar la escritura dominando pequeñas tareas realistas, como escribir aplicaciones o cartas de queja. Luego, tu texto será analizado automáticamente y recibirás retroalimentación directa sobre ortografía, contenido y gramática. ¡De esta manera, mejoras tus habilidades de escritura y te preparas bien para la vida cotidiana!',
    details_srs_title:
      'Aprendizaje eficaz de vocabulario con SRS: ¡Repeticiones automáticas y análisis personalizado del progreso!',
    details_srs_text:
      'Con el sistema SRS (Sistema de Repetición Espaciada), puedes aprender y repetir vocabulario de manera eficaz. Este sistema te recuerda cuándo repetir palabras para retenerlas a largo plazo. SRS se basa en el método científico de que repetir a intervalos determinados fortalece la memoria. También recibirás estadísticas sobre el progreso de aprendizaje de cada palabra y tu tasa de éxito, para que puedas concentrarte en tus debilidades y siempre saber cómo estás progresando en el aprendizaje de vocabulario.',
    details_own_text_title:
      'Aprendizaje personalizado: ¡Crea textos a medida para ejercicios de vocabulario y temas específicos!',
    details_own_text_text:
      'Con Jaaluu, puedes crear fácilmente tus propios textos en áreas en las que quieras enfocarte. Describe el área o vocabulario que deseas aprender y recibirás un texto para leer y practicar. De esta manera, puedes concentrarte en temas o vocabulario específicos y ampliar tus conocimientos en el área que más te interese. ¡Este enfoque personalizado te ayuda a aprender de manera más eficaz y precisa! Además, puedes crear textos que contengan todo el vocabulario que quieras repasar. Esto te permite aprender y repasar vocabulario en contexto, lo que facilita su memorización.',
    details_wordmap_title:
      'Tarjetas de palabras: ¡Conecta tu conocimiento y recuerda las palabras con facilidad!',
    details_wordmap_text:
      'Puedes crear tarjetas de palabras que te ayuden a vincular palabras y recordarlas de manera más eficaz. Agrega palabras y oraciones de manera interactiva a tu tarjeta de palabras y expande gradualmente tu vocabulario. Esto crea y fortalece conexiones en tu cerebro y construye asociaciones de manera lúdica. ¡De esta manera, mejoras tu comprensión lingüística de manera sostenible!',
    details_paste_text_title:
      'Usa tus propios textos: ¡Pega tu contenido para aprender con enfoque!',
    details_paste_text_text:
      'Con Jaaluu, también puedes pegar tus propios textos para usarlos en la práctica. Esto te permite trabajar con tu propio material y entrenar tu vocabulario en contextos reales.',
    site_also_available: 'El sitio también está disponible en',
    which_language_title: '¿Qué idiomas puedo aprender?',
    which_language_text:
      'Aquí podrás aprender inglés, francés, italiano, portugués, alemán, ruso y polaco. ¡Simplemente selecciona el idioma que deseas aprender y listo!'
  },
  intro: {
    your_level: '¿Cuál es tu nivel de idioma?',
    beginner_explained: 'Puedes leer oraciones simples',
    advanced_explained: 'Puedes leer textos más complejos',
    your_cefr_level: 'Tu nivel CEFR es',
    lets_start: '¿Con qué te gustaría empezar?',
    beginner_options: {
      simple_texts: 'Leer textos',
      simple_articles: 'Leer artículos',
      sayings: 'Aprender refranes',
      view_videos: 'Ver videos',
      simple_phrases: 'Leer frases típicas'
    },
    advanced_options: {
      news: 'Leer noticias',
      videos: 'Ver videos',
      wikipedia: 'Leer artículos',
      books: 'Leer libros'
    }
  },
  languages: {
    de: 'Alemán',
    en: 'Inglés',
    es: 'Español',
    fr: 'Francés',
    it: 'Italiano',
    pt: 'Portugués',
    ru: 'Ruso',
    zh: 'Chino',
    pl: 'Polaco'
  },
  general: {
    beginner: 'Principiante',
    advanced: 'Avanzado',
    search: 'Buscar',
    search_imperative: 'Busca',
    translate: 'Traducir',
    settings: 'Ajustes',
    vocabulary: 'Vocabulario',
    shorts: 'Textos cortos',
    articles: 'Artículos',
    feeds: 'Noticias',
    videos: 'Videos',
    read: 'Leer',
    view: 'Ver',
    listen: 'Escuchar',
    save: 'Guardar',
    more: 'mas',
    back: 'Atrás',
    next: 'Siguiente',
    easy: 'Fácil',
    medium: 'Medio',
    hard: 'Difícil',
    all: 'Todos',
    level: 'Nivel',
    font_size: 'Tamaño de fuente',
    language: 'Idioma',
    send: 'Enviar',
    image: 'Imagen',
    wordmap: 'Mapa de palabras',
    open: 'Abrir',
    add: 'Añadir',
    question: 'Pregunta',
    start: 'Comenzar',
    words: 'Palabra | Palabras'
  },
  app_bar: {
    search_in_foreign_language: 'Buscar en idioma extranjero',
    menu: 'Menú'
  },
  category: {
    chapter: 'Capítulo'
  },
  favorites: {
    favorites: 'Favoritos',
    word: 'Palabra',
    sentence: 'Oracion',
    sentences: 'Oraciones',
    texts_videos: 'Textos y Videos',
    wordmaps: 'Mapas de palabras',
    saved: 'Guardado',
    title: 'Título',
    type: 'Tipo',
    no_favorites_found: 'No se encontraron favoritos',
    filtered_by: 'filtrado por',
    train: 'Entrenar',
    notify_me_srs: 'Notificarme para repetir vocabulario',
    srs_level: 'Nivel SRS',
    success_rate: 'Tasa de éxito',
    last_seen: 'Última vez visto',
    next_review: 'Próxima revisión',
    success_level_up: 'La palabra ha subido al nivel {level}',
    what_is_srs: '¿Qué es el SRS?',
    srs_short:
      'El SRS es un sistema de repetición espaciada. Te ayuda a recordar las palabras mejor repitiéndolas en el momento adecuado.',
    srs_long:
      'El sistema se basa en el principio de que, cuanto más repites una palabra, mejor la recuerdas. Los intervalos entre repeticiones son determinados por el sistema. Si recuerdas bien una palabra, los intervalos se hacen más largos. Si tienes dificultades, los intervalos se hacen más cortos. De esta manera, puedes aprender palabras de forma más eficiente.',
    srs_intervals: 'Los {nIntervals} intervalos son: {intervals} días.',
    when_does_a_word_move: '¿Cuándo una palabra pasa al siguiente nivel?',
    srs_next_level_example_1:
      'Si agregas una palabra a los favoritos, estará en el nivel 1. Una palabra en el nivel 1 pasará al siguiente nivel si ha pasado 1 día desde que agregaste la palabra, tu tasa de éxito es superior al 50% y acabas de recordar la palabra correctamente.',
    srs_next_level_example_2:
      'Una palabra en el nivel {level} pasará al siguiente nivel después de que el último cambio al nivel {levelM1} haya sido al menos {days} días atrás, tu tasa de éxito es superior al 50% y acabas de recordar la palabra correctamente.'
  },
  player: {
    maximize: 'Maximizar',
    minimize: 'Minimizar'
  },
  video: {
    error:
      'Hubo un error al cargar los subtítulos. Lamentablemente, no todos los subtítulos son de libre acceso. Por favor prueba con otro vídeo.'
  },
  feeds: {
    disclaimer:
      'Esta es un resumen generado automáticamente por Chatgpt. El artículo original está disponible',
    here: 'aquí',
    generating_article: 'Generando artículo...',
    error:
      'Hubo un error al cargar el artículo. Lamentablemente, no todos los artículos son de libre acceso. Pruebe con otro artículo o copie el texto manualmente.',
    open_org: '1. Abrir artículo original y copiar texto',
    open_own: '2. Insertar texto aquí'
  },
  books: {
    read_now: 'Leer ahora',
    continue_on_page: 'Continuar en la página'
  },
  tooltip: {
    examples: 'Ejemplos',
    emotional_examples: 'Ejemplos emocionales',
    definition: 'Definición',
    synonyms: 'Sinónimas',
    antonyms: 'Antónimos',
    article: 'Artículo',
    conjugations: 'Conjugación',
    grammer: 'Gramática'
  },
  custom_text: {
    enter_your_own_text: 'Introduce tu propio texto',
    read_text: 'Leer texto',
    enter_new_text: 'Introduce un nuevo texto',
    own_text: 'Copia tu propio texto y pégalo aquí',
    special_vocabulary: 'Vocabulario especial'
  },
  special_vocabulary: {
    special_vocabulary: 'Vocabulario especial',
    describe: 'Ingresa un texto en inglés sobre un tema o vocabulario especial que deseas aprender',
    language_level: 'Nivel de idioma',
    generate_text: 'Generar texto'
  },
  intro_message: {
    save_word_as_fav: 'Guarda la palabra como favorita',
    save_video_as_fav: 'Guarda el video como favorito',
    save_article_as_fav: 'Guarda el artículo como favorito',
    save_book_as_fav: 'Guarda el libro como favorito',
    save_wordmap_as_fav: 'Guarda el mapa de palabras como favorito',
    play_word: 'Escucha la pronunciación de la palabra',
    play_article: 'Escucha el artículo',
    play_book: 'Escucha el libro',
    select_main_category: 'Selecciona una categoría principal',
    select_sub_category: 'Selecciona una subcategoría',
    click_on_read: 'Haz clic en `LEER` para leer el texto completo',
    click_on_view: 'Haz clic en `VER` para ver un video',
    last_book_page: 'Tu última página se guardará automáticamente y se mostrará aquí',
    wordmap_click_on_word: 'Haz clic en la palabra para ver las palabras más utilizadas',
    wordmap_click_on_line: 'Haz clic en una línea entre dos palabras para ver una oración',
    category_progress: 'Sigue tu progreso marcando las casillas',
    conversations_select_scene: 'Selecciona un ejercicio',
    conversations_warn:
      'Parece que hay un error en tu oración. Haz clic en el triángulo para más explicaciones.',
    conversations_task: 'Formula una oración según la instrucción al final del chat'
  },
  tour_guide: {
    click_on_every_word: {
      title: 'Traducir palabra',
      text: 'Puedes hacer clic en cualquier palabra para ver traducciones, oraciones de ejemplo y definiciones'
    },
    click_on_i_for_sentence: {
      title: 'Frase completa',
      text: 'Para traducir una frase completa, haz clic en el símbolo i. Aquí también puedes obtener explicaciones sobre gramática.'
    },
    no_save_custom: {
      title: 'No se puede guardar',
      text: 'Los propios textos no se pueden guardar'
    },
    change_playback_rate: {
      title: 'Cambiar Velocidad de Reproducción',
      text: 'Cambia la velocidad de reproducción en Menú/Configuración/Audio y Video.'
    },
    theme: {
      title: 'Colores',
      text: 'Puedes cambiar los colores en el menú a modo oscuro'
    },
    explain_menu: {
      title: 'Menú',
      text: 'Los elementos del menú están ordenados de muy difícil a fácil de arriba a abajo'
    },
    repeat_words: {
      title: 'Repetir vocabulario',
      text: 'Debes repetir {nWords} vocabularios.',
      call_to_action: 'Aprender ahora'
    }
  },
  chat: {
    your_message: 'Tu mensaje',
    in_de: 'en alemán',
    in_en: 'en inglés',
    in_es: 'en español',
    in_fr: 'en francés',
    in_it: 'en italiano',
    in_pt: 'en portugués',
    in_ru: 'en ruso',
    in_zh: 'en chino',
    in_pl: 'en polaco',
    wait: 'Por favor espera...',
    speak_now: 'Habla ahora',
    new_chat: 'Nueva conversación'
  },
  langs: {
    de: 'Alemán',
    en: 'Inglés',
    es: 'Español',
    fr: 'Francés',
    it: 'Italiano',
    pt: 'Portugués',
    ru: 'Ruso',
    zh: 'Chino',
    pl: 'Polaco'
  },
  about: {
    how_does_it_work: 'Como funciona?',
    here_you_can: 'Aquí puedes',
    read_texts: 'leer textos',
    watch_videos: 'ver videos',
    click_on_every_wrord_for: 'Haz clic en cualquier palabra para',
    translations: 'traducciones',
    examples: 'ejemplos',
    definitions: 'definiciones',
    synonyms: 'sinónimos',
    copy_your_own_text: 'Copia y leer tu propio texto',
    chat_with_chatbot: 'Chatea con un chatbot',
    level: 'Tu nivel debería estar entre B1 y C2'
  },
  settings: {
    audio_video: 'Audio y Video',
    colors: 'Color',
    dark_mode: 'Modo oscuro',
    lang_level: 'Nivel de idioma'
  },
  error: {
    resource_not_found: 'Lo sentimos, no pudimos encontrar lo que buscas'
  },
  train: {
    check: 'Comprobar',
    flashcards: 'Tarjetas de memoria',
    exercises: 'Ejercicios',
    finished: 'Ejercicios terminados',
    go_to_favorites: 'Ir a favoritos',
    restart_exercise: 'Reiniciar ejercicio',
    go_to_home: 'Ir a inicio',
    text_with_words: 'Texto con palabras',
    text_includes: 'El texto incluye las siguientes palabras'
  },
  wordmap: {
    click_on_circle: 'Haz clic en el círculo para ver las palabras más utilizadas con {word}',
    click_on_edge: 'Haz clic en la línea entre dos palabras para ver una frase con ambas palabras',
    open_fullscreen: 'Abrir en pantalla completa',
    add_own_word: 'Añadir palabra propia',
    add_sentence_with_all_words: 'Añadir frase con todas las palabras',
    add_to_word: 'Agregar a la palabra'
  },
  conversations: {
    you_are: 'Tu eres',
    you_talk_to: 'Tu hablas con',
    your_tasks: 'Tus tareas'
  },
  write: {
    min_words: 'Palabras mínimas',
    your_email_needs_to_contain: 'Tu correo electrónico debe contener',
    rate: 'Calificar',
    analysis: 'Análisis',
    overall: 'General',
    grammar: 'Gramática',
    content: 'Contenido',
    spelling: 'Ortografía'
  }
};
