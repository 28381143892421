import {
  CommunicationBasics,
  EntertainmentAndLeisure,
  FeelingsAndEmotions,
  FoodAndDining,
  HealthAndWellBeing,
  MainCategories,
  NubmersAndQuantity,
  SocialContexts,
  TimeAndDates,
  TravelAndDirections
} from '@shared/openapi/jaaluu-api';

export const CATEGORIES_EN = {
  [MainCategories.COMMUNICATION_BASICS]: {
    title: 'Communication',
    categories: {
      [CommunicationBasics.GREETINGS]: 'Greetings',
      [CommunicationBasics.QUESTIONS]: 'Questions',
      [CommunicationBasics.EXPRESSIONS]: 'Expressions'
    }
  },
  [MainCategories.FEELINGS_AND_EMOTIONS]: {
    title: 'Feelings and Emotions',
    categories: {
      [FeelingsAndEmotions.POSITIVE_EMOTIONS]: 'Positive Emotions',
      [FeelingsAndEmotions.NEGATIVE_EMOTIONS]: 'Negative Emotions',
      [FeelingsAndEmotions.NEUTRAL_OR_MIXED_FEELINGS]: 'Neutral or Mixed Feelings'
    }
  },
  [MainCategories.TRAVEL_AND_DIRECTIONS]: {
    title: 'Travel and Directions',
    categories: {
      [TravelAndDirections.DIRECTIONS]: 'Directions',
      [TravelAndDirections.LOCATIONS]: 'Locations',
      [TravelAndDirections.TRANSPORTATION]: 'Transportation',
      [TravelAndDirections.ACCOMODATION]: 'Accomodation'
    }
  },
  [MainCategories.NUMBERS_AND_QUANTITY]: {
    title: 'Numbers and Quantity',
    categories: {
      [NubmersAndQuantity.NUMBERS]: 'Numbers',
      [NubmersAndQuantity.QUANTITY]: 'Quantity'
    }
  },
  [MainCategories.TIME_AND_DATES]: {
    title: 'Time and Dates',
    categories: {
      [TimeAndDates.TIME]: 'Time',
      [TimeAndDates.DATES]: 'Dates'
    }
  },
  [MainCategories.ENTERTAINMENT_AND_LEISURE]: {
    title: 'Entertainment and Leisure',
    categories: {
      [EntertainmentAndLeisure.ENTERTAINMENT]: 'Entertainment',
      [EntertainmentAndLeisure.LEISURE]: 'Leisure'
    }
  },
  [MainCategories.HEALTH_AND_WELL_BEING]: {
    title: 'Health and Well Being',
    categories: {
      [HealthAndWellBeing.HEALTH]: 'Health',
      [HealthAndWellBeing.WELL_BEING]: 'Well Being'
    }
  },
  [MainCategories.SOCIAL_CONTEXTS]: {
    title: 'Soical Contexts',
    categories: {
      [SocialContexts.SOCIAL]: 'Social',
      [SocialContexts.CULTURAL]: 'Cultural',
      [SocialContexts.RELIGIOUS]: 'Religious',
      [SocialContexts.POLITICAL]: 'Political'
    }
  },
  [MainCategories.FOOD_AND_DINING]: {
    title: 'Food and Dining',
    categories: {
      [FoodAndDining.FOOD]: 'Food',
      [FoodAndDining.DRINKS]: 'Drinks',
      [FoodAndDining.DINING]: 'Dining'
    }
  }
};
