import {
  CommunicationBasics,
  EntertainmentAndLeisure,
  FeelingsAndEmotions,
  FoodAndDining,
  HealthAndWellBeing,
  MainCategories,
  NubmersAndQuantity,
  SocialContexts,
  TimeAndDates,
  TravelAndDirections
} from '@shared/openapi/jaaluu-api';

export const CATEGORIES_FR = {
  [MainCategories.COMMUNICATION_BASICS]: {
    title: 'Communication',
    categories: {
      [CommunicationBasics.GREETINGS]: 'Salutations',
      [CommunicationBasics.QUESTIONS]: 'Questions',
      [CommunicationBasics.EXPRESSIONS]: 'Expressions'
    }
  },
  [MainCategories.FEELINGS_AND_EMOTIONS]: {
    title: 'Sentiments et émotions',
    categories: {
      [FeelingsAndEmotions.POSITIVE_EMOTIONS]: 'Émotions positives',
      [FeelingsAndEmotions.NEGATIVE_EMOTIONS]: 'Émotions négatives',
      [FeelingsAndEmotions.NEUTRAL_OR_MIXED_FEELINGS]: 'Sentiments neutres ou mixtes'
    }
  },
  [MainCategories.TRAVEL_AND_DIRECTIONS]: {
    title: 'Voyages et directions',
    categories: {
      [TravelAndDirections.DIRECTIONS]: 'Directions',
      [TravelAndDirections.LOCATIONS]: 'Lieux',
      [TravelAndDirections.TRANSPORTATION]: 'Transport',
      [TravelAndDirections.ACCOMODATION]: 'Hébergement'
    }
  },
  [MainCategories.NUMBERS_AND_QUANTITY]: {
    title: 'Nombres et quantité',
    categories: {
      [NubmersAndQuantity.NUMBERS]: 'Nombres',
      [NubmersAndQuantity.QUANTITY]: 'Quantité'
    }
  },
  [MainCategories.TIME_AND_DATES]: {
    title: 'Heures et dates',
    categories: {
      [TimeAndDates.TIME]: 'Heure',
      [TimeAndDates.DATES]: 'Dates'
    }
  },
  [MainCategories.ENTERTAINMENT_AND_LEISURE]: {
    title: 'Divertissement et loisirs',
    categories: {
      [EntertainmentAndLeisure.ENTERTAINMENT]: 'Divertissement',
      [EntertainmentAndLeisure.LEISURE]: 'Loisirs'
    }
  },
  [MainCategories.HEALTH_AND_WELL_BEING]: {
    title: 'Santé et bien-être',
    categories: {
      [HealthAndWellBeing.HEALTH]: 'Santé',
      [HealthAndWellBeing.WELL_BEING]: 'Bien-être'
    }
  },
  [MainCategories.SOCIAL_CONTEXTS]: {
    title: 'Contextes sociaux',
    categories: {
      [SocialContexts.SOCIAL]: 'Social',
      [SocialContexts.CULTURAL]: 'Culturel',
      [SocialContexts.RELIGIOUS]: 'Religieux',
      [SocialContexts.POLITICAL]: 'Politique'
    }
  },
  [MainCategories.FOOD_AND_DINING]: {
    title: 'Alimentation et restauration',
    categories: {
      [FoodAndDining.FOOD]: 'Aliments',
      [FoodAndDining.DRINKS]: 'Boissons',
      [FoodAndDining.DINING]: 'Restauration'
    }
  }
};
