<script setup lang="ts">
import vuetify from '@shared/vuetify';
import { ref } from 'vue';

const emits = defineEmits(['search']);

const props = defineProps({
  clearOnSearch: {
    type: Boolean,
    required: false,
    default: false
  },
  wrapperClasses: {
    type: String,
    required: false,
    default: 'align-center'
  }
});

const text = ref('');

const search = (e: Event) => {
  emits('search', text.value, e);
  if (props.clearOnSearch) text.value = '';
};

let debounceTranslateTimeout: any = null;
const debounceSearch = (event: any) => {
  if (event.key !== 'Enter' && vuetify.display.mdAndDown.value) return;
  clearTimeout(debounceTranslateTimeout);
  debounceTranslateTimeout = setTimeout(() => {
    search(event);
  }, 800);
};
</script>

<template>
  <div class="d-flex search" :class=[props.wrapperClasses]>
    <v-text-field
      color="green"
      v-model="text"
      variant="underlined"
      clear-icon="mdi-close"
      density="compact"
      v-bind="$attrs"
      class="search"
      @keyup.enter="search"
      @click:clear="search"
    >
    <template v-slot:label>
      <slot></slot>
    </template>
    </v-text-field>
    <v-btn
      v-if="text?.length > 0"
      @click="(e: any) => search(e)"
      variant="tonal"
      size="x-small"
      class="ml-2"
      color="green"
      icon="mdi-send"
      />
  </div>
</template>

<style scoped lang="scss">
.search {
  width: 170px;
}
@media screen and (min-width: 600px) {
  .search {
    width: 300px;
  }
}
</style>
