import { ref, Ref } from 'vue';

const LOCAL_STORAGE_KEY = 'intro-msg-seen';

export enum IntroMessageType {
  SAVE_WORD_AS_FAV = 'save_word_as_fav',
  SAVE_VIDEO_AS_FAV = 'save_video_as_fav',
  SAVE_ARTICLE_AS_FAV = 'save_article_as_fav',
  SAVE_BOOK_AS_FAV = 'save_book_as_fav',
  SAVE_WORDMAP_AS_FAV = 'save_wordmap_as_fav',
  PLAY_WORD = 'play_word',
  PLAY_ARTICLE = 'play_article',
  PLAY_BOOK = 'play_book',
  CATEGORY_PROGRESS = 'category_progress',
  SELECT_MAIN_CATEGORY = 'select_main_category',
  SELECT_SUB_CATEGORY = 'select_sub_category',
  CONVERSATIONS_SELECT_SCENE = 'conversations_select_scene',
  CONVERSATIONS_TASK = 'conversations_task',
  CONVERSATIONS_WARN = 'conversations_warn',
  CLICK_ON_VIEW = 'click_on_view',
  CLICK_ON_READ = 'click_on_read',
  LAST_BOOK_PAGE = 'last_book_page',
  WORDMAP_CLICK_ON_WORD = 'wordmap_click_on_word',
  WORDMAP_CLICK_ON_LINE = 'wordmap_click_on_line',
}

export default () => {
  const introMsgSeen: Ref<Record<IntroMessageType, boolean>> = ref({
    [IntroMessageType.SAVE_WORD_AS_FAV]: false,
    [IntroMessageType.SAVE_VIDEO_AS_FAV]: false,
    [IntroMessageType.SAVE_ARTICLE_AS_FAV]: false,
    [IntroMessageType.SAVE_BOOK_AS_FAV]: false,
    [IntroMessageType.SAVE_WORDMAP_AS_FAV]: false,
    [IntroMessageType.PLAY_WORD]: false,
    [IntroMessageType.PLAY_ARTICLE]: false,
    [IntroMessageType.PLAY_BOOK]: false,
    [IntroMessageType.SELECT_MAIN_CATEGORY]: false,
    [IntroMessageType.SELECT_SUB_CATEGORY]: false,
    [IntroMessageType.CATEGORY_PROGRESS]: false,
    [IntroMessageType.CONVERSATIONS_SELECT_SCENE]: false,
    [IntroMessageType.CONVERSATIONS_TASK]: false,
    [IntroMessageType.CONVERSATIONS_WARN]: false,
    [IntroMessageType.LAST_BOOK_PAGE]: false,
    [IntroMessageType.CLICK_ON_VIEW]: false,
    [IntroMessageType.CLICK_ON_READ]: false,
    [IntroMessageType.WORDMAP_CLICK_ON_WORD]: false,
    [IntroMessageType.WORDMAP_CLICK_ON_LINE]: false,
  });

  const getIntroMsgSeenFromLs = () => {
    const introMsgSeenFromLs = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (introMsgSeenFromLs) {
      introMsgSeen.value = JSON.parse(introMsgSeenFromLs);
    }
  };

  const setIntroMsgSeen = (type: IntroMessageType) => {
    introMsgSeen.value[type] = true;
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(introMsgSeen.value));
  };

  return {
    state: { introMsgSeen },
    funcs: { setIntroMsgSeen, getIntroMsgSeenFromLs }
  };
};
