<script setup lang="ts">
import Dialog from '@jaaluu/components/Dialog.vue';
import stores from '@jaaluu/stores';
import { t } from '@shared/i18n';

const { themeSettingsIsOpen, inDarkTheme } = stores.settings.state;
const { toggleTheme } = stores.settings.funcs;
</script>

<template>
  <Dialog
    v-model="themeSettingsIsOpen"
    :max-width="400"
    :title="t('settings.colors')"
    @close="() => (themeSettingsIsOpen = false)"
  >
    <v-switch
      color="primary"
      v-model="inDarkTheme"
      @update:model-value="toggleTheme"
      :label="t('settings.dark_mode')"
    ></v-switch>
  </Dialog>
</template>
<style lang="scss" scoped></style>
