import Main from '@jaaluu/Main.vue';
import '@shared/scss/all.scss';
import vuetify from '@shared/vuetify';
import { createApp } from 'vue';
import JsonViewer from 'vue3-json-viewer';
import 'vue3-json-viewer/dist/index.css';
import router from './router';

const app = createApp(Main);
app.use(router);
app.use(vuetify);
app.use(JsonViewer);

router.isReady().then(() => app.mount('#app'));
