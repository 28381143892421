import { LangVoiceType, Language } from '@shared/openapi/jaaluu-api';
import sharedStores from '@shared/shared-stores';
import { user } from '.';

const baseUrl = (import.meta as any).env.VITE_JAALUU_API_URL;

export default () => {
  const { isMaxReqReached } = user.funcs;
  const { notify } = sharedStores.snackbar.funcs;

  const getAudioFileUrl = (
    text: string,
    lang: Language,
    langVoiceType: LangVoiceType = LangVoiceType.FEMALE
  ) => {
    if (isMaxReqReached()) {
      notify({
        type: 'error',
        text: 'You reached the max amount of auido requests',
        icon: 'mdi-alert-circle'
      });
      return;
    }
    const textEncoded = encodeURIComponent(text).replace(/\n/g, '%0A').replaceAll('/', '-');
    return `${baseUrl}/v1/file/audio/${lang}/mp3/${textEncoded}?voice=${langVoiceType}`;
  };
  const getImageFileUrl = (imageUrl: string, dir = 'cache') => {
    if (imageUrl.startsWith('http')) {
      return imageUrl;
    }
    return `${baseUrl}/v1/file/image/${imageUrl}?dir=${dir}`;
  };

  return {
    funcs: {
      getAudioFileUrl,
      getImageFileUrl
    }
  };
};
