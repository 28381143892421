import { AutoTextLangLevel } from '@shared/openapi/jaaluu-api';
import { ref, Ref } from 'vue';

export default () => {
  const autoText: Ref<{
    inclueWords: string[];
    description: string;
    nWords: number;
    langLevel: AutoTextLangLevel;
  }> = ref({
    inclueWords: [],
    description: '',
    nWords: 30,
    langLevel: AutoTextLangLevel.MEDIUM
  });

  return { state: { autoText } };
};
