import { CategoryType, GoogleRssTopic, Language } from '@shared/openapi/jaaluu-api';
import { CATEGORIES_DE } from './categories/de';
import { CATEGORIES_EN } from './categories/en';
import { CATEGORIES_ES } from './categories/es';
import { CATEGORIES_FR } from './categories/fr';
import { CATEGORIES_IT } from './categories/it';
import { CATEGORIES_PL } from './categories/pl';
import { CATEGORIES_PT } from './categories/pt';
import { CATEGORIES_RU } from './categories/ru';

const TRANS: {
  [key: string]: {
    general: {
      feeds: string;
      videos: string;
      kids: string;
      wikipedia: string;
      books: string;
      kids_lexicon: string;
      conversations: string;
      write: string;
    };
    feed_topics: {
      [key in GoogleRssTopic]: string;
    };
    chat: {
      chat: string;
    };
    category: {
      show_all_about: string;
      [CategoryType.PHRASES]: string;
      [CategoryType.SAYINGS]: string;
      [CategoryType.SIMPLE_TEXTS]: string;
      [CategoryType.CONVERSATIONS]: string;
      [CategoryType.WRITE]: string;
      categories: any;
    };
    custom: {
      own_text: string;
    };
    special_vocabulary: {
      special_vocabulary: string;
    };
    write: {
      subject: string;
      message: string;
      from: string;
      to: string;
      send: string;
    };
  };
} = {
  [Language.DE]: {
    general: {
      feeds: 'Nachrichten',
      videos: 'Videos',
      kids: 'Kinder',
      wikipedia: 'Wikipedia',
      books: 'Bücher',
      kids_lexicon: 'Lexikon für Kinder',
      conversations: 'Konversationen',
      write: 'Schreiben'
    },
    feed_topics: {
      world: 'WELT',
      business: 'WIRTSCHAFT',
      technology: 'TECHNOLOGIE',
      entertainment: 'UNTERHALTUNG',
      science: 'WISSENSCHAFT',
      sports: 'SPORT',
      health: 'GESUNDHEIT',
      nation: 'NATION'
    },
    chat: {
      chat: 'Chat'
    },
    category: {
      show_all_about: 'Alles anzeigen über',
      categories: CATEGORIES_DE,
      [CategoryType.PHRASES]: 'Phrasen',
      [CategoryType.SIMPLE_TEXTS]: 'Einfache Texte',
      [CategoryType.SAYINGS]: 'Redewendungen',
      [CategoryType.CONVERSATIONS]: 'Konversationen',
      [CategoryType.WRITE]: 'Schreiben'
    },
    custom: {
      own_text: 'Eigener Text'
    },
    special_vocabulary: {
      special_vocabulary: 'Spezieller Wortschatz'
    },
    write: {
      subject: 'Betreff',
      message: 'Nachricht',
      from: 'Von',
      to: 'An',
      send: 'Senden'
    }
  },
  [Language.EN]: {
    general: {
      feeds: 'News',
      videos: 'Videos',
      kids: 'Kids',
      wikipedia: 'Wikipedia',
      books: 'Books',
      kids_lexicon: 'Kids lexicon',
      conversations: 'Conversations',
      write: 'Write'
    },
    feed_topics: {
      world: 'WORLD',
      business: 'BUSINESS',
      technology: 'TECHNOLOGY',
      entertainment: 'ENTERTAINMENT',
      science: 'SCIENCE',
      sports: 'SPORTS',
      health: 'HEALTH',
      nation: 'NATION'
    },
    chat: {
      chat: 'Chat'
    },
    category: {
      show_all_about: 'Show all about',
      [CategoryType.PHRASES]: 'Phrases',
      [CategoryType.SIMPLE_TEXTS]: 'Simple texts',
      [CategoryType.SAYINGS]: 'Sayings',
      [CategoryType.CONVERSATIONS]: 'Conversations',
      [CategoryType.WRITE]: 'Write',
      categories: CATEGORIES_EN
    },
    custom: {
      own_text: 'Own text'
    },
    special_vocabulary: {
      special_vocabulary: 'Special vocabulary'
    },
    write: {
      subject: 'Subject',
      message: 'Message',
      from: 'From',
      to: 'To',
      send: 'Send'
    }
  },
  [Language.ES]: {
    general: {
      feeds: 'Noticias',
      videos: 'Videos',
      kids: 'Niños',
      wikipedia: 'Wikipedia',
      books: 'Libros',
      kids_lexicon: 'Lexicon para niños',
      conversations: 'Conversaciones',
      write: 'Escribir'
    },
    feed_topics: {
      world: 'MUNDO',
      business: 'NEGOCIOS',
      technology: 'TECNOLOGÍA',
      entertainment: 'ENTRETENIMIENTO',
      science: 'CIENCIA',
      sports: 'DEPORTES',
      health: 'SALUD',
      nation: 'NACIÓN'
    },
    chat: {
      chat: 'Chat'
    },
    category: {
      show_all_about: 'Mostrar todo sobre',
      [CategoryType.PHRASES]: 'Frases',
      [CategoryType.SIMPLE_TEXTS]: 'Textos simples',
      [CategoryType.SAYINGS]: 'Dichos',
      [CategoryType.CONVERSATIONS]: 'Conversaciones',
      [CategoryType.WRITE]: 'Escribir',
      categories: CATEGORIES_ES
    },
    custom: {
      own_text: 'Texto propio'
    },
    special_vocabulary: {
      special_vocabulary: 'Vocabulario especial'
    },
    write: {
      subject: 'Asunto',
      message: 'Mensaje',
      from: 'De',
      to: 'Para',
      send: 'Enviar'
    }
  },
  [Language.FR]: {
    general: {
      feeds: 'Nouvelles',
      videos: 'Vidéos',
      kids: 'Enfants',
      wikipedia: 'Wikipedia',
      books: 'Livres',
      kids_lexicon: 'Lexique pour enfants',
      conversations: 'Conversations',
      write: 'Écrire'
    },
    feed_topics: {
      world: 'MONDE',
      business: 'AFFAIRES',
      technology: 'TECHNOLOGIE',
      entertainment: 'DIVERTISSEMENT',
      science: 'SCIENCE',
      sports: 'SPORTS',
      health: 'SANTÉ',
      nation: 'NATION'
    },
    chat: {
      chat: 'Chat'
    },
    category: {
      show_all_about: 'Tout afficher sur',
      [CategoryType.PHRASES]: 'Phrases',
      [CategoryType.SIMPLE_TEXTS]: 'Textes simples',
      [CategoryType.SAYINGS]: 'Proverbes',
      [CategoryType.CONVERSATIONS]: 'Conversation',
      [CategoryType.WRITE]: 'Écrire',
      categories: CATEGORIES_FR
    },
    custom: {
      own_text: 'Texte propre'
    },
    special_vocabulary: {
      special_vocabulary: 'Vocabulaire spécial'
    },
    write: {
      subject: 'Sujet',
      message: 'Message',
      from: 'De',
      to: 'À',
      send: 'Envoyer'
    }
  },
  [Language.IT]: {
    general: {
      feeds: 'Notizie',
      videos: 'Video',
      kids: 'Bambini',
      wikipedia: 'Wikipedia',
      books: 'Libri',
      kids_lexicon: 'Enciclopedia per bambini',
      conversations: 'Conversazioni',
      write: 'Scrivere'
    },
    feed_topics: {
      world: 'MONDO',
      business: 'AFFARI',
      technology: 'TECNOLOGIA',
      entertainment: 'INTRATTENIMENTO',
      science: 'SCIENZA',
      sports: 'SPORT',
      health: 'SALUTE',
      nation: 'NAZIONE'
    },
    chat: {
      chat: 'Chat'
    },
    category: {
      show_all_about: 'Mostra tutto su',
      [CategoryType.PHRASES]: 'Frasi',
      [CategoryType.SIMPLE_TEXTS]: 'Testi semplici',
      [CategoryType.SAYINGS]: 'Detto',
      [CategoryType.CONVERSATIONS]: 'Conversazioni',
      [CategoryType.WRITE]: 'Scrivere',
      categories: CATEGORIES_IT
    },
    custom: {
      own_text: 'Proprio testo'
    },
    special_vocabulary: {
      special_vocabulary: 'Vocabolario speciale'
    },
    write: {
      subject: 'Soggetto',
      message: 'Messaggio',
      from: 'Da',
      to: 'A',
      send: 'Inviare'
    }
  },
  [Language.PT]: {
    general: {
      feeds: 'Notícias',
      videos: 'Vídeos',
      kids: 'Crianças',
      wikipedia: 'Wikipedia',
      books: 'Livros',
      kids_lexicon: 'Enciclopédia para crianças',
      conversations: 'Conversas',
      write: 'Escrever'
    },
    feed_topics: {
      world: 'MUNDO',
      business: 'NEGÓCIOS',
      technology: 'TECNOLOGIA',
      entertainment: 'ENTRETENIMENTO',
      science: 'CIÊNCIA',
      sports: 'ESPORTES',
      health: 'SAÚDE',
      nation: 'NAÇÃO'
    },
    chat: {
      chat: 'Chat'
    },
    category: {
      show_all_about: 'Mostrar tudo sobre',
      [CategoryType.PHRASES]: 'Frases',
      [CategoryType.SIMPLE_TEXTS]: 'Textos simples',
      [CategoryType.SAYINGS]: 'Ditos',
      [CategoryType.CONVERSATIONS]: 'Conversas',
      [CategoryType.WRITE]: 'Escrever',
      categories: CATEGORIES_PT
    },
    custom: {
      own_text: 'Texto próprio'
    },
    special_vocabulary: {
      special_vocabulary: 'Vocabulário especial'
    },
    write: {
      subject: 'Assunto',
      message: 'Mensagem',
      from: 'De',
      to: 'Para',
      send: 'Enviar'
    }
  },
  [Language.RU]: {
    general: {
      feeds: 'Новости',
      videos: 'Видео',
      kids: 'Дети',
      wikipedia: 'Wikipedia',
      books: 'Книги',
      kids_lexicon: 'Энциклопедия для детей',
      conversations: 'Разговоры',
      write: 'Писать'
    },
    feed_topics: {
      world: 'МИР',
      business: 'БИЗНЕС',
      technology: 'ТЕХНОЛОГИИ',
      entertainment: 'РАЗВЛЕЧЕНИЯ',
      science: 'НАУКА',
      sports: 'СПОРТ',
      health: 'ЗДОРОВЬЕ',
      nation: 'НАЦИЯ'
    },
    chat: {
      chat: 'Чат'
    },
    category: {
      show_all_about: 'Показать все о',
      [CategoryType.PHRASES]: 'Фразы',
      [CategoryType.SIMPLE_TEXTS]: 'Простые тексты',
      [CategoryType.SAYINGS]: 'Поговорки',
      [CategoryType.CONVERSATIONS]: 'Разговоры',
      [CategoryType.WRITE]: 'Писать',
      categories: CATEGORIES_RU
    },
    custom: {
      own_text: 'Собственный текст'
    },
    special_vocabulary: {
      special_vocabulary: 'Специальный словарь'
    },
    write: {
      subject: 'Тема',
      message: 'Сообщение',
      from: 'От',
      to: 'Кому',
      send: 'Отправить'
    }
  },
  /* [Language.ZH]: {
    general: {
      feeds: '新闻',
      videos: '视频',
      wikipedia: '维基百科',
      books: '书籍',
      kids_lexicon: '儿童百科'
    },
    feed_topics: {
      world: '世界',
      business: '商业',
      technology: '技术',
      entertainment: '娱乐',
      science: '科学',
      sports: '体育',
      health: '健康',
      nation: '国家'
    },
    chat: {
      chat: '聊天'
    },
    category: {
      show_all_about: '显示所有关于',
      [CategoryType.PHRASES]: '短语',
      [CategoryType.SIMPLE_TEXTS]: '简单文本',
      [CategoryType.SAYINGS]: '谚语',
      categories: CATEGORIES_ZH
    },
    custom: {
      own_text: '自己的文本'
    }
  }, */
  [Language.PL]: {
    general: {
      feeds: 'Wiadomości',
      videos: 'Filmy',
      kids: 'Dzieci',
      wikipedia: 'Wikipedia',
      books: 'Książki',
      kids_lexicon: 'Encyklopedia dla dzieci',
      conversations: 'Rozmowy',
      write: 'Pisać'
    },
    feed_topics: {
      world: 'ŚWIAT',
      business: 'BIZNES',
      technology: 'TECHNOLOGIA',
      entertainment: 'ROZRYWKA',
      science: 'NAUKA',
      sports: 'SPORT',
      health: 'ZDROWIE',
      nation: 'PAŃSTWO'
    },
    chat: {
      chat: 'Czat'
    },
    category: {
      show_all_about: 'Pokaż wszystko o',
      [CategoryType.PHRASES]: 'Zwroty',
      [CategoryType.SIMPLE_TEXTS]: 'Proste teksty',
      [CategoryType.SAYINGS]: 'Przysłowia',
      [CategoryType.CONVERSATIONS]: 'Rozmowy',
      [CategoryType.WRITE]: 'Pisać',
      categories: CATEGORIES_PL
    },
    custom: {
      own_text: 'Własny tekst'
    },
    special_vocabulary: {
      special_vocabulary: 'Specjalne słownictwo'
    },
    write: {
      subject: 'Temat',
      message: 'Wiadomość',
      from: 'Od',
      to: 'Do',
      send: 'Wyślij'
    }
  }
};

export default () => {
  return {
    consts: { TRANS }
  };
};
