<script setup lang="ts">
import AudioVideoSettings from '@jaaluu/components/Settings/AudioVideoSettings.vue';
import LangSettings from '@jaaluu/components/Settings/LangSettings.vue';
import ThemeSettings from '@jaaluu/components/Settings/ThemeSettings.vue';
import SideMenu from '@jaaluu/components/SideMenu/SideMenu.vue';
import TextFieldWithButton from '@jaaluu/components/TextFieldWithButton.vue';
import stores from '@jaaluu/stores';
import { t } from '@shared/i18n';
import { Language } from '@shared/openapi/jaaluu-api';
import { onMounted, ref, Ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const { langSettingsOpen } = stores.settings.state;
const { openTooltip } = stores.tooltip.funcs;
const { langNative, langForeign, langOptions } = stores.language.state;
const { logoBall, logoWide } = stores.assets.state;
const { inFullscreen } = stores.settings.state;

const router = useRouter();

const routeToHome = () => {
  router.push(`/${langForeign.value}`);
  
};

const _langForeign: Ref<Language | null> = ref(null);
const _langNative: Ref<Language | null> = ref(null);
const _langForeignTrans: Ref<Language | null> = ref(null);
const _langNativeTrans: Ref<Language | null> = ref(null);

onMounted(() => {
  setLang();
});

const setLang = () => {
  if (!langForeign.value || !langNative.value) {
    console.error('Not all languages set. Lang foreign and native', langForeign.value, langNative.value);
    return;
  }
  _langForeign.value = langForeign.value;
  _langNative.value = langNative.value;
  _langForeignTrans.value = langForeign.value;
  _langNativeTrans.value = langNative.value;
};

const getTranslation = (translate: string, event: any) => {
  if (!translate) return;
  openTooltip(
    event,
    translate,
    null,
    translate.split(' ').length > 1 ? true : false,
    _langNativeTrans.value === langForeign.value ? true : false
  );
};

const switchLangs = () => {
  _langNativeTrans.value =
    _langNativeTrans.value === langForeign.value ? langNative.value : langForeign.value;
  _langForeignTrans.value =
    _langForeignTrans.value === langNative.value ? langForeign.value : langNative.value;
};

watch(
  () => langForeign.value,
  () => {
    setLang();
  }
);
watch(
  () => langNative.value,
  () => {
    setLang();
  }
);
watch(
  () => langSettingsOpen.value,
  () => {
    setLang();
  }
);
</script>

<template>
  <v-app-bar app class="w-full appbar" v-if="!inFullscreen">
    <div class="d-flex mx-auto max-width align-center justify-space-between px-2">
      <div class="grab" v-ripple>
        <v-img
          @click="routeToHome"
          transition="scale-transition"
          alt="Jaaluu"
          class="mt-1 cursor-pointer d-md-block d-none"
          contain
          width="170"
          :src="logoWide"
        />
        <v-img
          @click="routeToHome"
          transition="scale-transition"
          alt="Jaaluu"
          class="mt-1 cursor-pointer d-block d-md-none mr-2"
          contain
          :src="logoBall"
          width="35"
        />
      </div>
      <div class="d-flex align-center">
        <v-btn size="18" icon class="mb-n4" @click="() => switchLangs()" ><v-icon icon="mdi-swap-vertical" size="14" /></v-btn>
        <TextFieldWithButton
          clearable
          hide-details
          class="mt-1"
          @search="(text: string, e: Event) => getTranslation(text, e)"
        >
          <div class="">
            <div
              v-if="langOptions && _langNativeTrans && _langForeignTrans"
              class="d-flex align-center"
            >
              <div>{{ t('general.translate') }}</div>
              <div class="flag-wrapper ml-2">
                <v-img
                  :src="langOptions.find((o) => o.value === _langForeignTrans)?.flag"
                  alt=""
                  class="flag-icon"
                />
              </div>
              <v-icon icon="mdi-arrow-right-thin" size="12" @click="() => switchLangs()" />
              <div class="flag-wrapper">
                <v-img
                  :src="langOptions.find((o) => o.value === _langNativeTrans)?.flag"
                  alt=""
                  class="flag-icon"
                />
              </div>
            </div>
          </div>
        </TextFieldWithButton>
      </div>
      <SideMenu />
      <LangSettings />
      <ThemeSettings />
      <AudioVideoSettings />
    </div>
  </v-app-bar>
</template>

<style scoped lang="scss">
.v-theme--darkTheme {
  .appbar {
    background-color: rgb(var(--v-theme-background-darken-1));
  }
}
.flag-icon {
  width: 11px;
}
.flag-wrapper {
  height: 7px;
}
</style>
