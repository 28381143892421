<script lang="ts" setup>
import Dialog from '@jaaluu/components/Dialog.vue';
import { CookieOption } from '@jaaluu/interfaces/CookieOption';
import { GAnalyticsEvent } from '@jaaluu/interfaces/GAnalyticEvent';
import stores from '@jaaluu/stores';
import vuetify from '@shared/vuetify';

const { setCookieConsentInLs } = stores.cookies.funcs;
const { sendEvent } = stores.gAnalytics.funcs;
const { userConsent, cookieDialogIsOpen } = stores.cookies.state;
const { TOOLTIPS } = stores.cookies.consts;

const tooltipMobile = {
  text: 'We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.',
  scrollStrategy: 'close',
  scrim: true,
  persistent: false,
  openOnClick: true,
  openOnHover: false
};

const atAllowOrDeny = (value: boolean) => {
  userConsent.value.marketing = value;
  userConsent.value.statistics = value;
  userConsent.value.preferences = value;
  setCookieConsentInLs();
  cookieDialogIsOpen.value = false;
  if (value) {
    sendEvent(GAnalyticsEvent.CLICK_COOKIE_ACCEPT_ALL);
  } else {
    sendEvent(GAnalyticsEvent.CLICK_COOKIE_DENY_ALL);
  }
};

const atAllowSelection = () => {
  setCookieConsentInLs();
  cookieDialogIsOpen.value = false;
  sendEvent(GAnalyticsEvent.CLICK_COOKIE_ACCEPT_SELECTED, {
    cookieSettings: cookieDialogIsOpen.value
  });
};
</script>

<template>
  <Dialog
    :title="'This website uses cookies'"
    persistent
    v-model="cookieDialogIsOpen"
    :max-width="650"
    :show-close="false"
  >
    <div>
      <p v-if="vuetify.display.mdAndUp.value">
        We use cookies to personalise content and ads, to provide social media features and to
        analyse our traffic. We also share information about your use of our site with our social
        media, advertising and analytics partners who may combine it with other information that
        you’ve provided to them or that they’ve collected from your use of their services.
      </p>
      <p v-else>
        We use cookies to personalise content and ads, to provide social media features and to
        analyse our traffic...
        <v-icon
          icon="mdi-information-outline"
          size="12"
          color="gray"
          class="ml-1 grab"
          v-tooltip="tooltipMobile"
        />
      </p>
    </div>

    <div class="d-block d-md-flex justify-space-between align-center">
      <div class="d-flex align-center" v-for="c in Object.values(CookieOption) as CookieOption[]">
        <v-switch
          hide-details
          color="green"
          :label="c[0].toUpperCase() + c.slice(1).toLowerCase()"
          v-model="userConsent[c]"
          :disabled="c === CookieOption.NECESSARY"
        />
        <v-icon
          size="12"
          icon="mdi-information-outline"
          color="gray"
          class="ml-1 grab"
          v-tooltip="{ ...tooltipMobile, text: TOOLTIPS[c] }"
        />
      </div>
    </div>
    <div class="d-flex justify-space-between mt-4">
      <div class="">
        <v-btn
          size="x-small"
          class="mr-2"
          @click="() => atAllowOrDeny(false)"
          color="green"
          variant="outlined"
          >Deny</v-btn
        >
        <v-btn
          size="x-small"
          @click="() => atAllowSelection()"
          color="green"
          variant="outlined"
          >Allow selection</v-btn
        >
      </div>
      <v-btn @click="() => atAllowOrDeny(true)" color="green">OK</v-btn>
    </div>
  </Dialog>
</template>

<style scoped lang="scss"></style>
