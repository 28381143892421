
import { client as JaaluuClient } from '@shared/openapi/jaaluu-api/services.gen';
import { loading } from '@shared/shared-stores/index';
import { AxiosResponse } from 'axios';


export default () => {
  const { decrement, increment } = loading.funcs;

  const increasCountAndAddData = (config: any) => {
    increment();
    return config
  };

  const decreaseCount = async (error: any) => {
    decrement();
    return Promise.reject(error);
  };
  const decreaseCountSuccess = (res: AxiosResponse) => {
    decrement();
    return res;
  };

  JaaluuClient.instance.interceptors.request.use(increasCountAndAddData, decreaseCount);
  JaaluuClient.instance.interceptors.response.use(decreaseCountSuccess, decreaseCount);

  return { state: { } };
};
