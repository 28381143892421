<script setup lang="ts">
import Dialog from '@jaaluu/components/Dialog.vue';
import stores from '@jaaluu/stores';
import { t } from '@shared/i18n';
const { audioVideoSettingsIsOpen, playbackRate } = stores.settings.state;
</script>

<template>
  <Dialog
    v-model="audioVideoSettingsIsOpen"
    :max-width="400"
    :title="t('general.settings')"
    @close="() => (audioVideoSettingsIsOpen = false)"
  >
    <v-select
      v-model="playbackRate"
      :items="[0.6, 0.7, 0.8, 0.9, 1, 1.25, 1.5, 1.75, 2]"
      density="compact"
      hide-details
      class=""
      color="green"
      variant="underlined"
      :label="`Audio Speed`"
    />
  </Dialog>
</template>
<style lang="scss" scoped></style>
