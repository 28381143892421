import { Tour } from '@jaaluu/interfaces/TourGuide';
import { t } from '@shared/i18n';
import { ref, Ref } from 'vue';
import { favorites, language, settings } from '.';

const LOCAL_STORAGE_KEY = 'guide-seen';
const SRS_SEEN_TIME_LS_KEY = 'srs-seen-time';
const BASE_STEPS_ID = 'base-steps';

export default () => {
  const { getFavWordsForReviewSrc } = favorites.funcs;
  const { wordsToLearn } = favorites.state;
  const { notifyMeSrs } = settings.state;
  const { langForeign } = language.state;

  const tourGuideIsOpen = ref(false);
  const currentTour: Ref<Tour | null> = ref(null);
  const seenLocalStorage: Ref<Record<string, boolean>> = ref({});
  const localStorageLoaded = ref(false);

  const endTour = () => {
    seenLocalStorage.value[currentTour.value!.id] = true;
    if (currentTour.value!.includesBaseSteps) {
      seenLocalStorage.value[BASE_STEPS_ID] = true;
    }
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(seenLocalStorage.value));
    currentTour.value = null;
    tourGuideIsOpen.value = false;
  };

  const getSeenGuidesLocalStorage = () => {
    const seen = localStorage.getItem(LOCAL_STORAGE_KEY);
    localStorageLoaded.value = true;
    if (!seen) return;
    seenLocalStorage.value = JSON.parse(seen);
  };

  const shoudNotOpen = (id: string) => {
    return !localStorageLoaded.value || seenLocalStorage.value[id] || tourGuideIsOpen.value;
  };

  const initClickOnWordAndSentence = () => {
    const id = 'click-on-word-and-sentence';
    if (shoudNotOpen(id)) return;
    const tour: Tour = {
      id,
      steps: [
        {
          title: t('tour_guide.click_on_every_word.title'),
          text: t('tour_guide.click_on_every_word.text'),
          icon: 'mdi-translate'
        },
        {
          title: t('tour_guide.click_on_i_for_sentence.title'),
          text: t('tour_guide.click_on_i_for_sentence.text'),
          icon: 'mdi-information-variant'
        }
      ]
    };
    currentTour.value = tour;
    tourGuideIsOpen.value = true;
  };

  const initChangePlaybackRate = () => {
    const id = 'change-playback-rate';
    if (shoudNotOpen(id)) return;
    const tour: Tour = {
      id,
      steps: [
        {
          title: t('tour_guide.change_playback_rate.title'),
          text: t('tour_guide.change_playback_rate.text'),
          icon: 'mdi-speedometer'
        }
      ]
    };
    currentTour.value = tour;
    tourGuideIsOpen.value = true;
  };

  const initTourNoSaveCustom = () => {
    const id = 'no-save-custom';
    if (shoudNotOpen(id)) return;
    const tour: Tour = {
      id,
      steps: [
        {
          title: t('tour_guide.no_save_custom.title'),
          text: t('tour_guide.no_save_custom.text'),
          icon: 'mdi-information-variant'
        }
      ]
    };
    currentTour.value = tour;
    tourGuideIsOpen.value = true;
  };

  const initTheme = () => {
    const id = 'theme-tour';
    if (shoudNotOpen(id)) return;
    const tour: Tour = {
      id,
      steps: [
        {
          title: t('tour_guide.theme.title'),
          text: t('tour_guide.theme.text'),
          icon: 'mdi-theme-light-dark'
        }
      ]
    };
    currentTour.value = tour;
    tourGuideIsOpen.value = true;
  };

  const initMenu = () => {
    const id = 'menu-tour';
    if (shoudNotOpen(id)) return;
    const tour: Tour = {
      id,
      steps: [
        {
          title: t('tour_guide.explain_menu.title'),
          text: t('tour_guide.explain_menu.text'),
          icon: 'mdi-menu'
        }
      ]
    };
    currentTour.value = tour;
    tourGuideIsOpen.value = true;
  };

  const tourRepeatWords = () => {
    setTimeout(() => {
      if (!notifyMeSrs.value) return;
      const _wordsToLearn = getFavWordsForReviewSrc();
      const lastSeenUnix = localStorage.getItem(SRS_SEEN_TIME_LS_KEY);
      if (_wordsToLearn.length === 0) return;
      // only remind every 180 minutes
      if (lastSeenUnix && parseInt(lastSeenUnix) > new Date().getTime() - 1000 * 60 * 180) return;
      const callToAction = (router: any) => {
        wordsToLearn.value = _wordsToLearn;
        router.push(`/${langForeign.value}/learn/exercises`);
      };
      const tour: Tour = {
        id: 'repeat-words',
        steps: [
          {
            title: t('tour_guide.repeat_words.title'),
            text: t('tour_guide.repeat_words.text', { nWords: _wordsToLearn.length }),
            callToAction: {
              text: t('tour_guide.repeat_words.call_to_action'),
              action: callToAction
            },
            icon: 'mdi-weight-lifter'
          }
        ]
      };
      currentTour.value = tour;
      tourGuideIsOpen.value = true;
      localStorage.setItem(SRS_SEEN_TIME_LS_KEY, new Date().getTime().toString());
    }, 3000);
  };

  return {
    state: { tourGuideIsOpen, currentTour },
    funcs: {
      endTour,
      getSeenGuidesLocalStorage,
      initTourNoSaveCustom,
      initChangePlaybackRate,
      initTheme,
      initMenu,
      tourRepeatWords,
      initClickOnWordAndSentence
    }
  };
};
