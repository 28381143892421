import { ref } from 'vue';
import { CookieOption } from '@jaaluu/interfaces/CookieOption';

const COOKIE_CONSENT_KEY = 'cookieConsents';

const TOOLTIPS = {
  [CookieOption.NECESSARY]:
    'Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.',
  [CookieOption.PREFERENCES]:
    'Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.',
  [CookieOption.STATISTICS]:
    'Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.',
  [CookieOption.MARKETING]:
    'Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.'
};

export default () => {
  const cookieDialogIsOpen = ref(false);
  const userConsent = ref({
    [CookieOption.NECESSARY]: true,
    [CookieOption.PREFERENCES]: true,
    [CookieOption.STATISTICS]: true,
    [CookieOption.MARKETING]: true
  });

  const setCookieConsentInLs = () => {
    localStorage.setItem(COOKIE_CONSENT_KEY, JSON.stringify(userConsent.value));
    updateGTagConsent();
  };

  const getCookieConsentFromLs = () => {
    const userConsentLs = localStorage.getItem(COOKIE_CONSENT_KEY);
    if (userConsentLs) {
      userConsent.value = JSON.parse(userConsentLs);
      updateGTagConsent();
    } else {
      cookieDialogIsOpen.value = true;
    }
  };

  const updateGTagConsent = () => {
    const { preferences, statistics, marketing } = userConsent.value;
    if ((window as any).gtag) {
      console.log('Updating gtag consent ', { preferences, statistics, marketing });
      (window as any).gtag('consent', 'update', {
        ad_storage: marketing ? 'granted' : 'denied',
        ad_user_data: preferences ? 'granted' : 'denied',
        analytics_storage: statistics ? 'granted' : 'denied',
        ad_personalization: preferences ? 'granted' : 'denied',
        wait_for_update: 500
      });
    }
  }

  return {
    state: { userConsent, cookieDialogIsOpen },
    funcs: { setCookieConsentInLs, getCookieConsentFromLs, updateGTagConsent },
    consts: { TOOLTIPS }
  };
};
