<script setup lang="ts">
import anime from 'animejs';
import { defineProps, onMounted, ref } from 'vue';
const props = defineProps({
  title: {
    type: String,
    required: true
  },
  contents: {
    type: Array,
    required: true
  },
  animate: {
    type: Boolean,
    required: false,
    default: false
  }
});
const ID = Math.random().toString(36).substr(2, 9);
const isVisible = ref(false);

onMounted(() => {
  if (props.animate) {
    window.addEventListener('scroll', onScroll);
  }
});

const onScroll = () => {
  const rect = document.getElementById(ID)?.getBoundingClientRect();
  if (!rect || rect.top < 0) return;
  let visibleHeight =
    Math.max(0, Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0)) / rect.height;

  const duration = 750;
  if (visibleHeight !== null && visibleHeight < 0.3 && isVisible) {
    isVisible.value = false;
    anime({
      targets: `.col-${ID}`,
      translateY: window.innerWidth / 2 + 200,
      opacity: 0,
      easing: 'easeInOutQuad',
      duration
    });
  } else if (!isVisible.value) {
    isVisible.value = true;
    anime({
      targets: `.col-${ID}`,
      translateY: 0,
      opacity: 1,
      easing: 'easeInOutQuad',
      duration
    });
  }
};
</script>
<template>
  <div class="welcome-content-width mt-16 mx-auto" :id="ID">
    <div class="">
      <h2 class="title-welcome text-center">{{ props.title }}</h2>
      <div class="d-flex flex-wrap">
        <div
          v-for="(cont, idx) in props.contents"
          class="w-100 w-md-33 text-center"
          :class="`col-${ID}`"
        >
          <video :id="`video-${idx}`" playsinline autoplay muted loop class="video mt-12">
            <source :src="cont.video" type="video/mp4" />
          </video>
          <div class="text-welcome text-center text mx-auto mt-n6" :id="`text-${idx}`">
            <v-card  elevation="2" variant="outlined" color="primary" class="pa-2">
              {{ cont.text }}
            </v-card>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-center">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.title-welcome {
  font-size: 2rem;
}
.text-welcome {
  font-size: 1.5rem;
}
video {
  width: 250px;
  filter: grayscale(0);
}
.text {
  right: 50%;
  padding-right: 20px;
  width: 280px;
}

.flex-dir-reverse {
  flex-direction: row-reverse;
}
.title-welcome {
  font-size: 2rem;
}
.text-welcome {
  font-size: 1.5rem;
}
</style>
