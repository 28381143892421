import { Book, BooksRes } from '@jaaluu/interfaces/Books';
import { BooksService, Language } from '@shared/openapi/jaaluu-api';
import axios, { AxiosResponse } from 'axios';
import { Ref, ref } from 'vue';
import { error } from '.';

const GUTINDEX_URL = 'https://gutendex.com/books';
const RESTRICTED_AUTHORS = ['Mann, Thomas', 'Döblin, Alfred'];

export default () => {
  const books: Ref<Book[]> = ref([]);
  const { handleDefaultError } = error.funcs;

  const getBooks = (load: number, lang: Language) => {
    const params = {
      languages: lang,
      author_year_start: 1900,
      mime_type: 'text'
    };
    return axios
      .get(GUTINDEX_URL, { params })
      .then((res: AxiosResponse<BooksRes>) => {
        if (res.data) {
          const restrictedBookIds = res.data.results
            .filter((b) =>
              b.authors.map((a) => a.name).some((an) => RESTRICTED_AUTHORS.includes(an))
            )
            .map((b) => b.id);
          books.value = res.data.results
            .filter((b) => !restrictedBookIds.includes(b.id))
            .splice(0, load);
        }
      })
      .catch(handleDefaultError);
  };

  const getBook = (id: number) => {
    return axios.get(`${GUTINDEX_URL}/${id}`);
  };

  const getBookContent = (book_url: string) => {
    return BooksService.getBookContentV1BooksContentBookUrlGet({ path: { book_url } }).then(
      (res) => {
        console.log(res);
        return res.data;
      }
    );
  };

  const searchBooks = (search: string, lang: Language) => {
    const params = {
      languages: lang,
      search,
      author_year_start: 1900,
      mime_type: 'text',
      submit_search: 'Search'
    };
    return axios
      .get(GUTINDEX_URL, { params })
      .then((res: AxiosResponse<BooksRes>) => {
        if (res.data) {
          const restrictedBookIds = res.data.results
            .filter((b) =>
              b.authors.map((a) => a.name).some((an) => RESTRICTED_AUTHORS.includes(an))
            )
            .map((b) => b.id);
          books.value = res.data.results
            .filter((b) => !restrictedBookIds.includes(b.id))
        }
      })
      .catch(handleDefaultError);
  };

  return {
    state: { books },
    funcs: { getBooks, searchBooks, getBook, getBookContent }
  };
};
