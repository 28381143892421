<script setup lang="ts">
import logoBall from '@jaaluu/assets/images/logo_ball_trans.png';
import anime from 'animejs';
import { onMounted, ref, Ref } from 'vue';

const styles: Ref<{ width: string; top: string; left: string }[]> = ref([]);
const opacity = ref(0);


const props = defineProps({
  nLogos: {
    type: Number,
    required: false,
    default: 100
  },
});

onMounted(() => {
  for (let i = 0; i < props.nLogos; i++) {
    styles.value.push({
      width: `${Math.random() + 5}px`,
      top: `${Math.random() * 100}%`,
      left: `${Math.random() * 100}%`
    });
  }
  setTimeout(() => {
    randomCircularMotion();
    opacity.value = 0.7;
  }, 500);
});

const randomCircularMotion = () => {
  const dur = 8000;
  anime({
    targets: '.logo-ball-animated',
    scale: [
      {value: () => anime.random(0.9, 1.1),  duration: () => anime.random(0, dur)},
      {value: () => anime.random(0.8, 1.6),  duration: () => anime.random(0, dur)},
      {value: () => 1,  duration: () => anime.random(0, dur)},
    ],
    translateY: [
      { value: () => anime.random(0, 20), duration: anime.random(0, dur), easing: 'easeInOutSine' },
      { value: () => anime.random(0, 20), duration: anime.random(0, dur), easing: 'easeInOutSine' },
      { value: 0, duration: () => anime.random(0, dur), easing: 'easeInOutSine' },
    ],
    translateX: [
      { value: () => anime.random(0, 20), duration: anime.random(0, dur), easing: 'easeInOutSine' },
      { value: () => anime.random(0, 20), duration: anime.random(0, dur), easing: 'easeInOutSine' },
      { value: 0,  duration: () => anime.random(0, dur), easing: 'easeInOutSine' },
    ],
    loop: true
  });
};
</script>
<template>
  <v-img
    :src="logoBall"
    class="logo-ball-animated"
    :style="{ width: style.width, top: style.top, left: style.left, opacity }"
    v-for="style in styles"
  />
</template>

<style scoped lang="scss">
.logo-ball-animated {
  position: absolute;
  z-index: 10;
  top: 50%;
  right: 0px;
  transform: translate(-50%, -50%);
  transition: opacity 1s ease-in-out;
}
.logo-ball-1 {
  width: 25px;
  top: 600px;
  right: 50px;
}
.logo-ball-2 {
  width: 50px;
  top: 1200px;
  left: 50px;
}
</style>
