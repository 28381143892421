export default {
  welcome: {
    your_language: 'Ваш язык',
    language_to_learn: 'Язык, который вы хотите изучить',
    start_learning: 'Начать обучение',
    how_does_this_work: 'Как это работает?',
    welcome_1: 'Добро пожаловать в JAALUU',
    welcome_2: 'Здесь вы можете изучать языки',
    welcome_3: 'Читать тексты или смотреть видео с субтитрами',
    welcome_4: 'Нажимайте на слово для перевода, примеров и определений',
    main_teaser: 'Улучшайте свои языковые навыки — легко и бесплатно',
    select_language_and_start: 'Выберите язык и начните',
    how_to_title: 'Читайте тексты, книги, новости или смотрите видео',
    how_to_text: 'Вы можете выбрать из различных текстов, книг, новостей или видео',
    click_on_word_title: 'Автоматический перевод, примеры и определения',
    click_on_word_text:
      'Нажмите на любое слово, чтобы получить автоматический перевод, примеры предложений и определения. Вы также можете переводить целые предложения автоматически или связывать слова с изображениями и создавать словесные сети.',
    listen_title: 'Слушайте слова и тексты',
    listen_text:
      'Вы можете слушать любое слово, предложение или целые тексты и тренировать произношение',
    conversations_title: 'Практикуйте разговоры',
    conversations_text:
      'Вы можете практиковать разговоры, общаясь с виртуальным чат-ботом. Вам нужно решать задачи, такие как: `Заказать еду в ресторане` или `Попросить указания.`',
    writing_title: 'Правописание',
    writing_text_1: 'Практикуйте правописание, написав тексты',
    writing_text_2:
      'Ваши тексты будут проверены на ошибки в правописании, содержании и грамматике.',
    texts_and_videos_title: 'Найдите свой уровень',
    texts_and_videos_text:
      'Вы можете выбирать между простыми фразами и текстами для детей, новостями, статьями из Википедии или книгами, в зависимости от того, что вам подходит.',
    srs_title: 'Повторяйте и тренируйте словарный запас',
    srs_text_1:
      'Вы можете сохранять любое слово или целые предложения как избранное для повторения позже',
    srs_text_2:
      'Тренируйте свой словарный запас с помощью флеш-карт и простых упражнений, а также отслеживайте свой прогресс',
    srs_text_3:
      'Система повторений SRS помогает вам учить слова более эффективно, повторяя их в нужное время',
    own_text_title: 'Специализированный словарный запас',
    own_text_text:
      'Вы можете создать текст на определенную тему, чтобы изучить специализированный словарный запас. Вы также можете создавать тексты, содержащие слова, которые хотите выучить.',
    wordmap_title: 'Словесные сети',
    wordmap_text_1: 'Вы можете создавать словесные сети и связывать слова с простыми предложениями',
    wordmap_text_2:
      'Словесные карточки могут быть сохранены как избранные, что позволяет легко повторять и тренировать их',
    sayings_title: 'Идиомы',
    sayings_text:
      'Здесь вы найдете часто используемые фразы и идиомы, классифицированные по категориям. Вы можете отслеживать свой прогресс и продолжать развиваться структурированным образом.',
    details: 'Подробности',
    details_text_and_videos_title:
      'Откройте для себя Jaaluu — Ваш ключ к быстрому и эффективному изучению языков!',
    details_text_and_videos_text:
      'Jaaluu предлагает вам множество текстов и видео для улучшения вашего уровня языка. Платформа идеально подходит, если вы уже понимаете простые слова и предложения. Для новичков есть простые фразы и идиомы, тексты для детей, лексиконы и видео для легкого старта. Для продвинутых учеников — новости, статьи из Википедии, книги и интересные видео для более глубокого понимания, а также интерактивные разговоры с чат-ботом. Кроме того, вы можете тренировать правописание и получать советы по улучшению ошибок в правописании, содержании и грамматике.',
    details_click_on_words_title:
      'Изучение словарного запаса стало легче: быстро, интерактивно и эффективно!',
    details_click_on_words_text:
      'Изучение словарного запаса может быть утомительным, поскольку поиск слов и определений занимает много времени. Jaaluu решает эту проблему: просто нажмите на слово, чтобы мгновенно получить переводы, примеры предложений, определения и объяснения. Это экономит время и помогает вам учить быстрее и эффективнее!',
    details_listen_title:
      'Слушайте и практикуйте: слышите слова, предложения и тексты, чтобы улучшить свои языковые навыки!',
    details_listen_text:
      'Вы можете слушать всё — любое слово, предложение или даже целые тексты. Просто воспроизведите и слушайте! Эта функция помогает вам тренировать правильное произношение и улучшать понимание на слух. Вы также можете слушать разговоры и развивать чувство естественного общения. Отлично подходит для закрепления словарного запаса и укрепления ваших языковых навыков в повседневных ситуациях.',
    details_conversations_title:
      'Практическое языковое обучение: Реалистичные разговоры для повседневной жизни!',
    details_conversations_text:
      'С помощью Jaaluu вы можете практиковать реалистичные разговоры с чат-ботом и готовиться к типичным повседневным ситуациям. Например, вы можете заказать еду в ресторане и оплатить счет или попросить указания. Или вы можете пообщаться с новым коллегой в свой первый день на новой работе. Ваши предложения будут автоматически проанализированы, чтобы дать вам обратную связь и улучшить ваши языковые навыки. Таким образом, вы тренируетесь на практике и эффективно готовитесь к реальным разговорам!',
    details_writing_title:
      'Практика письма для повседневной жизни: улучшите свои навыки с помощью реалистичных заданий!',
    details_writing_text:
      'Вы можете практиковать письмо, осваивая небольшие, реалистичные задания, такие как написание заявлений или жалоб. Ваш текст будет автоматически проанализирован, и вы получите прямую обратную связь по правописанию, содержанию и грамматике. Так вы улучшаете свои навыки письма и готовитесь к повседневной жизни!',
    details_srs_title:
      'Эффективное изучение словарного запаса с помощью SRS: автоматические повторения и персонализированный анализ прогресса!',
    details_srs_text:
      'С системой SRS (Система интервальных повторений) вы можете эффективно учить и повторять слова. Эта система напоминает вам, когда повторить слова, чтобы сохранить их в памяти на долгий срок. SRS основана на научном методе, который утверждает, что повторение через определенные интервалы укрепляет память. Вы также получите статистику по каждому слову и вашему успеху, чтобы вы могли сосредоточиться на слабых местах и всегда знать, как хорошо вы продвигаетесь в изучении словарного запаса.',
    details_own_text_title:
      'Персонализированное обучение: создавайте собственные тексты для целенаправленных упражнений по словарному запасу и темам!',
    details_own_text_text:
      'С помощью Jaaluu вы можете легко создавать свои собственные тексты в областях, на которых хотите сосредоточиться. Опишите область или словарный запас, который вы хотите выучить, и получите текст для чтения и практики. Так вы сможете сосредоточиться на конкретных темах или словах и расширить свои знания в области, которая вам важна. Такой подход помогает учить более эффективно и точно! Кроме того, вы можете создавать тексты, содержащие весь словарный запас, который вы хотите повторить. Это позволяет вам учить и повторять слова в контексте, что облегчает их запоминание.',
    details_wordmap_title: 'Карточки слов: Связывайте свои знания и запоминайте слова с легкостью!',
    details_wordmap_text:
      'Вы можете создавать карточки слов, которые помогут вам связывать слова и запоминать их более эффективно. Добавляйте слова и предложения на карточку и постепенно расширяйте свой словарный запас. Это создает и укрепляет связи в вашем мозге и строит ассоциации в игровой форме. Таким образом, вы улучшаете понимание языка — устойчиво!',
    details_paste_text_title:
      'Используйте свои собственные тексты: вставляйте свой контент, чтобы учить с фокусом!',
    details_paste_text_text:
      'С помощью Jaaluu вы также можете вставить свои собственные тексты для использования в обучении. Это позволяет работать с вашим материалом и тренировать ваш словарный запас в реальных контекстах.',
    site_also_available: 'Сайт также доступен на',
    which_languages_title: 'Какие языки я могу выучить?',
    which_languages_text: 'Здесь вы можете выучить английский, испанский, немецкий, французский, португальский, польский и итальянский языки. Просто выберите язык, который хотите выучить, и все готово!'
  },
  intro: {
    your_level: 'Каков ваш уровень владения языком?',
    beginner_explained: 'Вы можете читать простые предложения',
    advanced_explained: 'Вы можете читать более сложные тексты',
    your_cefr_level: 'Ваш уровень CEFR:',
    lets_start: 'С чего бы вы хотели начать?',
    beginner_options: {
      simple_texts: 'Читать тексты',
      simple_articles: 'Читать статьи',
      sayings: 'Учить выражения',
      view_videos: 'Смотреть видео',
      simple_phrases: 'Читать типичные фразы'
    },
    advanced_options: {
      news: 'Читать новости',
      videos: 'Смотреть видео',
      wikipedia: 'Читать статьи',
      books: 'Читать книги'
    }
  },
  languages: {
    de: 'Немецкий',
    en: 'Английский',
    es: 'Испанский',
    fr: 'Французский',
    it: 'Итальянский',
    pt: 'Португальский',
    ru: 'Русский',
    zh: 'Китайский',
    pl: 'Польский'
  },
  general: {
    beginner: 'Начинающий',
    advanced: 'Продвинутый',
    search: 'Поиск',
    search_imperative: 'Ищите',
    translate: 'Перевести',
    settings: 'Настройки',
    vocabulary: 'Словарь',
    shorts: 'Короткие тексты',
    articles: 'Статьи',
    feeds: 'Новости',
    videos: 'Видео',
    read: 'Читать',
    view: 'Смотреть',
    listen: 'Слушать',
    save: 'Сохранить',
    more: 'ещё',
    back: 'Назад',
    next: 'Далее',
    easy: 'Легко',
    medium: 'Средне',
    hard: 'Трудно',
    all: 'Все',
    level: 'Уровень',
    font_size: 'Размер шрифта',
    language: 'Язык',
    send: 'Отправить',
    image: 'Изображение',
    wordmap: 'Карта слов',
    open: 'Открыть',
    add: 'Добавить',
    question: 'Вопрос',
    start: 'Начать',
    words: 'Слово | Слов'
  },
  app_bar: {
    search_in_foreign_language: 'Поиск на иностранном языке',
    menu: 'Меню'
  },
  category: {
    chapter: 'Глава'
  },
  favorites: {
    favorites: 'Избранное',
    word: 'Слово',
    sentence: 'Предложение',
    sentences: 'Предложения',
    texts_videos: 'Тексты и Видео',
    wordmaps: 'Карты слов',
    saved: 'Сохранено',
    title: 'Название',
    type: 'Тип',
    no_favorites_found: 'Избранное не найдено',
    filtered_by: 'отфильтровано по',
    train: 'Тренировка',
    notify_me_srs: 'Уведомить меня, чтобы повторить словарный запас',
    srs_level: 'Уровень SRS',
    success_rate: 'Процент успешности',
    last_seen: 'В последний раз видели',
    next_review: 'Следующий просмотр',
    success_level_up: 'Слово перемещено на уровень {level}',
    what_is_srs: 'Что такое SRS?',
    srs_short:
      'SRS — это система интервальных повторений. Она помогает вам лучше запоминать слова, повторяя их в нужное время.',
    srs_long:
      'Система основана на принципе, что чем чаще вы повторяете слово, тем лучше вы его запоминаете. Интервалы между повторениями определяются системой. Если вы хорошо запомнили слово, интервалы становятся длиннее. Если у вас возникают трудности, интервалы становятся короче. Так вы можете учить слова более эффективно.',
    srs_intervals: 'Интервалы повторений: {nIntervals} дня(ей): {intervals} дней.',
    when_does_a_word_move: 'Когда слово переходит на следующий уровень?',
    srs_next_level_example_1:
      'Если вы добавите слово в избранное, оно будет на уровне 1. Слово на уровне 1 перейдет на следующий уровень, если пройдет 1 день с момента добавления слова, ваш процент успешности будет выше 50% и вы только что правильно запомнили это слово.',
    srs_next_level_example_2:
      'Слово на уровне {level} перейдет на следующий уровень, если последний переход на уровень {levelM1} был не менее {days} дней назад, ваш процент успешности будет выше 50% и вы только что правильно запомнили это слово.'
  },
  player: {
    maximize: 'Развернуть',
    minimize: 'Свернуть'
  },
  video: {
    error:
      'Произошла ошибка при загрузке субтитров. К сожалению, не все субтитры доступны бесплатно. Пожалуйста, попробуйте другое видео.'
  },
  feeds: {
    disclaimer:
      'Это автоматически сгенерированное резюме с помощью ChatGPT. Оригинальная статья доступна',
    here: 'здесь',
    generating_article: 'Генерация статьи...',
    error:
      'Произошла ошибка при загрузке статьи. К сожалению, не все статьи доступны бесплатно. Пожалуйста, попробуйте другую статью или скопируйте текст вручную',
    open_org: '1. Откройте оригинальную статью и скопируйте текст',
    open_own: '2. Вставьте текст сюда'
  },
  books: {
    read_now: 'Читать сейчас',
    continue_on_page: 'Продолжить на странице'
  },
  tooltip: {
    examples: 'Примеры',
    emotional_examples: 'Эмоциональные примеры',
    definition: 'Определение',
    synonyms: 'Синонимы',
    antonyms: 'Антонимы',
    article: 'Артикль',
    conjugations: 'Спряжение',
    grammer: 'Грамматика'
  },
  custom_text: {
    enter_your_own_text: 'Введите ваш текст',
    read_text: 'Читать текст',
    enter_new_text: 'Введите новый текст',
    own_text: 'Скопируйте свой текст и вставьте его сюда',
    special_vocabulary: 'Специальная лексика'
  },
  special_vocabulary: {
    special_vocabulary: 'Специальный словарь',
    describe:
      'Введите текст на английском языке по теме или специальному словарному запасу, который вы хотите выучить',
    language_level: 'Уровень языка',
    generate_text: 'Сгенерировать текст'
  },
  intro_message: {
    save_word_as_fav: 'Сохранить слово в избранное',
    save_video_as_fav: 'Сохранить видео в избранное',
    save_article_as_fav: 'Сохранить статью в избранное',
    save_book_as_fav: 'Сохранить книгу в избранное',
    save_wordmap_as_fav: 'Сохранить карту слов в избранное',
    play_word: 'Послушать произношение слова',
    play_article: 'Послушать статью',
    play_book: 'Послушать книгу',
    select_main_category: 'Выберите главную категорию',
    select_sub_category: 'Выберите подкатегорию',
    click_on_read: 'Нажмите «ЧИТАТЬ», чтобы прочитать весь текст',
    click_on_view: 'Нажмите «СМОТРЕТЬ», чтобы посмотреть видео',
    last_book_page: 'Ваша последняя страница будет автоматически сохранена и отображена здесь',
    wordmap_click_on_word: 'Нажмите на слово, чтобы увидеть наиболее часто используемые слова',
    wordmap_click_on_line: 'Нажмите на линию между двумя словами, чтобы увидеть предложение',
    category_progress: 'Отслеживайте свой прогресс, отмечая флажки',
    conversations_select_scene: 'Выберите упражнение',
    conversations_warn: 'Кажется, в вашем предложении ошибка. Нажмите на треугольник для получения дополнительных объяснений.',
    conversations_task: 'Сформулируйте предложение в соответствии с инструкцией в конце чата',
  },
  tour_guide: {
    click_on_every_word: {
      title: 'Перевод слова',
      text: 'Вы можете нажать на любое слово, чтобы увидеть переводы, примеры и определения'
    },
    click_on_i_for_sentence: {
      title: 'Целое предложение',
      text: 'Чтобы перевести целое предложение, нажмите на значок i. Здесь вы также можете получить разъяснения по грамматике.'
    },
    no_save_custom: {
      title: 'Нет сохранения',
      text: 'Собственные тексты не могут быть сохранены'
    },
    change_playback_rate: {
      title: 'Изменить скорость воспроизведения',
      text: 'Измените скорость воспроизведения в Меню/Настройки/Аудио и Видео.'
    },
    theme: {
      title: 'Цвета',
      text: 'Вы можете изменить цвета в меню на тёмный режим'
    },
    explain_menu: {
      title: 'Меню',
      text: 'Пункты меню отсортированы от очень сложных до легких сверху вниз'
    },
    repeat_words: {
      title: 'Повторить словарный запас',
      text: 'Вам нужно повторить {nWords} слов.',
      call_to_action: 'Учить сейчас'
    }
  },
  chat: {
    your_message: 'Ваше сообщение',
    in_de: 'на немецком',
    in_es: 'на испанском',
    in_fr: 'на французском',
    in_it: 'на итальянском',
    in_pt: 'на португальском',
    in_ru: 'на русском',
    in_zh: 'на китайском',
    in_pl: 'на польском',
    wait: 'Пожалуйста, подождите...',
    speak_now: 'Говорите сейчас',
    new_chat: 'Новый чат'
  },
  langs: {
    de: 'Немецкий',
    en: 'Английский',
    sp: 'Испанский',
    fr: 'Французский',
    it: 'Итальянский',
    pt: 'Португальский',
    pl: 'Польский',
    ru: 'Русский',
    zh: 'Китайский'
  },
  about: {
    how_does_it_work: 'Как это работает?',
    here_you_can: 'Здесь вы можете',
    read_texts: 'Читать тексты',
    watch_videos: 'Смотреть видео',
    click_on_every_wrord_for: 'Нажимайте на любое слово для',
    translations: 'переводов',
    examples: 'примеров',
    definitions: 'определений',
    synonyms: 'синонимов',
    copy_your_own_text: 'Копировать и читать свой текст',
    chat_with_chatbot: 'Общаться с чат-ботом',
    level: 'Ваш уровень должен быть между B1 и C2'
  },
  settings: {
    audio_video: 'Аудио и Видео',
    colors: 'Цвета',
    dark_mode: 'Темный режим',
    lang_level: 'Уровень языка'
  },
  error: {
    resource_not_found: 'Извините, мы не смогли найти то, что вы ищете'
  },
  train: {
    check: 'Проверить',
    flashcards: 'Флеш-карты',
    exercises: 'Упражнения',
    finished: 'Упражнения завершены',
    go_to_favorites: 'Перейти в избранное',
    restart_exercise: 'Перезапустить упражнение',
    go_to_home: 'Перейти на главную',
    text_with_words: 'Текст с словами',
    text_includes: 'Текст включает следующие слова'
  },
  wordmap: {
    click_on_circle: 'Нажмите на круг, чтобы увидеть наиболее часто используемые слова с {word}',
    click_on_edge:
      'Нажмите на линию между двумя словами, чтобы увидеть предложение с обоими словами',
    open_fullscreen: 'Открыть на весь экран',
    add_own_word: 'Добавить свое слово',
    add_sentence_with_all_words: 'Добавить предложение со всеми словами',
    add_to_word: 'Добавить в слово'
  },
  conversations: {
    you_are: 'Вы',
    you_talk_to: 'Вы говорите с',
    your_tasks: 'Ваши задачи',
  },
  write: {
    min_words: 'Минимальное количество слов',
    your_email_needs_to_contain: 'Ваш email должен содержать',
    rate: 'Оценка',
    analysis: 'Анализ',
    overall: 'Общее',
    grammar: 'Грамматика',
    content: 'Содержание',
    spelling: 'Орфография'
  }
};
