<script setup lang="ts">
// @ts-ignore
import stores from '@jaaluu/stores';
// @ts-ignore
import anime from 'animejs/lib/anime.es.js';
import { computed, onMounted, ref } from 'vue';
const { langForeign } = stores.language.state;
const { logoBallTrans } = stores.assets.state;

defineProps({
  text: {
    type: String,
    required: false
  },
  showSpinner: {
    type: Boolean,
    required: false,
    default: false
  }
});

const model = ref(0);
const { favWords } = stores.favorites.state;

const id = Math.random().toString(36).substr(2, 9);

const randomFavWords = computed(() => {
  return favWords.value
    .filter((fw) => fw.lang === langForeign.value)
    .sort(() => Math.random() - 0.5)
    .slice(0, 5);
});

onMounted(() => {
  var textWrapper: any = document.getElementById(id);
  if (!textWrapper) return;
  textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

  anime
    .timeline({ loop: true })
    .add({
      targets: '.ml2 .letter',
      scale: [4, 1],
      opacity: [0, 1],
      translateZ: 0,
      easing: 'easeOutExpo',
      duration: 950,
      delay: (_: any, i: any) => 70 * i
    })
    .add({
      targets: '.ml2',
      opacity: 0,
      duration: 2000,
      easing: 'easeOutExpo',
      delay: 4000
    });
});
</script>

<template>
  <div class="wrapper d-flex flex-column align-center">
    <div class="">
      <div class="d-flex justify-center align-center">
        <div class="">
          <v-img :src="logoBallTrans" alt="Animated Picture" class="animated-image" />
        </div>
        <h1 class="ml2 ml-2" :id="id">| JAALUU</h1>
      </div>
      <div class="d-flex justify-center subtitle mt-1" v-if="text">{{ text }}</div>
    </div>
    <!-- <div v-if="showSpinner && langForeign && favWords && favWords.length > 2" class="mt-6">
      <div>
        <v-carousel
          v-model="model"
          :cycle="true"
          delimiter-icon="mdi-minus"
          hide-delimiters
          height="100"
          :show-arrows="false"
        >
          <v-carousel-item v-for="favWord in randomFavWords" :key="favWord.word">
            <v-sheet height="100%" tile>
              <v-row class="fill-height" align="center" justify="center">
                <div class="text-center slider-content">
                  <TextWithTooltips :text="favWord.word" class="title-sm" />
                  <div class="subtitle-sm">
                    {{ getHumanizedDate(favWord.added_at.toString(), langForeign) }}
                  </div>
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </div>
    </div> -->
  </div>
</template>

<style scoped>
.wrapper {
  margin-top: 150px;
}

.ml2 {
  font-weight: 200;
  font-size: 1.5em;
  color: rgb(var(--v-theme-on-background));
  transform: scaleX(2);
  transform: scaleY(0.7);
}
@media screen and (min-width: 600px) {
  .ml2 {
    font-size: 2.5em;
  }
}

.ml2 .letter {
  display: inline-block;
  line-height: 1em;
}

.animated-image {
  width: 40px;
  max-width: min(15vw, 40px);
  height: auto;
  display: block;
  animation: loader 8s ease-in-out infinite;
  animation-delay: 2s;
}

@keyframes loader {
  0% {
    transform: rotate3d(0, 1, 0, 0deg) scale(1);
  }
  10% {
    transform: rotate3d(0, 1, 0, 90deg) scale(0.8);
  }
  20% {
    transform: rotate3d(0, 1, 0, 180deg) scale(1);
  }
  30% {
    transform: rotate3d(0, 1, 0, 270deg) scale(0.8);
  }
  40% {
    transform: rotate3d(0, 1, 0, 360deg) scale(1);
  }
  50% {
    transform: rotate3d(0, 1, 0, 0deg) scale(1);
  }
  60% {
    transform: rotate3d(0, 1, 0, 90deg) scale(0.8);
  }
  70% {
    transform: rotate3d(0, 1, 0, 180deg) scale(1);
  }
  100% {
    transform: rotate3d(0, 1, 0, 0deg) scale(1);
  }
}

.slider-content {
  width: 300px;
}
</style>
