<script lang="ts" setup>
import { Word } from '@jaaluu/interfaces/Tooltip.types';
import stores from '@jaaluu/stores';
import { Language, WordInfoService } from '@shared/openapi/jaaluu-api';
import { Ref, computed, onMounted, ref, watch } from 'vue';
// @ts-ignore
import he from 'he';

const { getMaxText } = stores.text.funcs;
const { openTooltip } = stores.tooltip.funcs;
const { splitBySentences } = stores.extractor.funcs;
const { activeText } = stores.text.state;
const { langForeign } = stores.language.state;

const EXCLUDE_INFO = ['', '...'];

const props = defineProps({
  text: {
    type: String,
    required: true
  },
  lang: {
    type: String,
    required: false
  },
  showSenteceI: {
    required: false,
    default: true
  },
  maxWords: {
    type: Number,
    required: false,
    default: 1000000000000000
  },
  translateFromEn: {
    type: Boolean,
    required: false,
    default: false
  }
});

const random = ref('');
const sentences: Ref<Word[][]> = ref([]);
const hoverOverI = ref(false);
const maxWords: Ref<number | undefined> = ref(props.maxWords);
const showChevronDown = ref(false);
const showChevronUp = ref(false);
const _text = ref(props.text);
const translationLoaded = ref(props.translateFromEn ? false : true);

onMounted(() => {
  updateSentence();
  random.value = Math.random().toString();
});

const _lang = computed(() => {
  return props.lang ? props.lang : langForeign.value;
});

const isTextTruncated = computed(() => {
  return maxWords.value && maxWords.value < _text.value.split(' ').length;
});

//@Watch('text')
const updateSentence = async () => {
  if (props.translateFromEn) {
    const query: any = {
      q: _text.value,
      foreign: Language.EN,
      native: langForeign.value
    };
    const res: any = await WordInfoService.getTranslationSentenceV1WordInfoTranslationSentenceGet({
      query
    });
    _text.value = res.data.translation;
    translationLoaded.value = true;
  } else {
    _text.value = props.text;
  }
  let orgText = _text.value;
  if (isTextTruncated.value) {
    showChevronDown.value = true;
    orgText = getMaxText(orgText, maxWords.value);
  } else {
    showChevronDown.value = false;
  }
  splitBySentences(orgText, _lang.value).then((_sentences) => {
    sentences.value = _sentences;
  });
};

const toggleShowComplete = () => {
  maxWords.value = maxWords.value ? undefined : props.maxWords;
  updateSentence();
  showChevronUp.value = !showChevronUp.value;
};

const showSentenceInfo = (sentence: Word[], wordIdx: number) => {
  if (!props.showSenteceI) return false;
  return (
    sentence.filter((w) => !EXCLUDE_INFO.includes(w.text)).length > 1 &&
    wordIdx === sentence.length - 1
  );
};

const filterLastPunctuation = (word: string, idxSent: number, idxWord: number) => {
  if (idxSent === sentences.value.length - 1 && idxWord === sentences.value[idxSent].length - 1) {
    return !/^[^\w\s]$/.test(word);
  }
  return true;
};

const getWords = (sentence: Word[], k: number) => {
  return sentence.filter((w: Word, idxWord: number) => filterLastPunctuation(w.text, k, idxWord));
};

watch(
  () => props.text,
  () => {
    updateSentence();
  }
);
</script>

<template>
  <div v-if="!translationLoaded">
    <v-skeleton-loader class="mx-auto" type="text"></v-skeleton-loader>
  </div>
  <div v-else>
    <div v-if="sentences.length > 0" class="">
      <span v-for="(sentence, k) in sentences" :key="`sentence_${k}_${random}`" class="sentence">
        <pre v-if="sentence.length === 1 && sentence[0].text === '\n'">{{ sentence[0].text }}</pre>
        <span
          v-else
          @click="(event) => openTooltip(event, word.text, sentence.map((w) => w.text).join(' '))"
          v-for="(word, j) in getWords(sentence, k)"
          :key="`sentence_${k}_word_${j}_${random}`"
          class="word"
          :class="{
            'word-hover': !hoverOverI,
            'word-pre': showSentenceInfo(sentence, j),
            'active-word': sentence.map((w) => w.text).join(' ') === activeText,
            [word.style]: true
          }"
        >
          {{ he.decode(word.text) }}
          <!-- - {{ getPinyin(word.text) }} -->
          <v-btn
            icon="mdi-information-variant"
            variant="text"
            v-if="showSentenceInfo(sentence, j)"
            :size="18"
            class="info ml-n2 mt-n3"
            @mouseover="() => (hoverOverI = true)"
            @mouseleave="() => (hoverOverI = false)"
            @click="
              (event: Event) => {
                hoverOverI = false;
                openTooltip(
                  event,
                  sentence.map((w) => w.text).join(' '),
                  sentence.map((w) => w.text).join(' '),
                  true
                );
              }
            "
            :key="`sentence_completet_${k}_${random}`"
            ><v-icon size="15" icon="mdi-information-variant"
          /></v-btn>
          <v-btn
            variant="text"
            @mouseover="() => (hoverOverI = true)"
            @mouseleave="() => (hoverOverI = false)"
            v-if="
              k === sentences.length - 1 &&
              j === getWords(sentence, k).length - 1 &&
              (showChevronDown || showChevronUp)
            "
            size="18"
            class="no-word-hover"
            @click.stop="
              () => {
                hoverOverI = false;
                toggleShowComplete();
              }
            "
            ><v-icon
              class="no-word-hover"
              size="15"
              :icon="showChevronDown ? 'mdi-chevron-down' : 'mdi-chevron-up'"
          /></v-btn>
        </span>
      </span>
      <!-- <ChinesePinyin v-if="showpinyin" :text="text" :lang="_lang" /> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.info {
  color: rgb(156, 156, 156);
  &:hover {
    color: rgb(var(--v-secondary-text));
  }
}
.word {
  cursor: pointer;
  padding-right: 0.1rem;
}
.word-hover {
  cursor: pointer;
  padding-right: 0.1rem;
  &:hover {
    color: rgb(var(--v-secondary-text));
  }
}
.no-word-hover {
  color: rgb(var(--v-on-primary-gray)) !important;
}
.word-pre {
  white-space: pre;
}
.sentence {
  text-wrap: auto;
}
</style>
