import { ref } from 'vue';

interface Notification {
  text: string;
  timeout?: number;
  type?: string;
  icon?: string;
}

export default () => {
  const snackbars: any = ref([]);

  const notify = (notification: Notification) => {
    const id = new Date().getTime();
    const timeout = notification.timeout ? notification.timeout : 10000;
    const type = notification.type ? notification.type : 'error';
    const alreadyExists = snackbars.value.find((s: any) => s.text === notification.text);
    if (alreadyExists) return;

    snackbars.value.push({
      show: true,
      text: notification.text,
      type,
      timeout,
      id,
      icon: notification.icon,
    });
    setTimeout(() => {
      snackbars.value = snackbars.value.filter((s: any) => s.id !== id);
    }, timeout);
  };

  return { state: { snackbars }, funcs: { notify } };
};
