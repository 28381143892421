import { t } from '@shared/i18n';
import { Language } from '@shared/openapi/jaaluu-api';
import sharedStores, { animate } from '@shared/shared-stores';
import { computed, ref, Ref } from 'vue';
import { language } from './';

const FAV_WORD_STORAGE_KEY = 'favWords';
const FAV_SENTENCE_STORAGE_KEY = 'favSentences';
const FAV_URL_STORAGE_KEY = 'favUrls';
const FAV_WORDMAPS_STORAGE_KEY = 'favWordmaps';
const BOOKMARKS_STORAGE_KEY = 'bookmarks';
const SRS_INTERVALS = [1, 2, 5, 10, 25, 50, 100, 300];

type UrlType = 'feeds' | 'kids_lexicon' | 'videos' | 'wikipedia' | 'books';

export interface FavWord {
  word: string;
  lang: Language;
  added_at: Date;
  last_seen: Date;
  last_level_change: Date;
  trained_success: number;
  trained_count: number;
  seen_count: number;
  srs_level: number;
  success_rate: number;
}

export interface FavSentence {
  sentence: string;
  lang: Language;
  added_at: Date;
}

export interface FavUrl {
  url: string;
  title: string;
  type: UrlType;
  lang: Language;
  added_at: Date;
}

export interface ExampleSentence {
  id: string;
  sentence: string;
}

export interface WordmapData {
  wordmap: string;
  exampleSentences: ExampleSentence[];
  addedWords: WordmapWord[];
}

export interface FavWordmap {
  word: string;
  data: WordmapData;
  lang: Language;
  added_at: Date;
}

export enum FavType {
  WORD = 'word',
  URL = 'url',
  SENTENCE = 'sentence',
  WORDMAP = 'wordmap'
}

export default () => {
  const { langForeign } = language.state;
  const { addConfetti } = animate.funcs;
  const { notify } = sharedStores.snackbar.funcs;

  const favWords: Ref<FavWord[]> = ref([]);
  const wordsToLearn: Ref<FavWord[]> = ref([]);
  const favSentences: Ref<FavSentence[]> = ref([]);
  const favUrls: Ref<FavUrl[]> = ref([]);
  const favWordmaps: Ref<FavWordmap[]> = ref([]);
  const bookmarks: Ref<Record<string, number>> = ref({});

  const favWordList = computed(() => {
    return favWords.value.map((f) => f.word);
  });
  const favSentenceList = computed(() => {
    return favSentences.value.map((f) => f.sentence);
  });
  const favUrlsList = computed(() => {
    return favUrls.value.map((f) => f.url);
  });
  const favWordmapsList = computed(() => {
    return favWordmaps.value.map((f) => f.word);
  });

  const favWordsLang = computed(() => {
    return favWords.value.filter((f) => f.lang === langForeign.value);
  });
  const favSentencesLang = computed(() => {
    return favSentences.value.filter((f) => f.lang === langForeign.value);
  });
  const favUrlsLang = computed(() => {
    return favUrls.value.filter((f) => f.lang === langForeign.value);
  });
  const favWordmapsLang = computed(() => {
    return favWordmaps.value.filter((f) => f.lang === langForeign.value);
  });

  const setFavorites = () => {
    const mapper: any = {
      [FAV_WORD_STORAGE_KEY]: {
        store: favWords,
        parseFunc: JSON.parse,
        needKeysCount: 10
      },
      [FAV_SENTENCE_STORAGE_KEY]: {
        store: favSentences,
        parseFunc: JSON.parse,
        needKeysCount: 3
      },
      [FAV_URL_STORAGE_KEY]: {
        store: favUrls,
        parseFunc: JSON.parse,
        needKeysCount: 5
      },
      [BOOKMARKS_STORAGE_KEY]: {
        store: bookmarks,
        parseFunc: JSON.parse,
        needKeysCount: -1
      },
      [FAV_WORDMAPS_STORAGE_KEY]: {
        store: favWordmaps,
        parseFunc: JSON.parse,
        needKeysCount: 3
      }
    };

    Object.keys(mapper).forEach((key) => {
      const valueLs = localStorage.getItem(key);
      if (valueLs) {
        try {
          const parsedValue = mapper[key].parseFunc(valueLs);
          if (
            !parsedValue ||
            (mapper[key].needKeysCount > 0 &&
              parsedValue.length > 0 &&
              Object.keys(parsedValue[0]).length < mapper[key].needKeysCount)
          ) {
            localStorage.removeItem(key);
            return;
          }
          mapper[key].store.value = parsedValue;
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const toggleFavorite = (wordOrSentence: string, lang: Language, type: FavType) => {
    if (type === FavType.WORD) {
      toggleFavoriteWord(wordOrSentence, lang);
    } else {
      toggleFavoriteSentence(wordOrSentence, lang);
    }
  };

  const toggleFavoriteWord = (word: string, lang: Language) => {
    if (favWordList.value.includes(word)) {
      favWords.value = favWords.value.filter((fW) => fW.word !== word);
    } else {
      const newFavWord = {
        word,
        lang,
        added_at: new Date(),
        last_seen: new Date(),
        last_level_change: new Date(),
        trained_success: 0,
        trained_count: 0,
        seen_count: 1,
        srs_level: 0,
        success_rate: 0
      };
      favWords.value.push(newFavWord);
    }
    localStorage.setItem(FAV_WORD_STORAGE_KEY, JSON.stringify(favWords.value));
  };

  const toggleFavoriteSentence = (sentence: string, lang: Language) => {
    if (favSentenceList.value.includes(sentence)) {
      favSentences.value = favSentences.value.filter((f) => f.sentence !== sentence);
    } else {
      const newFavSentence = {
        sentence,
        lang,
        added_at: new Date()
      };
      favSentences.value.push(newFavSentence);
    }
    localStorage.setItem(FAV_SENTENCE_STORAGE_KEY, JSON.stringify(favSentences.value));
  };

  const toggleFavoriteUrl = (url: string, title: string | undefined | null, lang: Language) => {
    if (favUrlsList.value.includes(url)) {
      favUrls.value = favUrls.value.filter((tv) => tv.url !== url);
    } else if (!title) {
      console.error('No title for url', url);
    } else {
      const type = url.split('/')[1] as UrlType;
      const newFav = {
        url: `/${langForeign.value}${url}`,
        lang,
        type,
        title,
        added_at: new Date()
      };
      favUrls.value.push(newFav);
    }
    localStorage.setItem(FAV_URL_STORAGE_KEY, JSON.stringify(favUrls.value));
  };

  const toggleFavoriteWordmap = (word: string, data: WordmapData, lang: Language) => {
    if (favWordmapsList.value.includes(word)) {
      favWordmaps.value = favWordmaps.value.filter((f) => f.word !== word);
    } else {
      const newFav = {
        word,
        data,
        lang,
        added_at: new Date()
      };
      favWordmaps.value.push(newFav);
    }
    localStorage.setItem(FAV_WORDMAPS_STORAGE_KEY, JSON.stringify(favWordmaps.value));
  };

  const addBookmark = (id: string, pageNumber: number) => {
    bookmarks.value[id] = pageNumber;
    localStorage.setItem(BOOKMARKS_STORAGE_KEY, JSON.stringify(bookmarks.value));
  };

  const getDateToNextReview = (srsLevel: number, lastLevelChange: Date) => {
    const now = new Date();
    const interval = SRS_INTERVALS[srsLevel];
    const intervalInMins = interval * 24 * 60;
    const minsSinceLastChange = (now.getTime() - new Date(lastLevelChange).getTime()) / 1000 / 60;
    const diffInM = Math.max(0, intervalInMins - minsSinceLastChange);
    if (diffInM === 0) return now;
    return new Date(now.getTime() + diffInM * 60 * 1000);
  };

  const addTrainResult = (word: string, success: boolean) => {
    let movedToNextLevel = false;
    const favWord = favWords.value.find((f) => f.word === word);
    if (!favWord) return movedToNextLevel;
    favWord.trained_count++;
    favWord.seen_count++;
    const now = new Date();
    const srsInterval = SRS_INTERVALS[favWord.srs_level];
    const srsIntervalInMins = srsInterval * 24 * 60;
    const minsSinceLastChange =
      (now.getTime() - new Date(favWord.last_level_change).getTime()) / 60000;
    if (success) {
      favWord.trained_success++;
    }
    favWord.success_rate = parseFloat(
      ((favWord.trained_success / favWord.trained_count) * 100).toFixed(0)
    );
    if (success){
      if (
        favWord.srs_level < SRS_INTERVALS.slice(-1)[0] &&
        minsSinceLastChange >= srsIntervalInMins &&
        favWord.success_rate >= 50
      ) {
        movedToNextLevel = true;
        favWord.srs_level = Math.min(9, favWord.srs_level + 1);
        favWord.last_level_change = new Date();
        notify({
          type: 'success',
          text: t('favorites.success_level_up', { level: favWord.srs_level + 1 }),
          icon: 'mdi-weight-lifter'
        });
      }
      addConfetti();
    }
    favWord.last_seen = new Date();
    localStorage.setItem(FAV_WORD_STORAGE_KEY, JSON.stringify(favWords.value));
    return movedToNextLevel;
  };

  const getSuccessRateColor = (successRate: number) => {
    if (successRate < 50) return 'red';
    if (successRate < 80) return 'orange';
    return 'green';
  };

  const getFavWordsForReviewSrc = () => {
    const now = new Date();
    return favWordsLang.value.filter(({ last_seen, srs_level }) => {
      const interval = SRS_INTERVALS[srs_level];
      const intervalInMins = interval * 24 * 60;
      const lastSeen = new Date(last_seen);
      const minsSinceLastSeen = (now.getTime() - lastSeen.getTime()) / 60000;
      return minsSinceLastSeen >= intervalInMins;
    });
  };


  return {
    state: {
      favWords,
      favWordsLang,
      wordsToLearn,
      favWordList,
      favSentenceList,
      favSentencesLang,
      favSentences,
      favUrls,
      favUrlsLang,
      favUrlsList,
      favWordmaps,
      favWordmapsLang,
      favWordmapsList,
      bookmarks
    },
    funcs: {
      setFavorites,
      toggleFavoriteWord,
      toggleFavoriteUrl,
      toggleFavoriteSentence,
      toggleFavoriteWordmap,
      addBookmark,
      toggleFavorite,
      addTrainResult,
      getFavWordsForReviewSrc,
      getSuccessRateColor,
      getDateToNextReview
    },
    consts: {
      SRS_INTERVALS
    }
  };
};
