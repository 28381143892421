import { CategoryType, Language, MainCategories } from '@shared/openapi/jaaluu-api';
import { Ref, ref } from 'vue';
import { language } from '.';
import { CATEGORIES_EN } from './categories/en';
import { SubCategory } from './category';

export interface Progress {
  pct: number;
  done: string[];
}

export default () => {
  const { langForeign } = language.state;
  const progress: Ref<
    | {
        [valueof in Language]: {
          [valueof in CategoryType]: {
            [valueof in MainCategories]: Record<SubCategory, Progress>;
          };
        };
      }
    | null
  > = ref(null);

  const initProgressState = () => {
    let _progressLs = null;
    if (localStorage.getItem('progress')) {
      _progressLs = JSON.parse(localStorage.getItem('progress') || '');
    }
    const _progress: any = {};
    for (const lang of Object.values(Language)) {
      _progress[lang] = {};
      for (const categoryType of Object.values(CategoryType) as CategoryType[]) {
        _progress[lang][categoryType] = {};
        for (const mainCategory of Object.values(MainCategories) as MainCategories[]) {
          _progress[lang][categoryType][mainCategory] = {};
          for (const subCategory in CATEGORIES_EN[mainCategory].categories) {
            if (
              _progressLs &&
              _progressLs[lang] &&
              _progressLs[lang][categoryType] &&
              _progressLs[lang][categoryType][mainCategory] &&
              _progressLs[lang][categoryType][mainCategory][subCategory]
            ) {
              _progress[lang][categoryType][mainCategory][subCategory] =
                _progressLs[lang][categoryType][mainCategory][subCategory];
            } else {
              _progress[lang][categoryType][mainCategory][subCategory] = {
                pct: 0,
                done: []
              };
            }
          }
        }
      }
    }
    progress.value = _progress;
  };

  const getSubProgress = (
    mainCategory: MainCategories,
    subCategory: SubCategory,
    categoryType: CategoryType
  ) => {
    if (!langForeign.value || !progress.value) return { done: [], pct: 0 };
    return progress.value[langForeign.value][categoryType][mainCategory][subCategory];
  };

  const getMainProgress = (mainCategory: MainCategories, categoryType: CategoryType) => {
    if (!langForeign.value || !progress.value) return { done: [], pct: 0 };
    const subCategories = CATEGORIES_EN[mainCategory].categories;
    let total = 0;
    for (const subCategory in subCategories) {
      const subProgress = getSubProgress(mainCategory, subCategory as SubCategory, categoryType);
      total += subProgress.pct;
    }
    return { done: [], pct: total / Object.keys(subCategories).length } as Progress;
  };

  const updateLocalStorage = () => {
    localStorage.setItem('progress', JSON.stringify(progress.value));
  };

  return {
    state: { progress },
    funcs: { initProgressState, updateLocalStorage, getSubProgress, getMainProgress }
  };
};
