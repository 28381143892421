import { GAnalyticsEvent } from '@jaaluu/interfaces/GAnalyticEvent';

import { language } from './';

const G_ANALYTICS_ID = (import.meta as any).env.VITE_G_ANALYTICS_ID;
const gAnalyticsActive = G_ANALYTICS_ID && G_ANALYTICS_ID !== 'DISABLED';

export default () => {
  const { langForeign, langNative } = language.state;
  const initGoogleAnalytics = () => {
    if (!gAnalyticsActive) return;
    console.warn('Google Ads enabled');
    let gAdsScript = document.createElement('script');
    gAdsScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${G_ANALYTICS_ID}`);
    document.head.appendChild(gAdsScript);

    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${G_ANALYTICS_ID}', { 'cookie_flags': 'SameSite=None;Secure', 'debug_mode': true });
        `;
    document.head.appendChild(script);
  };

  const sendEvent = (name: GAnalyticsEvent, data: any = {}) => {
    if (!gAnalyticsActive) return;
    const gTag = (window as any).gtag;
    gTag('event', name, {
      ...data,
      lang_foreign: langForeign.value,
      lang_native: langNative.value,
      debug_mode: false
    });
  };

  const sendConversionStartLearning = () => {
    if (!gAnalyticsActive) return;
    const gTag = (window as any).gtag;
    gTag('event', 'conversion', {
      send_to: 'AW-11047790475/IDjVCNLO2pcaEIvP_5Mp',
      event_callback: null
    });
  };

  return { state: {}, funcs: { initGoogleAnalytics, sendEvent, sendConversionStartLearning } };
};
