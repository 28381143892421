<script lang="ts" setup>
import { t } from '@shared/i18n';
// @ts-ignore
import animatedChat from '@jaaluu/assets/videos/animated_chat.mp4';
import correctEmail from '@jaaluu/assets/videos/correct_email.mp4';
import emailWriting from '@jaaluu/assets/videos/email_writing.mp4';
import favStar from '@jaaluu/assets/videos/fav_star.mp4';
import favorite from '@jaaluu/assets/videos/favorite.mp4';
import helloGlobe from '@jaaluu/assets/videos/hello_globe.mp4';
import landing from '@jaaluu/assets/videos/landing.mp4';
import langLetters from '@jaaluu/assets/videos/lang_letters.mp4';
import level from '@jaaluu/assets/videos/level.mp4';
import listeningHeadphone from '@jaaluu/assets/videos/listening_headphones.mp4';
import network from '@jaaluu/assets/videos/network.mp4';
import specialVocab from '@jaaluu/assets/videos/special_vocab.mp4';
import srsSystem from '@jaaluu/assets/videos/srs_system.mp4';
import train from '@jaaluu/assets/videos/train.mp4';
import virtualLib from '@jaaluu/assets/videos/virtual_lib.mp4';
import SelectLanguages from '@jaaluu/components/Settings/SelectLanguages.vue';
import { GAnalyticsEvent } from '@jaaluu/interfaces/GAnalyticEvent';
import stores from '@jaaluu/stores';
import IconWithText from '@jaaluu/views/Welcome/IconWithText.vue';
import MovingVideosWithText from '@jaaluu/views/Welcome/MovingVideosWithText.vue';
import VideoTextCols from '@jaaluu/views/Welcome/VideoTextCols.vue';
import VideoWithText from '@jaaluu/views/Welcome/VideoWithText.vue';
import { useRouter } from 'vue-router';
import LogoBalls from './LogoBalls.vue';

const BASE_URL_APP = (import.meta as any).env.VITE_JAALUU_URL;
const router = useRouter();
const { sendEvent, sendConversionStartLearning } = stores.gAnalytics.funcs;
const { logoWideBlackSmall } = stores.assets.state;
const { langNative, langForeign, langOptions } = stores.language.state;

const navigateTo = (navTo: string) => {
  if (!langForeign.value || !langNative.value) {
    console.error('no langForeign or langNative');
  }
  router.push(navTo);
};

const scrollTo = (elId: string) => {
  const el = document.querySelector(elId);
  if (el) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
};

const atSelectLangAndStart = () => {
  scrollTo('#select-langs');
  sendEvent(GAnalyticsEvent.CLICK_SELECT_LANG_AND_START);
};

const atStartLearning = () => {
  sendEvent(GAnalyticsEvent.CLICK_START_LEARNING);
  sendConversionStartLearning();
  navigateTo('/intro')
}

</script>

<template>
  <div class="mx-auto relative w-full no-overflow">
    <div class="d-flex justify-center flex-column align-center mx-auto bg-green app-bar">
      <v-img
        transition="scale-transition"
        alt="Jaaluu"
        class="mt-1 cursor-pointer mr-2"
        contain
        min-width="175"
        :src="logoWideBlackSmall"
        width="55"
      />
    </div>
    <div class="d-flex flex-column align-center"></div>
    <div>
      <slot></slot>
    </div>

    <VideoWithText :title="t('welcome.main_teaser')" :videoSrc="langLetters">
      <v-btn small class="mt-2" color="green" @click="() => atSelectLangAndStart()">
        {{ t('welcome.select_language_and_start') }}
      </v-btn>
    </VideoWithText>

    <div class="mt-8 pt-12 max-width-small mx-auto" id="select-langs">
      <SelectLanguages :from-welcome="true" />
      <div class="d-flex flex-column">
        <v-btn
          small
          class="mt-8"
          color="green"
          @click="() => atStartLearning()"
          :disabled="!langForeign"
        >
          {{ t('welcome.start_learning') }}
        </v-btn>

        <v-btn
          variant="text"
          small
          class="mt-4"
          color="green"
          @click="scrollTo('#how-to')"
        >
          {{ t('welcome.how_does_this_work') }}
        </v-btn>
      </div>
    </div>

    <VideoWithText
      id="how-to"
      :title="t('welcome.how_to_title')"
      :text="t('welcome.how_to_text')"
      :videoSrc="virtualLib"
      :video-first="false"
    >
      <div class="relative mt-5" :style="{ height: '50px' }">
        <LogoBalls />
      </div>
    </VideoWithText>

    <div class="bg-green d-flex flex-column align-center pb-16">
      <VideoWithText
        :title="t('welcome.click_on_word_title')"
        :text="t('welcome.click_on_word_text')"
        :videoSrc="landing"
        :big="true"
      >
        <v-btn size="x-small" variant="text" class="mt-2" @click="scrollTo('#click-on-words')">
          {{ t('general.more') }}
        </v-btn>
      </VideoWithText>
    </div>

    <VideoWithText
      :title="t('welcome.listen_title')"
      :text="t('welcome.listen_text')"
      :videoSrc="listeningHeadphone"
      :animate="true"
    >
      <v-btn size="x-small" variant="text" class="mt-2" color="green" @click="scrollTo('#listen')">
        {{ t('general.more') }}
      </v-btn>
    </VideoWithText>
    <VideoWithText
      :title="t('welcome.conversations_title')"
      :text="t('welcome.conversations_text')"
      :videoSrc="animatedChat"
      :video-first="false"
      :animate="true"
    >
      <v-btn
        size="x-small"
        variant="text"
        class="mt-2"
        color="green"
        @click="scrollTo('#conversations')"
      >
        {{ t('general.more') }}
      </v-btn>
    </VideoWithText>

    <MovingVideosWithText
      :title="t('welcome.writing_title')"
      :contents="[
        { video: emailWriting, text: t('welcome.writing_text_1') },
        {
          video: correctEmail,
          text: t('welcome.writing_text_2')
        }
      ]"
      ><v-btn
        size="x-small"
        variant="text"
        class="mt-2"
        color="green"
        @click="scrollTo('#writing')"
      >
        {{ t('general.more') }}
      </v-btn>
    </MovingVideosWithText>

    <VideoWithText
      :title="t('welcome.texts_and_videos_title')"
      :text="t('welcome.texts_and_videos_text')"
      :videoSrc="level"
      :video-first="false"
    >
      <v-btn
        size="x-small"
        variant="text"
        class="mt-2"
        color="green"
        @click="scrollTo('#texts-and-video')"
      >
        {{ t('general.more') }}
      </v-btn>
    </VideoWithText>

    <VideoTextCols
      :title="t('welcome.srs_title')"
      :contents="[
        {
          video: favorite,
          text: t('welcome.srs_text_1')
        },
        {
          video: train,
          text: t('welcome.srs_text_2')
        },
        {
          video: srsSystem,
          text: t('welcome.srs_text_3')
        }
      ]"
      :animate="true"
    >
      <v-btn size="x-small" variant="text" class="mt-2" color="green" @click="scrollTo('#srs')">
        {{ t('general.more') }}
      </v-btn>
    </VideoTextCols>

    <VideoWithText
      :title="t('welcome.own_text_title')"
      :text="t('welcome.own_text_text')"
      :videoSrc="specialVocab"
      :video-first="false"
    >
      <v-btn
        size="x-small"
        variant="text"
        class="mt-2"
        color="green"
        @click="scrollTo('#own-text')"
      >
        {{ t('general.more') }}
      </v-btn>
    </VideoWithText>

    <MovingVideosWithText
      :title="t('welcome.wordmap_title')"
      :contents="[
        {
          video: network,
          text: t('welcome.wordmap_text_1')
        },
        {
          video: favStar,
          text: t('welcome.wordmap_text_2')
        }
      ]"
      :video-class="'scale-down'"
    >
      <v-btn size="x-small" variant="text" class="mt-2" color="green" @click="scrollTo('#wordmap')">
        {{ t('general.more') }}
      </v-btn>
    </MovingVideosWithText>

    <VideoWithText
      :title="t('welcome.sayings_title')"
      :text="t('welcome.sayings_text')"
      :videoSrc="helloGlobe"
      :video-first="false"
    />

    <div class="d-flex justify-center py-3 mt-12">
      <v-btn small class="" color="green" @click="scrollTo('#select-langs')">
        {{ t('welcome.select_language_and_start') }}
      </v-btn>
    </div>

    <div class="welcome-content-width mx-auto mt-16">
      <h1 class="text-center title-welcome mb-n8">{{ t('welcome.details') }}</h1>
      <IconWithText
        id="texts-and-video"
        :title="t('welcome.details_text_and_videos_title')"
        :text="t('welcome.details_text_and_videos_text')"
        icon="mdi-text-long"
      />

      <IconWithText
        id="click-on-words"
        :title="t('welcome.details_click_on_words_title')"
        :text="t('welcome.details_click_on_words_text')"
        icon="mdi-file-word-box-outline"
      />

      <IconWithText
        id="listen"
        :title="t('welcome.details_listen_title')"
        :text="t('welcome.details_listen_text')"
        icon="mdi-headphones"
      />

      <IconWithText
        id="conversations"
        :title="t('welcome.details_conversations_title')"
        :text="t('welcome.details_conversations_text')"
        icon="mdi-message"
      />

      <IconWithText
        id="writing"
        :title="t('welcome.details_writing_title')"
        :text="t('welcome.details_writing_text')"
        icon="mdi-receipt-text-edit-outline"
      />

      <IconWithText
        id="srs"
        :title="t('welcome.details_srs_title')"
        :text="t('welcome.details_srs_text')"
        icon="mdi-brain"
      />

      <IconWithText
        id="own-text"
        :title="t('welcome.details_own_text_title')"
        :text="t('welcome.details_own_text_text')"
        icon="mdi-star-four-points-outline"
      />

      <IconWithText
        id="wordmap"
        :title="t('welcome.details_wordmap_title')"
        :text="t('welcome.details_wordmap_text')"
        icon="mdi-transit-connection-horizontal"
      />

      <IconWithText
        id="paste-text"
        :title="t('welcome.details_paste_text_title')"
        :text="t('welcome.details_paste_text_text')"
        icon="mdi-content-copy"
      />
      <IconWithText
        id="paste-text"
        :title="t('welcome.which_languages_title')"
        :text="t('welcome.which_languages_text')"
        icon="mdi-translate"
      />
    </div>

    <div class="d-flex justify-center py-3">
      <v-btn small class="" color="green" @click="scrollTo('#select-langs')">
        {{ t('welcome.select_language_and_start') }}
      </v-btn>
    </div>

    <div class="relative bg-gradient mt-12" :style="{ height: '100px' }">
      <LogoBalls />
    </div>
    <div class="bg-green pt-8 pb-8 text-black">
      <div class="welcome-content-width mx-auto">
        <div class="d-flex justify-center mb-4">{{ t('welcome.site_also_available') }}:</div>
        <div class="d-flex flex-wrap justify-center align-center">
          <div v-for="langOpt in langOptions" class="mr-12 mt-4 d-flex align-center">
            <a :href="`${BASE_URL_APP.replace('//', `//${langOpt.value}.`)}/welcome`"
              ><img :src="langOpt.flag" :alt="langOpt.title" class="flag-icon mr-2" /><span>{{
                langOpt.title
              }}</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.max-width-small {
  max-width: 300px;
}

.flag-icon {
  width: 25px;
}
.transition {
  transition: width 1s ease;
}
.bg-footer {
  background: rgb(var(--v-theme-on-surface));
}
.bg-green {
  background: #4caf50;
  color: white;
}
.app-bar {
  height: 65px;
  padding-bottom: 10px;
}
.bg-gradient {
  background: linear-gradient(white, #4caf50);
}
.flag-icon {
  width: 25px;
}
.no-overflow {
  overflow: hidden;
}
</style>
