export default {
  welcome: {
    your_language: 'La tua lingua',
    language_to_learn: 'Lingua che vuoi imparare',
    start_learning: 'Inizia a imparare',
    how_does_this_work: 'Come funziona?',
    welcome_1: 'Benvenuto su JAALUU',
    welcome_2: 'Qui puoi imparare le lingue',
    welcome_3: 'Leggi testi o guarda video con sottotitoli',
    welcome_4: 'Clicca su una parola per traduzioni, esempi e definizioni',
    main_teaser: 'Migliora le tue competenze linguistiche - facilmente e gratuitamente',
    select_language_and_start: 'Seleziona la lingua e inizia',
    how_to_title: 'Leggi testi, libri, notizie o guarda video',
    how_to_text: 'Puoi scegliere tra una varietà di testi, libri, notizie o video',
    click_on_word_title: 'Traduzione automatica, esempi e definizioni',
    click_on_word_text:
      'Clicca su qualsiasi parola per traduzione automatica, frasi di esempio e definizioni. Puoi anche tradurre frasi intere automaticamente o collegare parole con immagini e creare reti di parole.',
    listen_title: 'Ascolta parole e testi',
    listen_text: 'Puoi ascoltare qualsiasi parola, frase o testo intero e praticare la pronuncia',
    conversations_title: 'Pratica conversazioni',
    conversations_text:
      'Puoi praticare conversazioni parlando con un chatbot virtuale. Devi risolvere compiti come: `Ordina cibo in un ristorante` o `Chiedi indicazioni.`',
    writing_title: 'Ortografia',
    writing_text_1: "Pratica l'ortografia scrivendo testi",
    writing_text_2:
      'I tuoi testi verranno controllati per errori ortografici, contenuto e grammatica.',
    texts_and_videos_title: 'Trova il tuo livello',
    texts_and_videos_text:
      'Puoi scegliere tra frasi semplici e testi per bambini, notizie, articoli di Wikipedia o libri, a seconda di ciò che ti si addice di più',
    srs_title: 'Ripeti e allena il vocabolario',
    srs_text_1:
      'Puoi salvare qualsiasi parola o intera frase tra i preferiti per ripetere in seguito',
    srs_text_2:
      'Allena il tuo vocabolario con flashcards ed esercizi semplici, e monitora i tuoi progressi',
    srs_text_3:
      'Il sistema di ripetizione SRS ti aiuta ad imparare in modo più efficace ripetendo le parole al momento giusto',
    own_text_title: 'Vocabolario specializzato',
    own_text_text:
      'Puoi creare un testo su un argomento specifico per imparare vocabolario specializzato. Puoi anche creare testi contenenti parole che desideri imparare.',
    wordmap_title: 'Reti di parole',
    wordmap_text_1: 'Puoi creare reti di parole e collegare parole con frasi semplici',
    wordmap_text_2:
      'Le schede delle parole possono essere salvate come preferite, per permettere una facile ripetizione e allenamento',
    sayings_title: 'Modi di dire',
    sayings_text:
      'Qui troverai frasi e modi di dire comunemente usati, suddivisi in categorie. Puoi monitorare i tuoi progressi e continuare a svilupparti in modo strutturato.',
    details: 'Dettagli',
    details_text_and_videos_title:
      'Scopri Jaaluu – La tua chiave per un apprendimento linguistico veloce ed efficace!',
    details_text_and_videos_text:
      'Jaaluu ti offre una vasta gamma di testi e video per migliorare il tuo livello linguistico. La piattaforma è ideale se riesci già a comprendere parole e frasi semplici. Per i principianti, ci sono frasi e modi di dire semplici, testi per bambini, dizionari e video per un inizio facile. Per i livelli avanzati, ci sono notizie, articoli di Wikipedia, libri e video interessanti per una comprensione più profonda, o conversazioni interattive con chatbot. Inoltre, puoi allenare la tua ortografia e ricevere suggerimenti per migliorare gli errori di ortografia, contenuto e grammatica.',
    details_click_on_words_title:
      'Imparare il vocabolario diventa facile: Veloce, interattivo e sostenibile!',
    details_click_on_words_text:
      'Imparare il vocabolario può essere noioso perché cercare parole e definizioni richiede molto tempo. Jaaluu risolve questo problema: basta cliccare su una parola per ottenere traduzioni, frasi di esempio, definizioni e spiegazioni istantaneamente. Questo ti fa risparmiare tempo e ti aiuta ad imparare più velocemente e in modo più efficace!',
    details_listen_title:
      'Ascolta e pratica: Ascolta parole, frasi e testi per migliorare le tue competenze linguistiche!',
    details_listen_text:
      'Puoi ascoltare tutto – qualsiasi parola, frase o anche testi interi. Basta riprodurre e ascoltare! Questa funzione ti aiuta a praticare la corretta pronuncia e a migliorare la comprensione orale. Puoi anche ascoltare conversazioni e sviluppare una percezione per la comunicazione naturale. Perfetto per interiorizzare il vocabolario e rafforzare le tue competenze linguistiche nelle situazioni quotidiane.',
    details_conversations_title:
      'Allenamento linguistico pratico: Conversazioni realistiche per la vita quotidiana!',
    details_conversations_text:
      'Con Jaaluu, puoi praticare conversazioni realistiche con un chatbot e prepararti per situazioni tipiche della vita quotidiana. Ad esempio, puoi ordinare cibo in un ristorante e pagare il conto, oppure chiedere indicazioni. Oppure, puoi fare una chiacchierata con il tuo nuovo collega nel tuo primo giorno di lavoro. Le tue frasi verranno analizzate automaticamente per darti un feedback diretto e migliorare le tue competenze linguistiche. In questo modo, ti alleni in modo pratico ed efficace per conversazioni reali!',
    details_writing_title:
      'Pratica di scrittura per la vita quotidiana: Migliora le tue competenze con compiti realistici!',
    details_writing_text:
      'Puoi praticare la scrittura affrontando piccoli compiti realistici, come scrivere lettere di candidatura o di reclamo. Il tuo testo verrà poi analizzato automaticamente e riceverai un feedback diretto su ortografia, contenuto e grammatica. In questo modo, migliori le tue competenze nella scrittura e sei ben preparato per la vita quotidiana!',
    details_srs_title:
      'Apprendimento efficace del vocabolario con SRS: Ripetizioni automatiche e analisi personalizzate dei progressi!',
    details_srs_text:
      "Con il sistema SRS (Spaced Repetition System), puoi imparare e ripetere il vocabolario in modo efficace. Questo sistema ti ricorda quando ripetere le parole per conservarle a lungo termine. SRS si basa sul metodo scientifico secondo cui ripetere a intervalli determinati rafforza la memoria. Riceverai anche statistiche sul progresso di apprendimento di ogni parola e sulla tua percentuale di successo, così da poter concentrarti sui tuoi punti deboli e sapere sempre come stai progredendo nell'apprendimento del vocabolario.",
    details_own_text_title:
      'Apprendimento personalizzato: Crea testi personalizzati per esercizi mirati di vocabolario e argomenti!',
    details_own_text_text:
      "Con Jaaluu, puoi facilmente creare i tuoi testi in aree su cui desideri concentrarti. Descrivi l'area o il vocabolario che vuoi imparare, e riceverai un testo da leggere e praticare. In questo modo, puoi concentrarti su argomenti o vocabolario specifici ed espandere la tua conoscenza nell'area che ti interessa. Questo approccio personalizzato ti aiuta ad imparare in modo più efficace e preciso! Inoltre, puoi creare testi che contengono tutto il vocabolario che vuoi rivedere. In questo modo, impari e rivedi il vocabolario nel contesto, facilitandone la memorizzazione.",
    details_wordmap_title:
      'Schede delle parole: Collega la tua conoscenza e ricorda le parole con facilità!',
    details_wordmap_text:
      'Puoi creare schede delle parole che ti aiutano a collegare le parole e ricordarle in modo più efficace. Aggiungi parole e frasi interattivamente alla tua scheda delle parole e amplia gradualmente il tuo vocabolario. Questo crea e rafforza le connessioni nel tuo cervello e costruisce associazioni in modo giocoso. In questo modo, rafforzi la tua comprensione linguistica – in modo sostenibile!',
    details_paste_text_title:
      'Usa i tuoi testi: Incolla il tuo contenuto per imparare con attenzione!',
    details_paste_text_text:
      'Con Jaaluu, puoi anche incollare i tuoi testi per utilizzarli per la pratica. Questo ti permette di lavorare con il tuo materiale e allenare il tuo vocabolario in contesti reali.',
    site_also_available: 'Il sito è disponibile anche in',
    which_languages_title: 'Quali lingue posso imparare?',
    which_languages_text: 'Qui puoi imparare inglese, spagnolo, tedesco, francese, portoghese, russo e polacco. Seleziona semplicemente la lingua che vuoi imparare e sei pronto per partire!'
  },
  intro: {
    your_level: 'Qual è il tuo livello linguistico?',
    beginner_explained: 'Puoi leggere frasi semplici',
    advanced_explained: 'Puoi leggere testi più complessi',
    your_cefr_level: 'Il tuo livello CEFR è',
    lets_start: 'Con cosa vorresti iniziare?',
    beginner_options: {
      simple_texts: 'Leggere testi',
      simple_articles: 'Leggere articoli',
      sayings: 'Imparare modi di dire',
      view_videos: 'Guardare video',
      simple_phrases: 'Leggere frasi tipiche'
    },
    advanced_options: {
      news: 'Leggere notizie',
      videos: 'Guardare video',
      wikipedia: 'Leggere articoli',
      books: 'Leggere libri'
    }
  },
  languages: {
    de: 'Tedesco',
    en: 'Inglese',
    es: 'Spagnolo',
    fr: 'Francese',
    it: 'Italiano',
    pt: 'Portoghese',
    ru: 'Russo',
    zh: 'Cinese',
    pl: 'Polacco'
  },
  general: {
    beginner: 'Principiante',
    advanced: 'Avanzato',
    search: 'Cerca',
    search_imperative: 'Cerca',
    translate: 'Traduci',
    settings: 'Impostazioni',
    vocabulary: 'Vocabolario',
    shorts: 'Testi brevi',
    articles: 'Articoli',
    feeds: 'Notizie',
    videos: 'Video',
    read: 'Leggi',
    view: 'Guarda',
    listen: 'Ascolta',
    save: 'Salva',
    more: 'altro',
    back: 'Indietro',
    next: 'Avanti',
    easy: 'Facile',
    medium: 'Medio',
    hard: 'Difficile',
    all: 'Tutti',
    level: 'Livello',
    font_size: 'Dimensione del carattere',
    language: 'Lingua',
    send: 'Invia',
    image: 'Immagine',
    wordmap: 'Mappa delle parole',
    open: 'Apri',
    add: 'Aggiungi',
    question: 'Domanda',
    start: 'Inizia',
    words: 'Parola | Parole',
  },
  app_bar: {
    search_in_foreign_language: 'Cerca in lingua straniera',
    menu: 'Menu'
  },
  category: {
    chapter: 'Capitolo'
  },
  favorites: {
    favorites: 'Preferiti',
    word: 'Parola',
    sentence: 'Frase',
    sentences: 'Frasi',
    texts_videos: 'Testi e Video',
    wordmaps: 'Mappe delle parole',
    saved: 'Salvato',
    title: 'Titolo',
    type: 'Tipo',
    no_favorites_found: 'Nessun preferito trovato',
    filtered_by: 'filtrato per',
    train: 'Allenamento',
    notify_me_srs: 'Avvisami di ripetere il vocabolario',
    srs_level: 'Livello SRS',
    success_rate: 'Tasso di successo',
    last_seen: 'Ultima volta visto',
    next_review: 'Prossima revisione',
    success_level_up: 'Parola spostata al livello {level}',
    what_is_srs: "Cos'è SRS?",
    srs_short:
      'SRS è un sistema di ripetizione spaziale. Ti aiuta a ricordare meglio le parole ripetendole al momento giusto.',
    srs_long:
      'Il sistema si basa sul principio che più spesso ripeti una parola, meglio la ricordi. Gli intervalli tra le ripetizioni sono determinati dal sistema. Se ricordi bene una parola, gli intervalli diventano più lunghi. Se hai difficoltà, gli intervalli diventano più brevi. In questo modo puoi imparare le parole in modo più efficiente.',
    srs_intervals: 'Gli intervalli di {nIntervals} sono: {intervals} giorni.',
    when_does_a_word_move: 'Quando una parola passa al livello successivo?',
    srs_next_level_example_1:
      "Se aggiungi una parola ai preferiti, sarà al livello 1. Una parola al livello 1 passerà al livello successivo se è passato 1 giorno da quando l'hai aggiunta, il tuo tasso di successo è superiore al 50% e hai appena ricordato correttamente la parola.",
    srs_next_level_example_2:
      "Una parola al livello {level} passerà al livello successivo dopo che l'ultimo cambiamento al livello {levelM1} è stato almeno {days} giorni fa, il tuo tasso di successo è superiore al 50% e hai appena ricordato correttamente la parola."
  },
  player: {
    maximize: 'Massimizza',
    minimize: 'Minimizza'
  },
  video: {
    error:
      'Si è verificato un errore durante il caricamento dei sottotitoli. Sfortunatamente non tutti i sottotitoli sono liberamente accessibili. Per favore prova un altro video.'
  },
  feeds: {
    disclaimer:
      "Questo è un riassunto generato automaticamente da Chatgpt. L'articolo originale è disponibile",
    here: 'qui',
    generating_article: 'Generazione articolo...',
    error:
      "Si è verificato un errore durante il caricamento dell'articolo. Purtroppo non tutti gli articoli sono liberamente accessibili. Prova un altro articolo o copia il testo manualmente",
    open_org: '1. Apri articolo originale e copia il testo',
    open_own: '2. Incolla il testo qui'
  },
  books: {
    read_now: 'Leggi ora',
    continue_on_page: 'Continua sulla pagina'
  },
  tooltip: {
    examples: 'Esempi',
    emotional_examples: 'Esempi emotivi',
    definition: 'Definizione',
    synonyms: 'Sinonimi',
    antonyms: 'Contrari',
    article: 'Articolo',
    conjugations: 'Coniugazione',
    grammer: 'Grammatica'
  },
  custom_text: {
    enter_your_own_text: 'Inserisci il tuo testo',
    read_text: 'Leggi il testo',
    enter_new_text: 'Inserisci un nuovo testo',
    own_text: 'Copia il tuo testo e incollalo qui',
    special_vocabulary: 'Vocabolario speciale'
  },
  special_vocabulary: {
    special_vocabulary: 'Vocabolario speciale',
    describe:
      'Inserisci un testo in inglese su un argomento o vocabolario speciale che vuoi imparare',
    language_level: 'Livello linguistico',
    generate_text: 'Genera testo'
  },
  intro_message: {
    save_word_as_fav: 'Salva la parola come preferita',
    save_video_as_fav: 'Salva il video come preferito',
    save_article_as_fav: 'Salva l’articolo come preferito',
    save_book_as_fav: 'Salva il libro come preferito',
    save_wordmap_as_fav: 'Salva la mappa delle parole come preferita',
    play_word: 'Ascolta la pronuncia della parola',
    play_article: 'Ascolta l’articolo',
    play_book: 'Ascolta il libro',
    select_main_category: 'Seleziona una categoria principale',
    select_sub_category: 'Seleziona una sottocategoria',
    click_on_read: 'Clicca su `LEGGI` per leggere il testo completo',
    click_on_view: 'Clicca su `GUARDA` per guardare un video',
    last_book_page: 'La tua ultima pagina verrà salvata automaticamente e mostrata qui',
    wordmap_click_on_word: 'Clicca sulla parola per vedere le parole più usate',
    wordmap_click_on_line: 'Clicca su una linea tra due parole per vedere una frase',
    category_progress: 'Tieni traccia dei tuoi progressi selezionando le caselle di controllo',
    conversations_select_scene: 'Seleziona un esercizio',
    conversations_warn: 'Sembra che ci sia un errore nella tua frase. Clicca sul triangolo per maggiori spiegazioni.',
    conversations_task: 'Formula una frase seguendo l’istruzione alla fine della chat',
  },
  tour_guide: {
    click_on_every_word: {
      title: 'Traduci parola',
      text: 'Puoi cliccare su qualsiasi parola per vedere traduzioni, frasi di esempio e definizioni'
    },
    click_on_i_for_sentence: {
      title: 'Intera frase',
      text: 'Per tradurre un’intera frase, clicca sul simbolo i. Qui puoi anche ottenere spiegazioni sulla grammatica.'
    },
    no_save_custom: {
      title: 'Impossibile salvare',
      text: 'Non è possibile salvare i testi personali'
    },
    change_playback_rate: {
      title: 'Modifica la Velocità di Riproduzione',
      text: 'Modifica la velocità di riproduzione tramite Menu/Impostazioni/Audio e Video.'
    },
    theme: {
      title: 'Colori',
      text: 'Puoi cambiare i colori nel menu alla modalità scura'
    },
    explain_menu: {
      title: 'Menu',
      text: 'Gli elementi del menu sono ordinati da molto difficile a facile dall’alto verso il basso'
    },
    repeat_words: {
      title: 'Ripeti il vocabolario',
      text: 'Dovresti ripetere {nWords} vocaboli.',
      call_to_action: 'Impara ora'
    }
  },
  chat: {
    your_message: 'Il tuo messaggio',
    in_de: 'in tedesco',
    in_en: 'in inglese',
    in_es: 'in spagnolo',
    in_fr: 'in francese',
    in_pt: 'in portoghese',
    in_ru: 'in russo',
    in_zh: 'in cinese',
    in_pl: 'in polacco',
    wait: 'Attendere prego...',
    speak_now: 'Parla ora',
    new_chat: 'Nuova chat'
  },
  langs: {
    de: 'Tedesco',
    en: 'Inglese',
    es: 'Spagnolo',
    fr: 'Francese',
    it: 'Italiano',
    pt: 'Portoghese',
    ru: 'Russo',
    zh: 'Cinese',
    pl: 'Polacco'
  },
  about: {
    how_does_it_work: 'Come funziona?',
    here_you_can: 'Qui puoi',
    read_texts: 'leggere testi',
    watch_videos: 'guardare video',
    click_on_every_wrord_for: 'Clicca su una parola per',
    translations: 'traduzioni',
    examples: 'esempi',
    definitions: 'definizioni',
    synonyms: 'sinonimi',
    copy_your_own_text: 'Copiare e leggere i tuoi testi',
    chat_with_chatbot: 'Chatta con un chatbot',
    level: 'Il tuo livello dovrebbe essere tra B1 e C2'
  },
  settings: {
    audio_video: 'Audio e Video',
    colors: 'Colore',
    dark_mode: 'Modalità scura',
    lang_level: 'Livello di lingua'
  },
  error: {
    resource_not_found: 'Siamo spiacenti, non siamo riusciti a trovare quello che stai cercando'
  },
  train: {
    check: 'Controlla',
    flashcards: 'Flashcard',
    exercises: 'Esercizi',
    finished: 'Esercizi completati',
    go_to_favorites: 'Vai ai preferiti',
    restart_exercise: 'Ricomincia esercizio',
    go_to_home: 'Vai alla home',
    text_with_words: 'Testo con parole',
    text_includes: 'Il testo include le seguenti parole'
  },
  wordmap: {
    click_on_circle: 'Clicca sul cerchio per vedere le parole più frequentemente usate con {word}',
    click_on_edge: 'Clicca sulla linea tra due parole per vedere una frase con entrambe le parole',
    open_fullscreen: 'Apri a schermo intero',
    add_own_word: 'Aggiungi parola personale',
    add_sentence_with_all_words: 'Aggiungi frase con tutte le parole',
    add_to_word: 'Aggiungi alla parola'
  },
  conversations: {
    you_are: 'Tu sei',
    you_talk_to: 'Tu parli con',
    your_tasks: 'I tuoi compiti',
  },
  write: {
    min_words: 'Parole minime',
    your_email_needs_to_contain: 'La tua email deve contenere',
    rate: 'Valutazione',
    analysis: 'Analisi',
    overall: 'Generale',
    grammar: 'Grammatica',
    content: 'Contenuto',
    spelling: 'Ortografia'
  }
};
