import { Ref, ref } from 'vue';

import { Language } from '@shared/openapi/jaaluu-api';
import axios from 'axios';

export default () => {
  const articles: Ref<any[]> = ref([]);

  const getEncodedUrl = (url: string) => {
    const baseUrl = url.split('/summary/')[0] + '/summary/';
    const partToEncode = url.split('/summary/')[1];
    const encodedPart = encodeURIComponent(partToEncode);
    return baseUrl + encodedPart;
  };

  const getRandomWikipediArticles = (load: number, langForeign: Language) => {
    const loadDiff = load - articles.value.length;
    console.debug('Loading random wikipedia articles', loadDiff);
    return (async () => {
      try {
        // summary: https://en.wikipedia.org/api/rest_v1/page/summary/<title>
        // search: https://en.wikipedia.org/w/api.php?prop=info%7Cpageprops&inprop=url&ppprop=disambiguation&titles=Batman&format=json&redirects=&action=query&origin=*&
        // details: https://en.wikipedia.org/api/rest_v1/page/summary/Batman
        // random:https://en.wikipedia.org/w/api.php?prop=extracts|revisions&format=json&action=query&generator=random&grnnamespace=0&rvprop=content&grnlimit=10&origin=*

        const promises = Array.from({ length: loadDiff }, (x, i) => {
          return axios
            .get(
              `https://${
                langForeign
              }.wikipedia.org/api/rest_v1/page/random/summary?${Math.random().toString()}`,
              {
                headers: {
                  Accept: 'application/problem+json',
                  'Cache-Control': 'no-store'
                }
              }
            )
            .then((res: any) => {
              articles.value.push(res.data);
            })
            .catch((error) => {
              console.error('Error loading wiki article', error);
              const url = error.request?.responseURL;
              console.log('reponse url', url);
              if (url) {
                console.log('getting new article >>>>');
                axios
                  .get(getEncodedUrl(url), {
                    headers: {
                      Accept: 'application/problem+json',
                      'Cache-Control': 'no-store'
                    }
                  })
                  .then((res) => {
                    articles.value.push(res.data);
                  });
              }
            });
        });
        await Promise.all(promises);
      } catch (error) {
        console.log(error);
      }
    })();
  };

  const getSearchedWikipediaArticles = async (search: string, langForeign: Language) => {
    console.error('gettin gearched article');
    const res = await axios.get(
      `https://${langForeign}.wikipedia.org/w/api.php?action=opensearch&search=${search}&namespace=0&format=json&origin=*`
    );

    const searchResTitles = res.data[1];
    const summaryPromises = searchResTitles.map(async (title: string) => {
      try {
        const summaryRes = await axios.get(
          `https://${langForeign}.wikipedia.org/api/rest_v1/page/summary/${encodeURIComponent(title)}`
        );
        articles.value.push(summaryRes.data);
      } catch (error) {
        console.error(`Failed to fetch summary for ${title}:`, error);
      }
    });
    await Promise.all(summaryPromises);
  };

  return {
    state: { articles },
    funcs: { getRandomWikipediArticles, getSearchedWikipediaArticles }
  };
};
