import { FeedHeadline, GoogleRssTopic } from '@shared/openapi/jaaluu-api';
import { ref, Ref } from 'vue';
export default () => {
  const maxLoadFeeds: Ref<Record<GoogleRssTopic, number> | null> = ref(null);

  const getCleanTitle = (news: FeedHeadline) => {
    if (!news) return '';
    return news.title.replace(` - ${news.source}`, '');
  };

  return { state: { maxLoadFeeds }, funcs: { getCleanTitle } };
};
